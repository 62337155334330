import React from "react";
import {Box, Table, TableCell, TablePagination, TableRow} from "@material-ui/core";
import { ProjectCard } from "../../common/ProjectCard/ProjectCard";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setEditProjectMeta } from "../../store/entities/projects";
export const BlueprintsTag = ({ list, checked_def = false, filter }) => {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    console.log('Projects.jsx, list:', list)
    const history = useHistory();
    const dispatch = useDispatch();
    // console.log(typeof list.map());
    const handleOpenProject = async ({ blueprint_id, type, name }) => {
        // const res = await dispatch(
        //   setEditProjectMeta({ projectId: project_id, name })
        // );
        // if (res) {
        history.push(`/nodes/create_node?edit=true&node_id=${blueprint_id}&type_id=${type}`);
        // }
    };
    console.log("Blueprints, list.slice:", list.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage))
    return (
        <>

            {Boolean(list.length) ? (list.filter(elem => elem.typeId.toLowerCase().indexOf(filter) > -1 ||
                elem.description?.toLowerCase().indexOf(filter) > -1).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((project) => (
                            <ProjectCard
                                title={project.typeId}
                                tags={[]}
                                key={project.blueprint_id}
                                description={project.description}
                                onClick={() => handleOpenProject(project)}
                                f_owner={project.f_owner}
                                entity={project.type}
                                id={project.blueprint_id}
                                checked_def={checked_def}
                                language={project.language}
                            />
                        )
            )): "no data"}

            {/*{Boolean(list.length)*/}
            {/*    ? list.map((project) => (*/}
            {/*        <ProjectCard*/}
            {/*            title={project.typeId}*/}
            {/*            tags={[]}*/}
            {/*            key={project.blueprint_id}*/}
            {/*            description={project.description}*/}
            {/*            onClick={() => handleOpenProject(project)}*/}
            {/*            f_owner={project.f_owner}*/}
            {/*            entity={project.type}*/}
            {/*            id = {project.blueprint_id}*/}
            {/*            checked_def={checked_def}*/}
            {/*            language={project.language}*/}
            {/*        />*/}
            {/*    ))*/}
            {/*    : "no data"}*/}
            {Boolean(list.length) ?(
                <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={list.length || 0}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />):("Nothing")}
        </>
    );
};

import axios from "axios";
import Config from "../config";

const postNewLink = ({ projectId, nodeFrom, portFrom, nodeTo, portTo }) => {
  console.log('postNewLink, src', [nodeFrom, portFrom])
  console.log('postNewLink, dst', [nodeTo, portTo])

  return axios({
    method: "post",
    baseURL: Config.baseUrl,
    url: `/projects/${projectId}/links`,
    data: {
      src: [nodeFrom, portFrom],
      dst: [nodeTo, portTo],
    },
  });
};

//FIXME: links or nodes on endpoint???
const deleteLinkById = ({ projectId, linkId }) => {
  console.log('deleteLinkById, linkId:', linkId)
  return axios({
    method: "delete",
    baseURL: Config.baseUrl,
    url: `/projects/${projectId}/links/${linkId}`,
  });
};

export const Links = {
  post: {
    create: postNewLink,
  },
  delete: {
    byId: deleteLinkById,
  },
};

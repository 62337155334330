import React from "react";
import { Box } from "@material-ui/core";
import { ProjectCard } from "../../common/ProjectCard/ProjectCard";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setEditProjectMeta } from "../../store/entities/projects";
export const Projects = ({ list, checked_def = false }) => {
  console.log('Projects.jsx, list:', list)
  const history = useHistory();
  const dispatch = useDispatch();
  let dates = {};
  list.forEach((proj) => {
    try {
      proj.snapshots.forEach((snapshot) => {
        if (snapshot.name === 'dev') {
          try {
            dates[proj.id] = snapshot.geometry.c_dt;
          } catch (e) {
            dates[proj.id] = null;
          }
        }
      })}
    catch{
      console.log('ERROR Projects: snapshots not existed.')
    }
  });
  // console.log(typeof list.map());
  const handleOpenProject = async ({ id }) => {
    // const res = await dispatch(
    //   setEditProjectMeta({ projectId: project_id, name })
    // );
    // if (res) {
    history.push(`/tablespace/${id}`);
    // }
  };
  return (
    <Box>
      {Boolean(list.length)
        ? list.map((project) => (
            <ProjectCard
              title={project.name}
              tags={Array.isArray(project.tags)?project.tags.join().split(',')
                                                :project.tags.split(',')}
              key={project.id}
              description={project.description}
              onClick={() => handleOpenProject(project)}
              f_owner={ project.f_owner }
              entity={'project'}
              id = {project.id}
              c_dt={dates[project.id]}
              checked_def={checked_def}
            />
          ))
        : "no data"}
    </Box>
  );
};

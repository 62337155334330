const envs = {
  dev_local: {
    // baseUrl: "https://backend-test.splime.io/api",
    baseUrl: "http://127.0.0.1:5001/api",
    // baseUrl: "http://spl-prod.fm-msk.local:5000/api",
  },
  dev_remote: {
    baseUrl: "https://app.splime.io/api",
  },
  prod: {
    baseUrl: "https://app.splime.io/api",
  },
};
//

const config = {
  ...envs[process.env.REACT_APP_STAGE],
};
console.log("Config is: ", process.env.REACT_APP_STAGE);
export default config;

import Config from "../config";
import axios from "axios";

//update schema req: void

const getProjectSnapshots = ({ projectId }) => {
  return axios({
    method: "get",
    baseURL: Config.baseUrl,
    url: `/projects/${projectId}/snapshots`,
  });
};

const postNewProjectSnapshot = ({ projectId, notes, isPublic = true }) => {
  return axios({
    method: "post",
    baseURL: Config.baseUrl,
    url: `/projects/${projectId}/snapshots`,
    data: {
      notes,
      is_public: isPublic,
    },
  });
};

const getSnapshotById = ({ projectId, snapshotId }) => {
  return axios({
    method: "get",
    baseURL: Config.baseUrl,
    url: `/projects/${projectId}/snapshots/${snapshotId}`,
  });
};

const patchProjectSnapshot = ({ projectId, snapshotId, data }) => {
  return axios({
    method: "patch",
    baseURL: Config.baseUrl,
    url: `/projects/${projectId}/snapshots/${snapshotId}`,
    data,
  });
};

export const Snapshots = {
  get: {
    list: getProjectSnapshots,
    byId: getSnapshotById,
  },
  post: {
    snapshot: postNewProjectSnapshot,
  },
  patch: {
    snapshot: patchProjectSnapshot,
  },
};

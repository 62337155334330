import axios from "axios";
import Config from "../config";

const getBluePrints = ({ mode, request = "" }) => {
  return axios({
    baseURL: Config.baseUrl,
    url: `/blueprints?snapshots=contents&mode=${mode}&request=${request}`,
  });
};

const getDeletedList = () => {
  return axios({
    method: "get",
    baseURL: Config.baseUrl,
    url: `/blueprints/deleted?snapshots=contents`,
  });
};

const getBluepringById = ({ blueprintId }) => {
  return axios({
    method: "get",
    baseURL: Config.baseUrl,
    url: `/blueprints/${blueprintId}?snapshots=contents`,
  });
};

const getBlueprintSnapshot = ({ blueprintId, version }) => {
  return axios({
    method: "get",
    baseURL: Config.baseUrl,
    url: `/blueprints/${blueprintId}/snapshots/${version}`,
  });
};

const patchBlueprintData = (formData) => {
  const { blueprint_id, inputs, outputs, src, description, links, language, f_public } = formData;
  const local_inputs = inputs || [];
  const local_outputs = outputs || [];
  const local_links = links || [];

  // local_inputs.forEach(item=>{
  //   item[item.name]={adapter:item.adapter||1,value:item.value||0, is_file: item.is_file||0};
  //   // item.push(inputs_agg);
  // })
  // // nodeOptions.inputs.push(inputs_agg);
  // local_outputs.forEach(item=>{
  //   item[item.name]={adapter:item.adapter||1,value:item.value||0, is_file: item.is_file||0};
  //   // item.push(outputs_agg);
  // })
  console.log('patchBlueprintData, formData:', formData)
  return axios({
    method: "patch",
    baseURL: Config.baseUrl,
    url: `/blueprints/${blueprint_id}/snapshots/dev`,
    data: {
      f_public: f_public,
      inputs: local_inputs,
      outputs: local_outputs,
      source: src,
      language: language,
      type: 'blueprint',
      notes: description,
      links: local_links,
    },
  });
};

const patchMainBlueprintData = (formData) => {
  const { blueprint_id, typeId, f_public, description } = formData;


  console.log('patchBlueprintData, formData:', formData)
  return axios({
    method: "patch",
    baseURL: Config.baseUrl,
    url: `/blueprints/${blueprint_id}`,
    data: {
      f_public: f_public,
      name: typeId,
      description: description,
    },
  });
};

const patchRemove = (formData) => {
  const { blueprint_id, check } = formData;

  console.log('Projects patchRemove', blueprint_id, check)

  return axios({
    method: "delete",
    baseURL: Config.baseUrl,
    url: `/blueprints/${blueprint_id}`,
    data: {
      f_deleted: true,
    },
  });
};

const patchRestore = (formData) => {
  const { blueprint_id, check } = formData;

  console.log('Projects patchRemove', blueprint_id, check)

  return axios({
    method: "patch",
    baseURL: Config.baseUrl,
    url: `/blueprints/${blueprint_id}/restore`,
    data: {
      f_deleted: false,
    },
  });
};

const postNewBlueprint = (formData) => {
  const {
    typeId: name,
    description: notes,
    currentVersion: version,
    is_public,
  } = formData;
  return axios({
    method: "post",
    baseURL: Config.baseUrl,
    url: `/blueprints`,
    data: {
      name,
      notes,
      version,
      is_public,
    },
  });
};

const postNewBlueprintSnapshot = ({blueprintId, name}) => {
  return axios({
    method: "post",
    baseURL: Config.baseUrl,
    url: `/blueprints/${blueprintId}/snapshots`,
    data: {
      name: name
    },
  });
};

const postFork = ({ blueprint_id }) => {
  return axios({
    method: "post",
    baseURL: Config.baseUrl,
    url: `/blueprints/${blueprint_id}/snapshots/dev/fork`,
  });
};

export const Blueprints = {
  get: {
    list: getBluePrints,
    delList: getDeletedList,
    byId: getBluepringById,
    snapshot: getBlueprintSnapshot,
  },
  patch: {
    dev: patchBlueprintData,
    main: patchMainBlueprintData,
    removeFlg: patchRemove,
    restoreFlg: patchRestore,
  },
  post: {
    create: postNewBlueprint,
    snapshot: postNewBlueprintSnapshot,
    fork: postFork,
  },
};

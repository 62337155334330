import compareVersions from "compare-versions";
import {Api} from "../api";

export const changeSnapshotBlueprint = (el, version) => {
    let parsed = null;
    console.log("parseBlueprints1, el:", el)
    let versions;
    let node;
    try {
      versions = el.snapshots.map((snap) => snap.name);
      node = el.snapshots.find((snap) => snap.name === version);
    } catch (e) {
      versions = el.versions.map((snap) => snap.name);
      node = el.versions.find((snap) => snap.name === version);
    }
    console.log("parseBlueprints2")
    // const [currentVersion] = versions.sort(compareVersions).reverse();
    console.log("parseBlueprints3")
    if (versions.length && version) {
      console.log('parseBlueprints, el: ', el)
      console.log('parseBlueprints, node: ', node)
      console.log(versions)
      parsed = {
        // type,
        incomplete: false,
        blueprint_id: el.id ? el.id : el.blueprint_id,
        project_id: null,
        currentVersion: version,
        src: node.source || "",
        inputs: node.inputs || [],
        outputs: node.outputs || [],
        tags: el.tags || "",
        type: el.type || 'blueprint',
        typeId: el.name ? el.name : el.typeId,
        description: el.description,
        language: node.language,
        f_owner: el.f_owner,
        owner_id: el.owner_id,
        username: el.username,
        versions: el.snapshots ? el.snapshots : el.versions,
        f_public: node.f_public,
      };
    } else {
      parsed = {
        incomplete: true,
        blueprint_id: el.blueprint_id,
        project_id: null,
        currentVersion: 'dev',
        src: "",
        inputs: [],
        outputs: [],
        tags: "",
        typeId: el.name,
        description: el.description,
        language: 'python',
        f_owner: false,
        versions: ["1.0.0"],
        f_public: true,
      };
    }

    return parsed;
};

export const parseBlueprints = (bpVersionsResponse, versionName = 'dev') => {
  console.log("parseBlueprints0:", bpVersionsResponse)
  if(bpVersionsResponse.data) {
    return bpVersionsResponse.data.map((el) => {
      return changeSnapshotBlueprint(el, versionName);
    });
  } else {
    return bpVersionsResponse.map((el) => {
      return changeSnapshotBlueprint(el, versionName);
    });
  }
};

export const parseAdapters = (bpVersionsResponse) => {
  console.log("parseAdapters00:", bpVersionsResponse)
  let localEl;
  if (bpVersionsResponse.data){
    localEl = [...bpVersionsResponse.data];
  } else {
    localEl = bpVersionsResponse;
  }

  console.log("parseAdapters0:", localEl)

  return localEl.map((el) => {
    let parsed = null;
    // console.log(1)
    const versions = el.snapshots.map((snap) => snap.name);
    // console.log(2)
    // const [currentVersion] = versions.sort(compareVersions).reverse();
    // console.log(3)
    const currentVersion = 'dev';
    if (versions.length && currentVersion) {
      console.log('parseBlueprints, el: ', el)
      const node = el.snapshots.find((snap) => snap.name === 'dev');
      console.log('parseBlueprints, node: ', node)
      console.log(versions)
      parsed = {
        // type,
        incomplete: false,
        extension: node.extension,
        blueprint_id: el.id,
        currentVersion: currentVersion,
        src: node.source || "",
        inputs: node.inputs || [],
        outputs: node.outputs || [],
        tags: el.tags || "",
        type: el.type || 'adapter',
        typeId: el.name,
        description: el.description,
        f_binary: node.f_binary,
        language: node.language,
        f_owner: el.f_owner,
        owner_id: el.owner_id,
        username: el.username,
        versions,
        f_public: node.f_public
      };
    } else {
      parsed = {
        incomplete: true,
        extension: "",
        blueprint_id: el.blueprint_id,
        currentVersion: 'dev',
        src: "",
        inputs: [],
        outputs: [],
        tags: "",
        typeId: el.name,
        f_binary: "true",
        description: el.description,
        language: 'python',
        f_owner: false,
        versions: ["1.0.0"],
        f_public: true
      };
    }

    return parsed;
  });
};



export const parseProjectBlueprint = async (projVersionsResponse) => {
  console.log("parseProjects0:", projVersionsResponse)
  const res = await Promise.all(projVersionsResponse.map(async (el) => {

    let parsed = null;
    console.log("parseProjects1, el:", el)
    const versions = el.snapshots.map((snap) => snap.name);
    console.log("parseProjects2")
    // const [currentVersion] = versions.sort(compareVersions).reverse();
    console.log("parseProjects3")
    const currentVersion = 'dev';
    if (versions.length && currentVersion) {
      console.log('parseProjects, el: ', el)
      let snap = el.snapshots.find((snap) => snap.name === 'dev');

      let localInputs = [];
      let inputs = [];

      localInputs = await Api.microservices.get.fetchIO({projectId: el.id, projectSnapshot: snap.name});
      localInputs = localInputs['data']['inputs'];

      localInputs.map((inp) => {
        let nodeName = inp.slice(0, inp.indexOf(":", 0));
        let inputName = inp.slice(inp.indexOf(":", 0)+1, );
        let local = {...snap.nodes[nodeName].inputs[inputName]};
        local['name'] = snap.nodes[nodeName].inputs[inputName]['name_id'];
        local['node_name'] = nodeName;
        inputs.push(local);
      })


      // snap.nodes.key()

      console.log('parseProjects, localInputs: ', localInputs);
      let outputs = [];

      Object.keys(snap.nodes).forEach(node_key => {
        Object.keys(snap.nodes[node_key].outputs).forEach(output_key => {
          let local = {...snap.nodes[node_key].outputs[output_key]};
          local['name'] = snap.nodes[node_key].outputs[output_key]['name_id'];
          local['node_name'] = node_key;
          outputs.push(local);
        });
      });

      console.log('parseProjects, node: ', snap)



      console.log(versions)
      parsed = {
        // type,
        incomplete: false,
        blueprint_id: null,
        project_id: el.id,
        currentVersion: currentVersion, //|| "1.0.0",
        src: snap.source || "",
        snapshot: snap,
        inputs: inputs || [],
        outputs: outputs || [],
        tags: el.tags || "",
        type: el.type || 'projectBlueprint',
        typeId: el.name,
        description: el.description,
        language: 'spl',
        f_owner: el.f_owner,
        owner_id: el.owner_id,
        username: el.username,
        versions,
        f_public: el.f_public,
        f_expand: false,
      };
    } else {
      parsed = {
        incomplete: true,
        blueprint_id: null,
        project_id: el.id,
        currentVersion: "1.0.0",
        src: "",
        inputs: [],
        outputs: [],
        tags: "",
        type: 'projectBlueprint',
        typeId: el.name,
        description: el.description,
        language: 'spl',
        f_owner: false,
        versions: ["1.0.0"],
        f_public: true,
        f_expand: false,
      };
    };

    return parsed;
  }));
  return res;
};

import firebase from "firebase/app";
import "firebase/auth";

firebase.initializeApp({
  // ... firebase auth options
  apiKey: process.env.REACT_APP_FIREBASE_AUTH_API_KEY,
  databaseURL: process.env.REACT_APP_FIREBASE_AUTH_DATABASE_URL,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_AUTHDOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_AUTH_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_AUTH_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_AUTH_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_AUTH_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_AUTH_MEASUREMENT_ID,
});
export const providerTypes = {
  google: "google.com",
  default: "password",
};
//observer for checkin is user logged in
const errCredentialsHandler = (err, errCallback) => {
  const user = firebase.auth().currentUser;
  if (err.code === "auth/account-exists-with-different-credential") {
    firebase
      .auth()
      .fetchSignInMethodsForEmail(err.email)
      .then((providers) => {
        if (providers.includes(providerTypes.google)) {
          return errCallback({
            provider: providerTypes.google,
            pendingErr: err,
          });
        }
        if (providers.includes(providerTypes.default)) {
          return errCallback({
            provider: providerTypes.default,
            pendingErr: err,
          });
        }
      });
  }
};

export const linkSocialAccountToExistUser = (
  linkedData,
  password,
  onSuccess
) => {
  const { provider, pendingErr } = linkedData;
  const { email, credential } = pendingErr;
  if (provider === providerTypes.google) {
    signInGoogle(credential, onSuccess);
  }
  if (provider === providerTypes.default) {
    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then((res) => {
        return res.user.linkWithCredential(credential);
      })
      .then((status) => {
        onSuccess();
      })
      .catch((err) => console.log("error", err));
  }
};

export const signUpEmailPassword = (
  email,
  password,
  { onSuccess, onError }
) => {
  firebase
    .auth()
    .createUserWithEmailAndPassword(email, password)
    .then((res) => {
      console.log("User registered successfull");
      onSuccess();
    })
    .catch((err) => onError(err));
};

//sign in by e-mail & password
export const signInEmailPassword = ({ email, password }) => {
  firebase
    .auth()
    .signInWithEmailAndPassword(email, password)
    .then((res) => {})
    .catch((err) => console.log(err));
};

//call method to reset password
export const forgotPassword = ({ email }) => {
  firebase
    .auth()
    .sendPasswordResetEmail(email)
    .then(() => console.log("Password reset link send to e-mail"))
    .catch((err) => console.log(err));
};

//sign in with github
export const signInGithub = async ({ onSuccess, onError }) => {
  const provider = new firebase.auth.GithubAuthProvider();
  provider.addScope("read:user");
  firebase
    .auth()
    .signInWithPopup(provider)
    .then((result) => console.log("User logged in/ signed up with github")) //TODO: data
    .catch((err) => errCredentialsHandler(err, onError));
};

//sign in with google
export const signInGoogle = (credential, onSuccess) => {
  const provider = new firebase.auth.GoogleAuthProvider();
  provider.addScope("profile");
  provider.addScope("email");
  firebase
    .auth()
    .signInWithPopup(provider)
    .then((result) => {
      if (credential) {
        result.user
          .linkWithCredential(credential)
          .then((res) => onSuccess())
          .catch((err) => console.log("Err, when connecting account, ", err));
      }
    })
    .catch((err) => console.log("Err when try sign in with google"));
};

//sign uot
export const signOut = () => {
  firebase
    .auth()
    .signOut()
    .then((res) => console.log("Signed out"));
};

export const resetPassword = ({ oobCode, email, password }) => {
  firebase
    .auth()
    .verifyPasswordResetCode(oobCode)
    .then((email) => {
      console.log("Reset password code verified, email: ", email);
      firebase
        .auth()
        .confirmPasswordReset(oobCode, password)
        .then((res) => console.log("Password reset success", res))
        .catch((err) => console.log("Err, when changing password", err));
    })
    .catch((err) => console.log("Code is expired", err));
};

import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import Diagram, { useSchema } from "beautiful-react-diagrams";
import {Box, Button, CircularProgress, makeStyles} from "@material-ui/core";
import { GraphControl } from "./GraphControl";
import * as sControls from "./schemaControls";
import { useDispatch, useSelector } from "react-redux";
import { NodeProperties } from "./NodeProperties";
import {
  nodePropertiesClose,
  nodePropertiesOpen,
} from "../../store/ui/tableSpace";

import {
  addNodeToSelected,
  clearCurrentGraphData,
  clearExpandedGroupData,
  clearSelectedNodes,
  clearWorkSpace,
  setExpandedGroupId,
  setExpandedGroupSchema,
  setGraphData,
  setProjectEditable,
  setSelectNode, setWorkProject
} from "../../store/entities/tableSpace";

import { useDebounce } from "../../helpers/hooks/useDebounce";
import { Minimap } from "../minimap/Minimap";
import { ZoneSelect } from "../zoneSelect/ZoneSelect";
import { fetchBlueprints } from "../../store/thunks/tablespace";
import { getContentFromClipboard } from "../../helpers/getContentFromClipboard";
import { useParams } from "react-router-dom";
import { setEditProjectMeta } from "../../store/entities/projects";
import {
  findNodesInArea,
  getAreaCoords,
  resetSelectAreaCoords,
} from "../../helpers/selectArea";
import {
  fetchProjectInfoById,
  patchProjectData,
  runProject,
  fetchResults, getMicroserviceInfo, fetchProjectBlueprints
} from "../../store/thunks/projects";
import { Api } from "../../api";
import run_task, { changeRunTaskFlg, changeRunTaskId } from "../../store/run_task/run_task";
import {EditMS, flgChanger} from "./EditMS";
import {EditSettings, flgSettingsPage} from "./EditSettings";
import {setFetchingGlobal, setGraphRunning} from "../../store/ui/common";
import {SnapshotsList, flgSnapshotsPage} from "./SnapshotsList";
import {convertNodes, divideProjectBlueprints} from "../../helpers/schema";
import {getArtifacts} from "../../helpers/projects";

const canvasStyle = {
  position: "relative",
  border: "none",
  top: "0",
  left: "0",
  boxShadow: "none",
  "background": "radial-gradient(#e6e6e6 1px, transparent 1px) 0 0 / 8px 8px",
};

const selectAreaInitial = {
  init: {
    x: 0,
    y: 0,
  },
  start: {
    x: 0,
    y: 0,
  },
  end: {
    x: 0,
    y: 0,
  },
  size: { width: 0, height: 0 },
};

const useStyles = makeStyles((theme) => ({
  src: {
    width: "100%",
    position: "relative",
  },
  tableCellHead: {
    textTransform: "capitalize",
  },
  dialogPaperRoot: {
    minHeight: "30vh",
    minWidth: "600px",
    padding: theme.spacing(2),
  },
}));

export const GraphCanvas = () => {
  // тут run_task
  const runTasks = useSelector(state => state.run_task);

  const { nodeProperties } = useSelector((state) => state.ui.tableSpace);

  const {
    workSpace,
    currentGraphData,
    projectEditable,
    nodeList: { selectedNodes, expandedGroupData },
    workProject,
  } = useSelector((state) => state.entities.tableSpace);

  console.log('GraphCanvas, workSpace:', workSpace);
  console.log('GraphCanvas, currentGraphData:', currentGraphData);

  let f_owner = 0;
  let projectMeta = {description: '', name: '', f_public: ''}

  if (workProject){
    f_owner = workProject.f_owner;
    projectMeta.name = workProject.name;
    projectMeta.description = workProject.description;
    projectMeta.f_public = workProject.f_public;
  }

  const { projectId } = useParams();
  let { projectSnapshot } = useParams();
  // if (projectSnapshot){
  //   projectSnapshot = projectSnapshot.replace('%20', ' ');
  // }
  console.log('fetchProjectInfoById (thunks) 0', { projectId, projectSnapshot });
  const [schema, { onChange }] = useSchema({ nodes: [], links: []});
  const [dialogShow, setDialogShow] = useState(false);
  const [dialogShowSettings, setDialogShowSettings] = useState(false);
  const [dialogShowSnapshots, setDialogShowSnapshots] = useState(false);
  const classes = useStyles();

  let dialog_flg = false;

  console.log("GraphCanvas, schema:", schema)
  console.log("GraphCanvas, runTasks:", runTasks)

  const refSchema = useRef(null);
  const refSelected = useRef(null);
  refSchema.current = schema;
  refSelected.current = selectedNodes;
  // const [isCreateGroup, setCreateGroup] = useState(false);
  const [showSelectArea, setShowSelectArea] = useState(false);
  const [clickTimer, setClickTimer] = useState({ stamp: null, timer: null });
  const [selectAreaCoords, setSelectAreaCoords] = useState(selectAreaInitial);

  const [canvasSize, setCanvasSize] = useState({
    x: window.screen.availWidth,
    y: window.screen.availHeight,
  });

  const dispatch = useDispatch();
  useEffect(() => {
      dispatch(changeRunTaskId(-1))
      dispatch(changeRunTaskFlg(""))
  }, [!workSpace]);

  // if ((workSpace) && (runTasks.run_task_flg === "")){
  //   console.log("changeRunTaskFlg:", runTasks.run_task_flg)
  //   dispatch(changeRunTaskId(workSpace.run_task_id))
  //   dispatch(changeRunTaskFlg(workSpace.run_task_flg))
  // }

  useEffect(() => {
    if ((workSpace) && (runTasks.run_task_flg === "")) {
      console.log("GraphCanvas, changeRunTaskFlg")
      dispatch(changeRunTaskId(workSpace.run_task_id))
      dispatch(changeRunTaskFlg(workSpace.run_task_flg))
      console.log("GraphCanvas, changeRunTaskFlg:", runTasks)
    }
  }, [workSpace]);

  //Graph/schema methods declaration

  const expandNode = async (id, snapshotData, selectedNodes) => {
    try {
      console.log("expandNode, refSchema.current:",refSchema.current)
      console.log("expandNode, id:", id)

      let schemaNew = {...refSchema.current};
      let node = schemaNew.nodes.filter((node) => node.id === id)[0];
      let {f_expand, ...data} = node.data;

      console.log("expandNode, f_expand:", f_expand);
      console.log("expandNode, data:", data);
      delete node.data;
      data['f_expand'] = true;
      node['data'] = data;

      console.log("expandNode, new Schema:", schemaNew);

      await onChange({...schemaNew});
      try {
        console.log("fetchProjectInfoById, started:", {...schemaNew});
        setTimeout(async function() {
          let snapshot = 'dev';
          let res = await dispatch(fetchProjectInfoById({projectId, projectSnapshot}, (projectData) => {}));
          console.log("fetchProjectInfoById, res:", res);
        }, 1000);

      } catch (e) {
        console.log("Err, when try fetchProjectInfoById", e);
      }
    } catch (err) {
      console.log("Err, when try to add new node", err);
      onChange(refSchema.current);
    }
  }

  const changeProjectSnapshot = async (snapshotName) => {
    dispatch(setFetchingGlobal(true));
    console.log("GraphCanvas, changeProjectSnapshot:", snapshotName)
    //create new node and add it to current schema
    try {
      setTimeout(async function() {
        let res = await dispatch(fetchProjectInfoById({projectId, snapshotName}, (projectData) => {}));
        console.log("changeProjectSnapshot, res:", res);
      }, 1000);
    } catch (e) {

      console.log("Err, when try changeProjectSnapshot", e);
    }
    // onChange(workSpace.schema);
    // dispatch(setFetchingGlobal(false));
  };

  const submitSnapshot = async ({meta, entityName, f_BlueprintFix = false, schemaLocal = null}) => {
    console.log("GraphCanvas, SubmitSnapshot:", meta);
    function unique(arr, keyProps) {
      const kvArray = arr.map(entry => {
        const key = keyProps.map(k => entry[k]).join('|');
        return [key, entry];
      });
      const map = new Map(kvArray);
      return Array.from(map.values());
    }

    if (Object.keys(meta).length === 0 || meta.name === ""){
      return 0
    }
    if (!meta['description']){
      meta['description'] = "";
    }

    if (!meta['name'] || meta['name'] === ''){
      return 0
    }

    console.log("GraphCanvas, patch schema1:", schema)
    console.log("GraphCanvas, patch schema2:", refSchema.current)
    console.log("GraphCanvas, patch schema3:", workSpace)
    console.log("GraphCanvas, patch schema4:", schemaLocal)


    let ms_workspace = JSON.parse(JSON.stringify(schemaLocal ? schemaLocal:schema));
    let ms_workspaceSnap = JSON.parse(JSON.stringify(schemaLocal ? schemaLocal:schema));
    let uniqueBPList = [];

    // Create set of Blueprints in Project
    if (f_BlueprintFix) {
      // Unique Blueprints in Project
      let convertedNodes;
      try {
        convertedNodes = convertNodes(ms_workspace.nodes);
        convertedNodes = {...convertedNodes.nodesData};
      } catch (e) {
        console.log("Error with conversion:", e);
      }
      let nodeProjects = {...convertedNodes.nodeProjectParts};
      const nodesLocal = {...ms_workspace.nodes};
      convertedNodes = divideProjectBlueprints({convertedNodes, nodeProjects, submitData: nodesLocal})

      console.log("GraphCanvas, unique entities conv:", convertedNodes)

      Object.values(convertedNodes).forEach((node) => {
        console.log("EditMS, node:", node)

        let new_bp_snap_name = projectId.toString() + "_" + entityName + "_" + meta.name.toString();
        uniqueBPList.push({id: node.data.blueprintId, snapshot: new_bp_snap_name});
      });


      uniqueBPList = unique(uniqueBPList, ['id', 'snapshot']);

      console.log("EditMS, unique entities bps:", uniqueBPList)

      console.log("EditMS, ms_workspaceSnap.nodes", ms_workspaceSnap.nodes)

      await uniqueBPList.forEach((bpSnap) => {
        // Create new Snapshot for each unique Blueprint
        Api.blueprints.post.snapshot({blueprintId: bpSnap.id, name: bpSnap.snapshot})
        // Add snapshotName for each Node in Project
        ms_workspaceSnap.nodes.filter((node) => node.data.blueprintId === bpSnap.id).forEach((node) => {
          node['blueprint'] = [bpSnap.id, bpSnap.snapshot];
        });
        // Add snapshotName for each projectNode in Project
        ms_workspaceSnap.nodes.filter((projectNode) => projectNode.data.blueprintId === null).forEach((projectNode) => {
          Object.values(projectNode.data.snapshot.nodes).forEach((node) => {
            if (node.data.blueprintId === bpSnap.id) {
              node['blueprint'] = [bpSnap.id, bpSnap.snapshot];
              node['currentVersion'] = bpSnap.snapshot;
            }
          });
        });
        console.log("EditMS, pred-finally schema0:", {ms_workspaceSnap})
      });
    }

    let run_tasks_loc = {run_task_id: -1, run_task_flg: 0}

    console.log("EditMS, pred-finally schema:", {...ms_workspaceSnap})
    // Patch Project with Snapshoted Nodes
    console.log('patchProjectData (thunks) - snap!');
    await dispatch(patchProjectData({projectId, schema: {...ms_workspaceSnap}, run_tasks: run_tasks_loc, projectSnapshot: 'dev', description: meta.description})) ;

    // Create new Project's Snapshot for Microservice
    console.log('patchProjectData (thunks) - snap1!', {projectId: projectId, snapName: meta.name});
    await Api.microservices.post.msSnap({projectId: projectId, snapName: meta.name})

    console.log("EditMS, finally schema:", ms_workspace)

    // Update Project's 'dev' Snapshot for 'dev' Nodes
    console.log('patchProjectData (thunks) - post snap!');
    await dispatch(patchProjectData({projectId, schema: {...ms_workspace}, run_tasks: run_tasks_loc, projectSnapshot: 'dev'}));
  };

  const addNode = async (node, coordinatesAlt = null) => {
    if(!Boolean(projectSnapshot) || projectSnapshot === 'dev') {
      //create new node and add it to current schema
      try {
        console.log('GraphCanvas, refSchema.current', refSchema.current)
        const {addedNode, schema} = sControls.addNode(
            refSchema.current,
            node,
            nodeMethods,
            coordinatesAlt
        );
        if ((node?.data?.type !== "dummy") && (node?.type !== "projectBlueprint")) {
          console.log('GraphCanvas, node.data: ', {...node.data})
          console.log('GraphCanvas, addedNode: ', addedNode)
          console.log('GraphCanvas, schema: ', schema)
          console.log("GraphCanvas, coordinatesAlt:", coordinatesAlt)
          let local_inputs = {};
          node.inputs.forEach(item => {
            local_inputs[item.name] = {adapter: item.adapter || [1, 'dev'], value: item.value || ['inline', 5]}
          })
          let local_outputs = {};
          node.outputs.forEach(item => {
            local_outputs[item.name] = {adapter: item.adapter || [1, 'dev']}
          })
          await Api.nodes.post.node({
            projectId,
            id: addedNode.id,
            currentVersion: node.currentVersion,
            blueprintId: node.blueprint_id,
            inputs: local_inputs,
            outputs: local_outputs,
            data: {...node.data},
            geometry: coordinatesAlt ? coordinatesAlt : addedNode.coordinates,
            language: addedNode.language,
            versions: node.versions,
            //data: addedNode.data,
          });
        }

        await onChange(schema);
        return schema;
      } catch (err) {
        console.log("Err, when try to add new node", err);
        await onChange(refSchema.current);
      }
    };
  };

  const clearData = () => {
    //remove none serialised data for state stamp
    console.log("GraphCanvas, clearData, schema:", schema);
    return {
      ...schema,
      // run_task_id: runTasks.run_task_id,
      // run_task_flg: runTasks.run_task_flg,
      nodes: schema.nodes.map((node) => {
        const { id, coordinates, data, inputs, outputs, language, currentVersion, versions } = node;
        return { id, coordinates, data, inputs, outputs, language, currentVersion, versions };
      }),
    };
  };
  const createSnapshot = () => {
    const snapShotData = clearData();

    const localStorageSnapData = {
      projectId,
      schema: snapShotData,
    };
    localStorage.setItem(
      "unsent_project",
      JSON.stringify(localStorageSnapData)
    );
    dispatch(setGraphData(snapShotData));
  };

  //Node methods declaration
  const selectNode = (nodeId) => {
    // if(!Boolean(projectSnapshot) || projectSnapshot === 'dev' || nodeId.slice(0, 5) !== "output") {
      //set single selected node (node body onClick)
      dispatch(nodePropertiesClose());
      dispatch(setSelectNode(nodeId));
    // };
  };

  const addToSelected = (nodeId) => {
    // if(!Boolean(projectSnapshot) || projectSnapshot === 'dev' || nodeId.slice(0, 6) !== "output") {
      //push node to selected nodes array
      console.log("addToSelected:", nodeId);
      dispatch(addNodeToSelected(nodeId));
      console.log("addToSelected1:", selectedNodes)
    // };
  };

  const unselectAll = () => {
    //clear array of selected nodes
    dispatch(clearSelectedNodes());
  };

  const createGroup = () => {
    //create group from selected nodes
    unselectAll();
    const result = sControls.createNodeGroup(
      selectedNodes,
      schema,
      nodeMethods
    );
    onChange(result);
  };

  const collapseGroup = () => {
    const result = sControls.collapseGroupNodes(
      expandedGroupData,
      schema,
      nodeMethods
    );
    dispatch(clearExpandedGroupData());
    onChange(result);
  };

  const deleteNode = async(nodeId, snapSchema, selectedArray) => {
    if(!Boolean(projectSnapshot) || projectSnapshot === 'dev') {
      //remove node from schema
      console.log("deleteNode: GC", nodeId, snapSchema, selectedArray)
      const selectedList = selectedArray || [];
      const nodes = !selectedList.length ? [nodeId] : selectedNodes;
      const result = sControls.deleteNode(nodes, snapSchema, nodeMethods);
      dispatch(nodePropertiesClose());
      console.log('GraphCanvas, deleteNode: ', nodeId)
      console.log('GraphCanvas, result: ', result)
      try {
        const removedLinksArray = [];
        result.removed.removed_links.forEach((deletedLink) => {
          console.log('GraphCanvas, deletedLink: ', deletedLink)
          const [nodeToId, portToId] = deletedLink.input.split("|");
          const [nodeFromId, portFromId] = deletedLink.output.split("|");
          const portFromName = portFromId.split("-")[1];
          const portToName = portToId.split("-")[1];
          removedLinksArray.push(nodeFromId + ',' + portFromName + ',' + nodeToId + ',' + portToName)
        })
        console.log('GraphCanvas, deletedLink: ', removedLinksArray);
        if (removedLinksArray.length) {
          for (const removedLink of removedLinksArray) {
            await Api.links.delete.byId({
              projectId,
              linkId: removedLink,
            });
          }
        }
      } catch (err) {

        console.log("Err, when try to create new link", err);
        // console.log("Link:", newLink);
      }
      try {
        await Api.nodes.delete.byId({
          projectId,
          nodeId,
        });
      } catch (e) {
        console.log('Error with node\'s remove: ', e)
      }
      console.log('GraphCanvas, result.schema: ', result.schema);
      onChange(result.schema);
    };
  };
  const showGroup = (nodeId, groupData, snapshotData) => {
    dispatch(setExpandedGroupId(nodeId));
    dispatch(setExpandedGroupSchema(groupData.schema));
    const result = sControls.showNestedNodes(
      nodeId,
      groupData,
      snapshotData,
      nodeMethods
    );
    onChange(result);
  };

  const sendNewLink = async (newLinks) => {
    if(!Boolean(projectSnapshot) || projectSnapshot === 'dev') {
      const prevLinks = refSchema.current.links;
      if (newLinks.length > prevLinks.length) {
        const newLink = newLinks[newLinks.length - 1];

        if (newLink) {
          const [nodeToId, portToId] = newLink.input.split("|");
          const [nodeFromId, portFromId] = newLink.output.split("|");
          const portFromName = portFromId.split("-")[1];
          const portToName = portToId.split("-")[1];
          console.log('sendNewLink, nodeFromId:', nodeFromId)
          console.log('sendNewLink, nodeToId:', nodeToId)
          console.log('sendNewLink, portFromName&portToName', portFromName, portToName)
          console.log('sendNewLink, ', newLinks)
          try {
            await Api.links.post.create({
              projectId,
              nodeFrom: nodeFromId,
              nodeTo: nodeToId,
              portFrom: portFromName,
              portTo: portToName,
            });
          } catch (err) {
            console.log("Err, when try to create new link", err);
            console.log("Link:", newLink);
          }
        }
      } else if (newLinks.length < prevLinks.length) {
        // correct delete link
        const deletedLink = prevLinks.filter(x => !newLinks.includes(x));
        console.log('GraphCanvas, deletedLink:', deletedLink)
        const [nodeToId, portToId] = deletedLink[0].input.split("|");
        const [nodeFromId, portFromId] = deletedLink[0].output.split("|");
        const portFromName = portFromId.split("-")[1];
        const portToName = portToId.split("-")[1];
        console.log('GraphCanvas, linkId:', nodeFromId + ',' + portFromName + ',' + nodeToId + ',' + portToName)
        try {
          await Api.links.delete.byId({
            projectId,
            linkId: nodeFromId + ',' + portFromName + ',' + nodeToId + ',' + portToName,
          });
        } catch (err) {

          console.log("Err, when try to create new link", err);
          // console.log("Link:", newLink);
        }
      }
    };
  };

  //interceptor for native brd onChange method
  const onChanged = async (e) => {
    if (e.links) {
      const lastNode = e.links[e.links.length - 1];
      console.log('GraphCanvas, onChange, lastNode:', lastNode)
      sendNewLink(e.links);
      console.log('GraphCanvas, onChange, link:', e.links)
      if (lastNode) {
        const [inputNode] = lastNode.input.split("|")[0].split("-");
        const [outputNode] = lastNode.output.split("|")[0].split("-");
        if (inputNode === "dummy" || outputNode === "dummy") {
          return onChange(e);
        }
      }

      const isSamePortTypes = sControls.checkLinksPort(schema, e.links);
      const isSameInput = sControls.checkInputs(schema, e.links);
      if (isSamePortTypes) {
        return onChange(schema);
      }
      if (isSameInput.result) {
        return onChange({ ...schema, links: isSameInput.data });
      }
    }
    //check nodes.position
    if (e.nodes) {
      const nodes = e.nodes.map((node) => {
        const coordinates = [...node.coordinates];
        const [x, y] = node.coordinates;
        if (x <= 0) {
          coordinates[0] = 0;
        }
        if (y <= 0) {
          coordinates[1] = 0;
        }
        return { ...node, coordinates };
      });
      return onChange({ nodes });
    }
    onChange(e);
  };

  const sendData = () => {
    console.log("getResults Start 0")
    if(!Boolean(projectSnapshot) || projectSnapshot === 'dev') {
      if ((runTasks) && (runTasks.run_task_id !== -1) && (runTasks.run_task_flg === 1)) {
        console.log("getResults Start")
        dispatch(
            fetchResults({
              task_id: runTasks.run_task_id,
              schema: refSchema.current,
              cb: async (result) => {
                console.log('GraphCanvas, result:', result);
                for (const key of Object.keys(result)) {
                  if (result[key].outputs !== null) {
                    await addDummyNode(null, null, {...result[key].outputs}, key.toString());
                  }
                }
                await dispatch(changeRunTaskFlg(0));
                await submitSnapshot({meta: {name: ("result" + new Date().toJSON().slice(0,19).replace(/T/g,' ')),
                    description: "Results of launch"},
                  entityName: projectMeta.name,
                  f_BlueprintFix: true});
              },
            })
        );
      }
      console.log('Start patching Project1')
      if (projectId && projectEditable) {
        console.log('Start patching Project2')
        const submitData = {
          projectId,
          schema: clearData(refSchema.current),
          run_tasks: runTasks
        };
        console.log('Start patching Project2, submitData:', submitData)
        dispatch(patchProjectData(submitData));
      }
    }
  };

  const setSchemaSnapshot = useDebounce(createSnapshot, 200);
  const sendState = useDebounce(sendData, 400);

  const handleGroupNodes = () => {
    createGroup();
    unselectAll();
  };

  const handleMouseDown = (e) => {
    e.preventDefault();
    const isCanvas = e.target.classList.contains("_canvas");
    if (isCanvas) {
      const timer = setTimeout(() => setShowSelectArea(true), 200);
      setClickTimer({ stamp: Date.now(), timer });
      setSelectAreaCoords((prev) => ({
        ...prev,
        init: { x: e.pageX, y: e.pageY },
      }));
    }
  };

  const handleMouseMove = (e) => {
    if (showSelectArea) {
      setSelectAreaCoords((prev) => getAreaCoords(e, prev));
    }
  };

  const handleMouseUp = () => {
    const buttonUpTime = Date.now();
    if (buttonUpTime - clickTimer.stamp < 200) {
      clearTimeout(clickTimer.timer);
      return dispatch(clearSelectedNodes());
    }
    if (showSelectArea) {
      const coords = {
        from: {
          x: selectAreaCoords.start.x,
          y: selectAreaCoords.start.y - 80,
        },
        to: {
          x: selectAreaCoords.end.x,
          y: selectAreaCoords.end.y,
        },
      };
      const nodesIntoArea = findNodesInArea(coords, refSchema.current);
      if (nodesIntoArea && (!Boolean(projectSnapshot) || projectSnapshot === 'dev'))  {
        nodesIntoArea.forEach((node) => dispatch(addNodeToSelected(node.id)));
        setSelectAreaCoords(resetSelectAreaCoords());
      }
      setShowSelectArea(false);
    }
  };

  const handleDeleteNodes = () => {
    selectedNodes.forEach((nodeId) =>
      deleteNode(nodeId, refSchema.current, selectedNodes)
    );
    dispatch(clearSelectedNodes());
  };

  const handleCopyListener = (e) => {
    e.preventDefault();
    const { current: schema } = refSchema;
    const { current: selected } = refSelected;
    const data = schema.nodes.filter((node) => selected.includes(node.id));

    navigator.clipboard
      .writeText(JSON.stringify(data))
      .then(() => console.log("Copied"))
      .catch((err) => console.log("Err when copied: ", err));
  };

  const handlePasteListener = async (e) => {
    e.preventDefault();
    const schema = refSchema.current;
    const selectedNodes = refSelected.current;
    const dummyNodes = schema.nodes.filter(
      (node) => selectedNodes.includes(node.id) && node.data.type === "dummy"
    );
    const { state: isPermissionGranted } = await navigator.permissions.query({
      name: "clipboard-read",
    });
    if (isPermissionGranted === "granted") {
      const clipboardData = await navigator.clipboard.read();
      await getContentFromClipboard(clipboardData, {
        onSuccess: (content) => {
          if (content.nodes) {
            const nodes = content.nodes.map((node) => {
              const { id, ...newNode } = node;
              return sControls.GraphNode(newNode, nodeMethods);
            });
            return onChange({ ...schema, nodes: [...schema.nodes, ...nodes] });
          }
          if (dummyNodes.length) {
            const { file, ...otherContent } = content;
            // TODO: req for file
            const nodes = schema.nodes.map((node) => {
              const dummyNode = dummyNodes.find(
                (dummy) => dummy.id === node.id
              );
              if (dummyNode?.id === node.id) {
                return {
                  ...dummyNode,
                  data: {
                    ...dummyNode.data,
                    content: otherContent,
                  },
                };
              }
              return node;
            });
            return onChange({ ...schema, nodes });
          }
        },
      });
    } else {
      alert(
        "For use copy/past you need to allow clipboard permissions on your browser "
      );
    }
  };
  const addDummyNode = async (e, isDblClick, graphResult, key = '') => {
    const dummyNodeData = {
      id: "dummy",
      name: "dummy node",
      inputs: [{name:"dummy_input", value: ['inline', "dummy node"]}],
      outputs: [{name: "dummy_output"}],
      blueprint_id: -42,
      data: {
        typeId: "dummy",
        type: "dummy",
        name: "Name test",
      },
    };
    console.log('addDummyNode, graphResult, key:', graphResult, key)
    if (graphResult) {
      dummyNodeData.data.name = "output-"+key.toString()
      dummyNodeData.data.result = '';
      for (const output of Object.keys(graphResult)){
        if (graphResult[output].data) {
          dummyNodeData.data.result += output + ': ' + graphResult[output].data.toString() + "\n";
        } else {
          dummyNodeData.data.result += output + ': ' + "File" + "\n";
        }
      };
      dummyNodeData.data.result_data = {};
      dummyNodeData.data.result_data = graphResult;
      for (const node of schema.nodes){
        if (node.id === key){
          const x = node.coordinates[0];
          const y = node.coordinates[1] + 100;
          dummyNodeData.coordinates = [x, y];
        };
      };
    }
    if (e) {
      const isCanvas = e.target.classList.contains("_canvas");

      if (isDblClick) {
        if (!isCanvas) {
          return;
        }
        const x = window.scrollX + e.clientX - 80;
        const y = window.scrollY + e.clientY - 100;
        dummyNodeData.coordinates = [x, y];
      }
    }
    console.log('addDummyNode, addNode Start')
    return await addNode(dummyNodeData);
  };

  const dummyNodeNameUpdate = ({ nodeId, name }) => {
    const newNodes = schema.nodes.map((node) =>
        nodeId === node.id ? { ...node, data: { ...node.data, name }, inputs:[{...node.inputs[0], value: ['inline', name]}] } : node
    );
    onChange({ ...schema, nodes: newNodes });
  };

  const valuesUpdate = ({ nodeId, values, outp_values }) => {
    console.log('GraphCanvas, valuesUpdate, values:', values)
    console.log('GraphCanvas, valuesUpdate, nodeId:', nodeId)
    const newNodes = schema.nodes.map((node) =>
        nodeId === node.id ? { ...node, inputs: values, outputs:  outp_values} : node
    );
    onChange({ ...schema, nodes: newNodes });
  };

  const changeBlueprintSnapshot = async ({ nodeId, newVersion }) => {
    // const newNodes = schema.nodes.map((node) =>
    //     nodeId === node.id ? { ...node, inputs: newVersion.inputs, outputs: newVersion.outputs,
    //       currentVersion: newVersion.currentVersion} : node
    // );
    // console.log("GraphCanvas, changeBlueprintSnapshot 1:", newNodes);
    // console.log("GraphCanvas, changeBlueprintSnapshot 2:", {...schema, nodes: newNodes});
    // onChange({ ...schema, nodes: newNodes });
  };

  const createMicroservice = async() => {
    console.log('GraphCanvas, create Microservice!')
    await Api.microservices.post.create({projectId: projectId,
      projectSnapshot:'dev',
      formData: refSchema.current,
      meta: workProject})
  }

  const editMS = async () => {
    flgChanger();
    setDialogShow(true);
  };

  const editSettings = async () => {
    flgSettingsPage();
    setDialogShowSettings(true);
  };

  const snapshotsList = async () => {
    console.log('Try to open SnapshotsList')
    flgSnapshotsPage();
    setDialogShowSnapshots(true);
  };

  const fetchMSIO = async () => {
    console.log('GraphCanvas, fetchMSIO!', projectSnapshot)
    const inputs = await Api.microservices.get.fetchIO({projectId: projectId,
      projectSnapshot:'dev',
      formData: refSchema.current,
      meta: workProject})

    function find_ms_snaps(snap_name){
      if (snap_name.name.slice(0, 3) === 'ms_'){
        return true;
      }
    }

    let snapshots = await Api.microservices.get.fetchSnaps({projectId: projectId});
    // snapshots = snapshots.data.snapshots.filter(find_ms_snaps);

    console.log('GraphCanvas, fetchMSIO, workSpace:', workProject)
    console.log('GraphCanvas, fetchMSIO, snapshots:', snapshots.data.snapshots.filter(find_ms_snaps))
    return {inputs, workSpace: refSchema.current, projectId: projectId, meta: workProject,
            snapshots: snapshots.data.snapshots.filter(find_ms_snaps)};
  };

  const setWP = ({name, description, f_public}) => {
    console.log("setWP", f_public);
    let workProjectLocal = {...workProject};
    workProjectLocal.f_public = f_public;
    workProjectLocal.name = name;
    workProjectLocal.description = description;
    console.log("setWP, workProject:", workProjectLocal)
    dispatch(setWorkProject( {...workProjectLocal}));
  };

  const stopGraphVar = {abort: false}//new AbortController();

  const stopGraph = async () =>{
    console.log("GraphCanvas, stopGraphVar:", stopGraphVar)
    console.log("GraphCanvas, stopGraphVar:", stopGraphVar)
    dispatch(changeRunTaskFlg(0))
    dispatch(setFetchingGlobal(false));
    dispatch(setGraphRunning(false));
    await onChange(refSchema.current);
  }

  const deleteOutputs = async () => {
    let localSchema = {...refSchema.current, nodes: []};
    console.log('startGraph, localSchema:', localSchema)
      for (const node of refSchema.current.nodes) {
        console.log('startGraph, deleteOutputs0:', node)
        if ((node.data.typeId !== "dummy") || (node.data.name.slice(0, 6) !== 'output')) {
          localSchema.nodes.push(node)
        }
        ;
      }
      ;
    await onChange({...localSchema});
  }

  const startGraph = async () => {
    await deleteOutputs();

    if (!Boolean(projectSnapshot) || projectSnapshot === 'dev') {
      console.log('startGraph, schema:', {...refSchema.current})
      dispatch(setFetchingGlobal(true));
      dispatch(setGraphRunning(true));
      dispatch(
          runProject({
            stopGraphVar,
            projectId,
            workSpace,
            schema: {...refSchema.current},
            runTasks: runTasks,
            cb: async (result) => {
              let selectedOutputNodes = [];
              for (const node of refSchema.current.nodes) {
                if ((node.data.typeId === "dummy") && (node.data.name.slice(0, 6) === 'output')) {
                  console.log('startGraph, delete:', node)
                  selectedOutputNodes.push(node.id);
                }
                ;
              }
              ;
              console.log('GraphCanvas, result:', result);
              let schema;
              for (const key of Object.keys(result)) {
                if (result[key].outputs && result[key].outputs !== null) {
                  schema = await addDummyNode(null, null, {...result[key].outputs}, key.toString());
                  await new Promise(r => setTimeout(r, 2000));
                }
              }

              await dispatch(changeRunTaskFlg(0))
              await dispatch(setFetchingGlobal(false));
              await dispatch(setGraphRunning(false));

              await submitSnapshot({meta: {name: ("result" + new Date().toJSON().slice(0,19).replace(/T/g,' ')),
                  description: "Results of launch"},
                entityName: projectMeta.name,
                f_BlueprintFix: true,
                schemaLocal: schema,})
            },
          })
      );
    };
  };

  // const getResults = async () => {
  //   console.log("getResults Start")
  //   dispatch(
  //       fetchResults({
  //         task_id: runTasks.run_task_id,
  //         schema: refSchema.current,
  //         cb: async (result) => {
  //           console.log('GraphCanvas, result:', result);
  //           for (const key of Object.keys(result)) {
  //             if (result[key].outputs !== null) {
  //               await addDummyNode(null, null, {...result[key].outputs}, key.toString());
  //             }
  //           }
  //           await dispatch(changeRunTaskFlg(0))
  //           await submitSnapshot({meta: {name: ("result" + new Date().toJSON().slice(0,19).replace(/T/g,' ')),
  //               description: "Results of launch"},
  //             entityName: projectMeta.name,
  //             f_BlueprintFix: true})
  //         },
  //       })
  //   );
  // };

  useEffect(() => {
    if (!projectId) {
      return dispatch(
          setEditProjectMeta({ projectId: null, name: null }));
    }
    let snapshot = 'dev';
    dispatch(fetchProjectInfoById({projectId, projectSnapshot}, (projectData) => {
      console.log("Try to Dispatch runTasks1")
      console.log("Try to Dispatch runTasks2:", projectData)
      dispatch(changeRunTaskId(projectData.data.geometry.run_task_id))
      dispatch(changeRunTaskFlg(projectData.data.geometry.run_task_flg))
    }));
    console.log('runTasks, dispatched:', runTasks);
    console.log('GraphCanvas Dispatch, schema:', schema);
    return () => dispatch(setEditProjectMeta({ projectId: null, name: null }));
  }, [projectId]);

  useEffect(() => {
    dispatch(fetchBlueprints());
  }, []);

  useEffect(() => {
    // dispatch(fetchProjectBlueprints());
  }, []);

  useEffect(() => {
    console.log('GraphCanvas setSchemaSnapshot, schema:', schema);
    setSchemaSnapshot(schema);
  }, [schema]);

  useEffect(() => {
    sendState(currentGraphData);
  }, [currentGraphData]);

  // node properties control
  useEffect(() => {
    if (selectedNodes.length === 1) {
      // setCreateGroup(false);
      dispatch(nodePropertiesClose());
      return dispatch(nodePropertiesOpen());
    }
    if (selectedNodes.length > 1) {
      // setCreateGroup(true);
      return dispatch(nodePropertiesClose());
    }
    // setCreateGroup(false);
    dispatch(nodePropertiesClose());
  }, [selectedNodes]);

  useEffect(() => {
    const nodes = currentGraphData.nodes.map((node) => {
      console.log("GraphCanvas, mapped node 1:", node)
      const nodeOptions = {
        id: node.id,
        coordinates: node.coordinates,
        typeId: node.data.typeId,
        inputs: node.inputs,
        outputs: node.outputs,
        data: { ...node.data },
        language: node.language,
        blueprintId: node.blueprint_id || node.data.blueprintId,
        currentVersion: node.currentVersion,
        versions: {...node.versions},
      };

      return sControls.GraphNode(nodeOptions, nodeMethods);
    });
    onChange({ ...currentGraphData, nodes });
    return () => {

      dispatch(setProjectEditable(false));
      sendData();
      dispatch(clearCurrentGraphData());
      dispatch(clearWorkSpace());
    };
  }, []);

  useEffect(() => {
    const { canvasHeight, canvasWidth } = sControls.getCanvasSize(
      refSchema.current.nodes,
      canvasSize
    );
    setCanvasSize({ x: canvasWidth, y: canvasHeight });
  }, [refSchema.current]);

  useEffect(() => {
    if (workSpace) {
      const nodes = workSpace.nodes.map((node) => {
        console.log("GraphCanvas, mapped node 2:", node)
        const nodeOptions = {
          id: node.id,
          coordinates: node.coordinates,
          typeId: node.data.typeId || "",
          inputs: node.inputs,
          outputs: node.outputs,
          data: { ...node.data },
          language: node.language,
          blueprintId: node.blueprint_id || node.data.blueprintId,
          currentVersion: node.currentVersion,
          versions: {...node.versions},
        };

        return sControls.GraphNode(nodeOptions, nodeMethods);
      });
      let x = { ...workSpace, nodes };
      onChange({ ...workSpace, nodes });
    }
  }, [workSpace]);

  useLayoutEffect(() => {
    document.addEventListener("copy", handleCopyListener);
    document.addEventListener("paste", handlePasteListener);
    return () => {
      document.removeEventListener("copy", handleCopyListener);
      document.removeEventListener("paste", handlePasteListener);
    };
  }, []);

  const nodeMethods = {
    addNode,
    deleteNode,
    selectNode,
    addToSelected,
    unselectAll,
    showGroup,
    expandNode,
  };

  return (
    <div>
      {(runTasks) && (runTasks.run_task_id !== -1) && (runTasks.run_task_flg === 1) && (
          <Box
              // className={classes.rootWrapper}
              style={{ marginLeft: "10px",
                left: "10px",
                position: "fixed",
                zIndex: 51,
                display: "flex",
                top: "120%",
                transform: "translateY(-50%)",
                height: "60vh",
                alignItems: "flex-start",
                transitionDuration: "200ms", }}
          >
            <CircularProgress />
          </Box>
      )}
      <EditMS
          onClose={() => setDialogShow(false)}
          classes={classes}
          isOpen={dialogShow}
          start_flg={dialog_flg}
          fetchMSIO = {fetchMSIO}
          patchProject = {patchProjectData}
      />
      <EditSettings
          onClose={() => setDialogShowSettings(false)}
          classes={classes}
          isOpen={dialogShowSettings}
          start_flg={dialog_flg}
          fetchMSIO = {fetchMSIO}
          setWorkProject = {setWP}
          projectMeta = {projectMeta}
      />
      <SnapshotsList
          onClose={() => setDialogShowSnapshots(false)}
          classes={classes}
          isOpen={dialogShowSnapshots}
          start_flg={dialog_flg}
          fetchMSIO = {fetchMSIO}
          setWorkProject = {setWP}
          projectMeta = {projectMeta}
          projectId = {projectId}
          changeProjectSnapshot={changeProjectSnapshot}
          submitSnapshot={submitSnapshot}
          patchProject = {sendData}
      />
      <ZoneSelect
        isShow={showSelectArea}
        startX={selectAreaCoords.start.x}
        startY={selectAreaCoords.start.y}
        width={selectAreaCoords.size.width}
        height={selectAreaCoords.size.height}
        onFinish={handleMouseUp}
        onMouseMove={handleMouseMove}
      />
      <GraphControl
        addNode={addNode}
        expandNode={expandNode}
        changeProjectSnapshot={changeProjectSnapshot}
        groupNodes={handleGroupNodes}
        startGraph={startGraph}
        createMicroservice={createMicroservice}
        editMS = {editMS}
        editSettings = {editSettings}
        showSnapshots = {snapshotsList}
        deleteNodes={handleDeleteNodes}
        addDummyNode={addDummyNode}
        f_owner={f_owner}
        projectSnapshot={projectSnapshot}
        projectId={projectId}
        stopGraph={stopGraph}
      />

      <Diagram
        schema={schema}
        onChange={onChanged}
        // @ts-ignore
        className={"_canvas"}
        style={{ ...canvasStyle, width: canvasSize.x, height: canvasSize.y}}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        onMouseMoveCapture={handleMouseMove}
        onDoubleClick={(e) => addDummyNode(e, true)}
      />
      <Minimap nodes={schema.nodes} scaling={14} canvasSize={canvasSize} />
      {nodeProperties.isOpen && (
        <NodeProperties
          nodeId={selectedNodes[0]}
          closeProperties={unselectAll}
          sentValues={valuesUpdate}
          deleteNode={deleteNode}
          nodeNameUpdate={dummyNodeNameUpdate}
          changeBlueprintSnapshot={changeBlueprintSnapshot}
          addNode={addNode}
        />
      )}
      {expandedGroupData.groupId && (
        <Box position={"fixed"} right={0} bottom={0}>
          <Button onClick={collapseGroup}>
            Collapse {expandedGroupData.groupId}
          </Button>
        </Box>
      )}
    </div>
  );
};

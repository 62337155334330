import React, { useState } from "react";
import {
  Box,
  Card,
  CardActionArea,
  Chip,
  Icon,
  makeStyles,
  Typography,
  CardHeader,
  Avatar,
  colors, Link
} from "@material-ui/core";
import {red} from "@material-ui/core/colors";
import VisibilityIcon from "@material-ui/icons/Visibility";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import {FavoritesCheckBox, RestoreCheckBox, ForkCheckBox, LogoIcon} from "./FavoritesCheckBox";
import {Api} from "../../api"
import {
  fetchDeletedProjects,
  fetchListOfProjects,
  getDeletedMicroservices,
  getMicroservicesList
} from "../../store/thunks/projects";
import {useDispatch} from "react-redux";
import {fetchAdapters, fetchBlueprints} from "../../store/thunks/tablespace";
import {useHistory} from "react-router-dom";


const useStyles = makeStyles((theme) => ({
  wrapper: {
    position: "relative",
    // maxWidth: "700px",
    width: "100%",
    minWidth: "480px",
    margin: theme.spacing(1, 0),
    "&:hover": {
      cursor: "pointer",
    },
  },

  mainTitle: {
    width: "80%",
  },

  chip: {
    margin: theme.spacing(0, 0.25),
    fontSize: "0.8rem",
    height: theme.spacing(2),
  },
  chipRoot: {},
  actionArea: {
    paddingBottom: theme.spacing(3),
  },
}));

export const ProjectCard = ({ title, description, tags, onClick, f_owner = false, entity, id,
                            checked_def = false, c_dt = null, language = null,
                            search = false, cust_func = null, owner = null}) => {
  const classes = useStyles();
  const [checked, setChecked] = useState(checked_def);
  const dispatch = useDispatch();
  if (c_dt !== null) {
    c_dt = new Date(c_dt);
  };
  const history = useHistory();

  console.log("ProjectCard0, owner", owner)
  const months = ["January", "February", "March", "April", "May", "June",
                  "July", "August", "September", "October", "November", "December"];

  const toggleCheck = async () => {
    checked ? setChecked(false) : setChecked(true);
    console.log("ProjectCard0, entity&checked&id:", entity, checked, id)
    switch (entity){
      case 'project':
        checked ? await Api.projects.patch.restoreFlg({ project_id: id}) :
                  await Api.projects.patch.removeFlg({ project_id: id});

        dispatch(fetchDeletedProjects());
        dispatch(fetchListOfProjects());
        break;
      case 'blueprint':
        checked ? await Api.blueprints.patch.restoreFlg({ blueprint_id: id}) :
            await Api.blueprints.patch.removeFlg({ blueprint_id: id});
        dispatch(fetchBlueprints());
        break;
      case 'adapter':
        checked ? await Api.adapters.patch.restoreFlg({ blueprint_id: id}) :
            await Api.adapters.patch.removeFlg({ blueprint_id: id});
        dispatch(fetchAdapters())
        break;
      case 'microservice':
        checked ? await Api.microservices.patch.restoreFlg({ project_id: id}) :
            await Api.microservices.patch.removeFlg({ project_id: id});
        dispatch(getMicroservicesList());
        dispatch(getDeletedMicroservices());
        break;
    }
    console.log("ProjectCard1, entity&checked:", entity, checked)
  };

  const toggleFork = async () => {
    console.log("ProjectCard0, entity&checked&id:", entity, checked, id)
    switch (entity){
      case 'project':
        Api.projects.post.fork({ projectId: id});

        dispatch(fetchDeletedProjects());
        dispatch(fetchListOfProjects());
        break;
      case 'blueprint':
        Api.blueprints.post.fork({ blueprint_id: id});

        dispatch(fetchBlueprints());
        break;
      case 'adapter':
        Api.adapters.post.fork({ blueprint_id: id});

        dispatch(fetchAdapters());
        break;
      // case 'microservice':
      //   checked ? await Api.microservices.patch.restoreFlg({ project_id: id}) :
      //       await Api.microservices.patch.removeFlg({ project_id: id});
      //   dispatch(getMicroservicesList());
      //   dispatch(getDeletedMicroservices());
      //   break;
    }
    console.log("ProjectCard1, entity&checked:", entity, checked)
  };

  const toggleFavorite = async () => {
    console.log("toggleFavorite:", entity, checked, id)
    let status;
    checked ? status = await Api.microservices.put.removeFav({ project_id: id}) :
        status = await Api.microservices.put.setFav({ project_id: id});
    console.log("toggleFavorite, status:", status);
    dispatch(getMicroservicesList());
    dispatch(getDeletedMicroservices());

    if(cust_func){
      cust_func(id);
    }
  }

  const handleGoToUser = async (uid) => {
    history.push(`/u${uid}`)
  }

  return (
    <Card className={classes.wrapper} elevation={2}>
      {(search)?(
        <CardHeader
            title= {owner != null ? <Link onClick={() => history.push(`/u${owner.owner_id}`)}>{owner.username}</Link>:<></>}
            style={{backgroundColor: "lightcyan", height: '35px'}}
            titleTypographyProps={{ variant:'h3'  }}>
        </CardHeader>):(<></>)}
      {/*{owner != null ? <Link onClick={() => history.push(`/u${owner.owner_id}`)}>{owner.username}</Link>:<></>}*/}
      <CardActionArea className={classes.actionArea} onClick={onClick}>

        <Box p={2}>
          <Typography variant={"h4"} className={classes.mainTitle}>
            {title ||
              " Dynamically add classes for list of radio button on it's state:\n" +
                "            React.js"}
          </Typography>
          <Typography variant={"body2"}>{description}</Typography>
        </Box>
      </CardActionArea>

      <Box position={"absolute"} top={1} right={1}>
        {(c_dt !== null) ? (
            <Typography variant={"caption"} color={"textSecondary"}>
              Last modified {c_dt.getDate()} {months[c_dt.getMonth()]} {c_dt.getFullYear()}
            </Typography>):(<></>)}
        {(f_owner) ?
            (<RestoreCheckBox checked={checked} onClick={toggleCheck}/>):
            ((entity !== 'microservice')?
                                          (<ForkCheckBox onClick={toggleFork}/>):
                                          (<FavoritesCheckBox checked={checked} onClick={toggleFavorite}/>))
        }
        {(language) ? (
            <LogoIcon language={language} onClick={onClick}/>):(<></>)}
      </Box>
      <Box
        p={1}
        display={"flex"}
        alignItems={"space-between"}
        position={"absolute"}
        bottom={0}
      >
        {Boolean(tags) && Array.isArray(tags) &&
          tags.map((chip) => (
            <Chip
              label={chip}
              key={chip}
              color={"default"}
              size={"small"}
              className={classes.chip}
              onClick={() => {}}
            />
          ))}
      </Box>
      <Box
        display={"flex"}
        justifyContent={"flex-end"}
        marginLeft={"auto"}
        position={"absolute"}
      >
        <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
          <Icon fontSize={"small"} color={"disabled"}>
            <VisibilityIcon fontSize={"small"} />
          </Icon>
          <Typography variant={"caption"} color={"textSecondary"}>
            17
          </Typography>
        </Box>
        <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
          <Icon fontSize={"small"} color={"disabled"}>
            <ArrowUpwardIcon fontSize={"small"} />
          </Icon>
          <Typography variant={"caption"} color={"textSecondary"}>
            25
          </Typography>
        </Box>
      </Box>
    </Card>
  );
};

import isUrl from "is-url";

export const getContentFromClipboard = async (clipboardData, { onSuccess }) => {
  //list of data types placed into clipboard
  const { types } = clipboardData[0];
  console.log(types);
  //get type of last copied element
  const lastType = types[types.length - 1];
  //get blob of last copied type
  const content = await clipboardData[0].getType(lastType);
  //if blob.type === "image/*" read data with file reader and convert it to base64
  const isImage = content.type.split("/")[0] === "image";
  if (isImage) {
    const reader = new FileReader();
    reader.onload = (fileData) => {
      const { result } = fileData.target;
      const thumb = URL.createObjectURL(content);
      onSuccess({
        text: null,
        image: thumb,
        nodes: null,
        file: { base64: result, binary: content },
      });
    };
    reader.onerror = (err) =>
       console.log("Error, when trying to read image", err);
    return reader.readAsDataURL(content);
  }
  //if !image get text from clipboard
  navigator.clipboard
    .readText()
    .then((text) => {
      //check text, is it url (when user copied image link)
      const isUrlString = isUrl(text);
      if (isUrlString) {
        return onSuccess({ text: null, image: text, schema: null });
      }
      try {
        const nodes = JSON.parse(text);
        onSuccess({ text: null, image: null, nodes });
      } catch (err) {
        console.log("Err on parsing");
        onSuccess({ text, image: null, nodes: null });
      }
    })
    .catch((err) =>  console.log("Err, when reading text", err));
};

import React, { useState } from "react";
import { Menu as MenuIcon, AccountCircle } from "@material-ui/icons";
import {
  AppBar,
  Box,
  IconButton,
  makeStyles,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { sidebarClose, sidebarOpen } from "../../store/ui/common";
import { signOut } from "../../api/auth";
const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
}));
export const MenuBar = () => {
  const classes = useStyles();
  const isSidebarOpen = useSelector((state) => state.ui.common.sidebar.isOpen);

  const { displayName } = useSelector((state) => state.user.userData);

  if (window.location.href.indexOf('tablespace') !== -1){
    // clearInterval(timer);
  }
  const { workProject } = useSelector((state) => state.entities.tableSpace);
  console.log('MenuBar, workProject', workProject)
  const dispatch = useDispatch();

  const handleMenuClick = () => {
    isSidebarOpen ? dispatch(sidebarClose()) : dispatch(sidebarOpen());
  };

  const [menuAnchor, setMenuAnchor] = useState(null);

  const handleLogout = () => {
    signOut();
  };

  const handleOpenProfileMenu = (e) => {
    setMenuAnchor(e.currentTarget);
  };

  const handleCloseProfileMenu = () => {
    setMenuAnchor(null);
  };

  return (
    <AppBar className={classes.appBar}>
      <Toolbar>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="menu"
          onClick={handleMenuClick}
        >
          <MenuIcon />
        </IconButton>
        {workProject && (
          <Typography variant="h6">{workProject.name}</Typography>
        )}

        <Box
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          color={"#ffffff"}
          marginLeft={"auto"}
        >
          <Typography variant={"body1"}>{displayName}</Typography>
          <IconButton color={"inherit"} onClick={handleOpenProfileMenu}>
            <AccountCircle />
          </IconButton>
          <Menu
            open={Boolean(menuAnchor)}
            anchorEl={menuAnchor}
            onClose={handleCloseProfileMenu}
          >
            <MenuItem onClick={handleLogout}>Log out</MenuItem>
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

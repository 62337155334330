import axios from "axios";
import Config from "../config";

//no post request for users
//new users create automatically after user firebase registration

export const getSelf = () => {
  return axios({
    method: "get",
    baseURL: Config.baseUrl,
    url: `/users/self`,
  });
};
export const getUsersList = () => {
  return axios({
    method: "get",
    baseURL: Config.baseUrl,
    url: `/users`,
  });
};

export const getUserById = ({ userId }) => {
  return axios({
    method: "get",
    baseURL: Config.baseUrl,
    url: `/users/${userId}`,
  });
};

export const getContent = ({ userId }) => {
  return axios({
    method: "get",
    baseURL: Config.baseUrl,
    url: `/users/${userId}/content`,
  });
};

export const patchUserName = ({ newName, userId }) => {
  return axios({
    method: "patch",
    baseURL: Config.baseUrl,
    url: `/users/${userId}`,
  });
};

export const deleteUser = ({ userId }) => {
  return axios({
    method: "delete",
    baseURL: Config.baseUrl,
    url: `/users/${userId}`,
  });
};

export const User = {
  get: {
    list: getUsersList,
    self: getSelf,
    userById: getUserById,
    content: getContent,
  },
  patch: {
    user: patchUserName,
  },
  delete: {
    user: deleteUser,
  },
};

import { Api } from "../api";
import {useSelector} from "react-redux";


export const getArtifactsSuccessively = async (taskId, schema) => {
  return new Promise((res) => {
    let timer = setInterval(async () => {
      let response = await Api.tasks.get.byId({ taskId });
      console.log('getArtifacts response status, response', response)
      if (window.location.href.indexOf('tablespace') === -1){
        clearInterval(timer);
      }
      if ((response.data.status === "done") || (response.data.status === "failed")) {
        let response = await Api.tasks.get.nodesTask({ taskId });
        clearInterval(timer);
        console.log('getArtifacts, response', response)
        for (let node of Object.keys(response.data)){
          if(response.data[node].status !== 'failed') {
            for (const schema_node of schema.nodes) {
              if ((node === schema_node.id)
                  ||(schema_node.outputs.filter((localOutput) => {
                        if (localOutput.id.split("|")[0] === node){
                          return localOutput;
                        }
                      })
                  ).length > 0) {
                response.data[node.split("*")[0]] = {...response.data[node]};
                node = node.split("*")[0];
                response.data[node]['outputs'] = {};
                for (const output of schema_node.outputs) {
                  let nodeId = output.id.split("|")[0];
                  // let nodeId = schema_node.id;
                  console.log("Run. output:", output);
                  if (output.value[0] !== 'userfile') {
                    response.data[node]['outputs'][output.name_id] = await Api.tasks.get.nodesOutput({
                      taskId,
                      nodeId,
                      output
                    })
                    response.data[node]['outputs'][output.name_id]['name_id'] = output.name_id;
                    response.data[node]['outputs'][output.name_id]['node_id'] = nodeId.split("*")[0];
                  } else {
                    response.data[node]['outputs'][output.name_id] = {...output};
                    response.data[node]['outputs'][output.name_id]['node_id'] = nodeId.split("*")[0];
                    // var file = await Api.projects.get.downfile({
                    //   task_id: taskId,
                    //   output_name: output.name_id,
                    //   node_id: nodeId
                    // });
                    // console.log("My File:", output.name_id.toString())
                    // var blob=new Blob([file.data], {type:(file.headers['content-type']+";charset=utf-8")});
                    // var link=document.createElement('a');
                    // link.type = file.headers['content-type']+";charset=utf-8";
                    // link.href=window.URL.createObjectURL(blob);
                    // var name = output.name_id.toString();
                    // link.download=name;
                    // link.click();
                  }
                  console.log("Run. output2:", response.data[node]['outputs'][output.name_id]);
                  response.data[node]['outputs'][output.name_id]['task_id'] = taskId;
                  response.data[node]['outputs'][output.name_id]['type'] = output.value[0];
                }
              };
            }
          }else{
            let nodeId = node.toString();
            console.log('getArtifacts, Errro node', nodeId);
            response.data[node]['outputs'] = {};
            let error = await Api.tasks.get.nodeResult({
              taskId,
              nodeId
            });
            response.data[node]['outputs']['ERROR'] = {};
            response.data[node]['outputs']['ERROR']['data'] = error['data']['stderr'];
          }
        }
        console.log('getArtifacts, response.data', response.data)
        res(response.data);
      };
    }, 500);
  });
};


export const getArtifacts = async ({stopGraphVar, taskId, schema}) => {
  console.log('getArtifacts 0 stopGraphVar', stopGraphVar)
  return new Promise((res, rej) => {
    let timer = setInterval(async () => {
      // const abortListener = ({target}) => {
      //   console.log('getArtifacts, abort')
      //   stopGraphVar.removeEventListener('abort', abortListener);
      //   clearInterval(timer);
      // }
      // stopGraphVar.addEventListener('abort', abortListener);
      // stopGraphVar.cancel = function (){
      //   clearInterval(timer);
      //   throw 'cancel'
      // }
      // console.log('getArtifacts init!, isGraphRunning', isGraphRunning)
      // if (!isGraphRunning) {
      //   clearInterval(timer);
      //   throw 'cancel';
      // };
      let response = await Api.tasks.get.byId({ taskId });
      console.log('getArtifacts response status, response', response)
      if (window.location.href.indexOf('tablespace') === -1){
        clearInterval(timer);
      }
      console.log('getArtifacts process, stopGraphVar', stopGraphVar)
      if ((response.data.status === "done") || (response.data.status === "failed")) {
        let response = await Api.tasks.get.nodesTask({ taskId });
        clearInterval(timer);
        console.log('getArtifacts, response', response)
        for (let node of Object.keys(response.data)){
          if(response.data[node].status !== 'failed') {
            for (const schema_node of schema.nodes) {
              if ((node === schema_node.id)
                  ||(schema_node.outputs.filter((localOutput) => {
                        if (localOutput.id.split("|")[0] === node){
                          return localOutput;
                        }
                      })
                  ).length > 0) {
                response.data[node.split("*")[0]] = {...response.data[node]};
                node = node.split("*")[0];
                response.data[node]['outputs'] = {};
                for (const output of schema_node.outputs) {
                  let nodeId = output.id.split("|")[0];
                  // let nodeId = schema_node.id;
                  console.log("Run. output:", output);
                  if (output.value[0] !== 'userfile') {
                    try {
                      response.data[node]['outputs'][output.name_id] = await Api.tasks.get.nodesOutput({
                        taskId,
                        nodeId,
                        output
                      })
                      response.data[node]['outputs'][output.name_id]['name_id'] = output.name_id;
                      response.data[node]['outputs'][output.name_id]['node_id'] = nodeId.split("*")[0];
                    } catch (e) {
                      response.data[node]['outputs'][output.name_id] = e
                      response.data[node]['outputs'][output.name_id]['name_id'] = output.name_id;
                      response.data[node]['outputs'][output.name_id]['node_id'] = nodeId.split("*")[0];
                    }
                  } else {
                    response.data[node]['outputs'][output.name_id] = {...output};
                    response.data[node]['outputs'][output.name_id]['node_id'] = nodeId.split("*")[0];
                    // var file = await Api.projects.get.downfile({
                    //   task_id: taskId,
                    //   output_name: output.name_id,
                    //   node_id: nodeId
                    // });
                    // console.log("My File:", output.name_id.toString())
                    // var blob=new Blob([file.data], {type:(file.headers['content-type']+";charset=utf-8")});
                    // var link=document.createElement('a');
                    // link.type = file.headers['content-type']+";charset=utf-8";
                    // link.href=window.URL.createObjectURL(blob);
                    // var name = output.name_id.toString();
                    // link.download=name;
                    // link.click();
                  }
                  console.log("Run. output2:", response.data[node]['outputs'][output.name_id]);
                  response.data[node]['outputs'][output.name_id]['task_id'] = taskId;
                  response.data[node]['outputs'][output.name_id]['type'] = output.value[0];
                }
              };
            }
          }else{
            let nodeId = node.toString();
            console.log('getArtifacts, Errro node', nodeId);
            response.data[node]['outputs'] = {};
            let error = await Api.tasks.get.nodeResult({
              taskId,
              nodeId
            });
            response.data[node]['outputs']['ERROR'] = {};
            response.data[node]['outputs']['ERROR']['data'] = error['data']['stderr'];
          }
        }
        console.log('getArtifacts, response.data', response.data)
        res(response.data);
      };
    }, 500);
  });
};

export const getMSArtifacts = async ({taskId, schema}) => {
  return new Promise((res) => {
    let timer = setInterval(async () => {
      let response = await Api.tasks.get.byId({ taskId });
      if (window.location.href.indexOf('ms_task') === -1){
        clearInterval(timer);
      }
      if ((response.data.status === "done") || (response.data.status === "failed")) {
        clearInterval(timer);
        console.log('getMSArtifacts, taskId', schema)
        let response = await Api.tasks.get.nodesTask({taskId});
        console.log('getArtifacts, response', response)
        for (let node of Object.keys(response.data)) {
          if (response.data[node].status !== 'failed') {
            for (const schema_node of schema.nodes) {
              console.log("getArtifacts, schema_node:", schema_node)
              console.log("getArtifacts, node:", node)
              if ((node === schema_node.id)
                  ||(schema_node.outputs.filter((localOutput) => {
                        if (localOutput.id.split("|")[0] === node){
                          return localOutput;
                        }
                      })
              ).length > 0) {
                response.data[node.split("*")[0]] = {...response.data[node]};
                node = node.split("*")[0];
                response.data[node]['outputs'] = {};
                for (const output of schema_node.outputs) {
                  let nodeId = output.id.split("|")[0];
                  console.log("Run. output:", output);
                  if (output.value[0] !== 'userfile') {
                    response.data[node]['outputs'][output.name_id] = await Api.tasks.get.nodesOutput({
                      taskId,
                      nodeId,
                      output
                    })
                    response.data[node]['outputs'][output.name_id]['name_id'] = output.name_id;
                    response.data[node]['outputs'][output.name_id]['node_id'] = nodeId.split("*")[0];
                  } else {
                    response.data[node]['outputs'][output.name_id] = {...output};
                    response.data[node]['outputs'][output.name_id]['node_id'] = nodeId.split("*")[0];
                    // var file = await Api.projects.get.downfile({
                    //   task_id: taskId,
                    //   output_name: output.name_id,
                    //   node_id: nodeId
                    // });
                    // console.log("My File:", output.name_id.toString())
                    // var blob=new Blob([file.data], {type:(file.headers['content-type']+";charset=utf-8")});
                    // var link=document.createElement('a');
                    // link.type = file.headers['content-type']+";charset=utf-8";
                    // link.href=window.URL.createObjectURL(blob);
                    // var name = output.name_id.toString();
                    // link.download=name;
                    // link.click();
                  }
                  console.log("Run. output2:", response.data[node]['outputs'][output.name_id]);
                  response.data[node]['outputs'][output.name_id]['task_id'] = taskId;
                  response.data[node]['outputs'][output.name_id]['type'] = output.value[0];
                }
              }
              ;
            }
          } else {
            let nodeId = node.toString();
            console.log('getArtifacts, Errro node', nodeId);
            response.data[node]['outputs'] = {};
            let error = await Api.tasks.get.nodeResult({
              taskId,
              nodeId
            });
            response.data[node]['outputs']['ERROR'] = {};
            response.data[node]['outputs']['ERROR']['data'] = error['data']['stderr'];
          }
        }
        console.log('getArtifacts, response.data', response.data)

        response.data['task_id'] = taskId;

        res(response.data);
      };
    }, 2000 );
  });
};

import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  Checkbox,
  IconButton,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
console.log('PortsTable Start');
const defaultName = "set port name";
const defaultValue = "null";

const Item = ({ f_owner, port, adapters, onDeletePort, onChangeName, onChangeValue, onChangeType,
                onChangeAdapter, onChangeDynamic, name, ...props }) => {
  console.log('PortsTable 1');
  const [nameEdit, setNameEdit] = useState(false);
  const [valueEdit, setValueEdit] = useState(false);
  const [typeEdit, setTypeEdit] = useState(false);
  const [adapterEdit, setAdapterEdit] = useState(false);
  const [error, setError] = useState(null);
  console.log('PortsTable port', port);
  console.log('PortsTable adapters', adapters);
  const handleBlur = (e) => {
    console.log('PortsTable e', e);
    const { value} = e.target;
    if (value.length === 0) {
      return setError("Name is too short, min 1 symbol");
    }
    onChangeName(e);
    setNameEdit(false);
  };

  const handleBlur1 = (e) => {
    console.log('PortsTable e', e);
    const { value} = e.target;
    if (value.length === 0) {
      return setError("Name is too short, min 1 symbol");
    }
    onChangeValue(e);
    setValueEdit(false);
  };

  const handleBlurType = (e) => {
    console.log('handleBlurType, PortsTable e', e);
    const { value} = e.target;

    onChangeType(e);
    setTypeEdit(false);
  };

  const handleBlurAdapter = (e) => {
    console.log('handleBlurAdapter, PortsTable e', e);
    const { value} = e.target;

    onChangeAdapter(e);
    setAdapterEdit(false);
  };

  const handleClickEditName = () => {
    if (port.name === defaultName) {
      onChangeName({
        target: {
          value: "",
        },
      });
    }
    setNameEdit(true);
  };

  const handleClickEditValue = () => {
    if (port.value === defaultValue) {
      onChangeValue({
        target: {
          value: "",
        },
      });
    }
    setValueEdit(true);
  };

  const handleClickEditType = () => {
    if (port.value === defaultValue) {
      console.log('handleClickEditType, defaultValue:', defaultValue)
      onChangeType({
        target: {
          value: "",
        },
      });
    }
    setTypeEdit(true);
  };

  const handleClickEditAdapter = () => {
    if (port.value === defaultValue) {
      console.log('handleClickEditType, defaultValue:', defaultValue)
      onChangeAdapter({
        target: {
          value: 1,
        },
      });
    }
    setAdapterEdit(true);
  };

  const const_types = {
    'inline': 'Inline',
    'userfile': "File"
  };

  return (
    <TableRow key={port.name}>
      <TableCell
          style={{ width: 100 }}>
        {!nameEdit ? (
          <Typography disabled={!f_owner} noWrap={true} style={{ width: 100 }} onClick={handleClickEditName}>{port.name}</Typography>
        ) : (
          <TextField
              disabled={!f_owner}
            fullWidth
            inputProps={{ maxLength: 50 }}
            style={{ width: 100 }}
            defaultValue={port.name}
            error={Boolean(error)}
            helperText={error}
            onChange={() => {
              if (error) {
                setError(null);
              }
            }}
            onKeyDown={(e) => e.key === "Enter" && handleBlur(e)}
            onBlur={handleBlur}
          />
        )}
      </TableCell>

      <TableCell>
        {!typeEdit ? (
          <Typography disabled={!f_owner} onClick={handleClickEditType}>{const_types[port.value[0]]}</Typography>
        ) : (
        <TextField select defaultValue={port.value[0]}
                   error={Boolean(error)}
                   disabled={!f_owner}
                   helperText={error}
                   onChange={() => {
                     if (error) {
                       setError(null);
                     }
                   }}
                   onKeyDown={(e) => e.key === "Enter" && handleBlurType(e)}
                   onBlur={handleBlurType}
        >
          <MenuItem value={"inline"}>Inline</MenuItem>
          <MenuItem value={"userfile"}>File</MenuItem>
        </TextField>)}
      </TableCell>

      {name === "inputs" && (
          <TableCell>
            {!valueEdit ? (
                <Typography disabled={!f_owner} onClick={handleClickEditValue}>{port.value[1]}</Typography>
            ) : (
                <TextField
                    disabled={!f_owner}
                    fullWidth
                    defaultValue={port.value[1]}
                    onChange={() => {
                       if (error) {
                         setError(null);
                       }
                     }}
                    onKeyDown={(e) => e.key === "Enter" && handleBlur1(e)}
                    onBlur={handleBlur1}
                />)}
          </TableCell>
      )}

      {/*Adapters*/}
      <TableCell>
        {!adapterEdit ? (
            <Typography disabled={!f_owner} onClick={handleClickEditAdapter}>{port.adapter_name || "None"}</Typography>
        ) : (
            <TextField
                disabled={!f_owner}
                select
                defaultValue={port.adapter_name || "None"}
                onChange={() => {
                  if (error) {
                    setError(null);
                  }
                }}
                onKeyDown={(e) => e.key === "Enter" && handleBlurAdapter(e)}
                onBlur={handleBlurAdapter}
            >
              {Boolean(adapters.length)
                  ? adapters.map((adapter_elem) => (
                      <MenuItem value={adapter_elem.blueprint_id}>{adapter_elem.typeId}</MenuItem>
                  ))
                  : <MenuItem value={null}>No data</MenuItem>}
            </TextField>)}
      </TableCell>

      {name === "inputs" && (
        <TableCell>
          <Checkbox disabled={!f_owner} defaultChecked={port.dynamic} onClick={(e) => onChangeDynamic(e)} />
        </TableCell>
      )}

      <TableCell>
        <IconButton disabled={!f_owner} onClick={onDeletePort} disabled={!f_owner}>
          <DeleteForeverIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

export const PortsTable = ({
  tableHeadCells,
  classes,
  ports,
  adapters,
  onChange,
  name,
  title, f_owner
}) => {
  console.log("PortsTable adapters1:", adapters);
  const handleDeletePort = (port) => {
    console.log('PortsTable, delete port:', port);
    onChange({
      target: {
        name,
        value: ports.filter((item) => item.name !== port.name),
      },
    });
  };
  const handleEditPortName = (e, port) => {
    const value = e.target.value;
    console.log('PortsTable, value1:', value);
    console.log('PortsTable, port1:', port);
    console.log('PortsTable, tableHeadCells:', tableHeadCells);
    onChange({
      target: {
        name,
        value: ports.map((item) => (item === port ? {name: value,
          dynamic: (item.dynamic === undefined ?  true:item.dynamic),
          type: item.type,
          value: item.value,
          adapter: item.adapter,
          adapter_name: item.adapter_name,
          f_name: item.f_name,
          f_key: item.f_key,} : item)),},
    });
  };

  const handleEditPortValue = (e, port) => {
    const value = e.target.value;
    console.log('PortsTable, value1:', value);
    console.log('PortsTable, port1:', port);
    console.log('PortsTable, tableHeadCells:', tableHeadCells);
    onChange({
      target: {
        name,
        value: ports.map((item) => (item === port ? {name: item.name,
          dynamic: (item.dynamic === undefined ?  true:item.dynamic),
          type: item.type,
          value: [item.value[0], value],
          adapter: item.adapter,
          adapter_name: item.adapter_name,
          f_name: item.f_name,
          f_key: item.f_key,} : item)),},
    });
  };

  const handleEditPortType = (e, port) => {
    const value = e.target.value;
    console.log('handleEditPortType, value1:', value);
    console.log('handleEditPortType, port1:', port);
    console.log('handleEditPortType, name:', name);
    onChange({
      target: {
        name,
        value: ports.map((item) => (item === port ? {name: item.name,
          dynamic: (item.dynamic === undefined ?  true:item.dynamic),
          type: value,
          value: [value, item.value[1]],
          adapter: item.adapter,
          adapter_name: item.adapter_name,
          f_name: item.f_name,
          f_key: item.f_key,} : item)),},
    });
  };

  const handleEditPortAdapter = (e, port) => {
    const value = e.target.value;
    console.log('handleEditPortAdapter, e:', e);
    console.log('handleEditPortAdapter, port1:', port);
    const adapter_elem = adapters.find(
        (node) => (node.blueprint_id === Number(value))
    );
    console.log("handleEditPortAdapter, adapter_elem", adapter_elem);
    onChange({
      target: {
        name,
        value: ports.map((item) => (item === port ? {name: item.name,
          dynamic: (item.dynamic === undefined ?  true:item.dynamic),
          type: item.type,
          value: item.value,
          adapter: [value, 'dev'],
          adapter_name: adapter_elem.typeId || null,
          f_name: item.f_name,
          f_key: item.f_key,} : item)),},
    });
  };

  const handleEditPortDynamic = (e, port) => {
    console.log('handleEditPortDynamic, e:', e);
    console.log('handleEditPortDynamic, port1:', port);
    onChange({
      target: {
        name,
        value: ports.map((item) => (item === port ? {name: item.name,
          dynamic: e.target.checked,
          type: item.type,
          value: item.value,
          adapter: item.adapter,
          adapter_name: item.adapter_name || null,
          f_name: item.f_name,
          f_key: item.f_key,} : item)),},
    });
  };

  const handleAddPort = () => {
    console.log('handleAddPort, ports:', [...ports, {name:defaultName, type:'default', value: defaultValue, adapter: 'JSON'}])
    onChange({
      target: {
        name,
        value: [...ports, {name:defaultName, type:'inline', value: ['inline', defaultValue], adapter: [1, 'dev'],
          dynamic: true,
          f_name: "...",
          f_key: "",}],
      },
    });
  };

  return (
    <Box mt={4}>
      <Typography variant={"h6"}>{title}</Typography>
      <TableContainer>
        <Table size={"small"}>
          <TableHead>
            <TableRow>
              {tableHeadCells.map((cell) => (
                <TableCell key={cell.id} className={classes.tableCellHead}>
                  {cell.title}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {ports &&
              ports.map((port) => (
                <Item
                  adapters={adapters}
                  port={port}
                  onDeletePort={() => handleDeletePort(port)}
                  onChangeName={(e) => handleEditPortName(e, port)}
                  onChangeValue={(e) => handleEditPortValue(e, port)}
                  onChangeType={(e) => handleEditPortType(e, port)}
                  onChangeAdapter={(e) => handleEditPortAdapter(e, port)}
                  onChangeDynamic={(e) => handleEditPortDynamic(e, port)}
                  name={name}
                  f_owner={f_owner}
                />
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box mt={3} width={"100%"}>
        <Button onClick={handleAddPort} disabled={!f_owner}>Add {name}</Button>
      </Box>
    </Box>
  );
};

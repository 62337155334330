import { createSlice } from "@reduxjs/toolkit";

export const commonSlice = createSlice({
  name: "common",
  initialState: {
    isFetchingGlobal: false,
    isGraphRunning: false,
    sidebar: {
      width: "280px",
      isOpen: false,
    },
  },
  reducers: {
    sidebarOpen: (state) => {
      state.sidebar.isOpen = true;
    },
    sidebarClose: (state) => {
      state.sidebar.isOpen = false;
    },
    setFetchingGlobal: (state, action) => {
      state.isFetchingGlobal = action.payload;
    },
    setGraphRunning: (state, action) => {
      state.isGraphRunning = action.payload;
    },
  },
});

export const { sidebarOpen, sidebarClose, setFetchingGlobal, setGraphRunning } =
  commonSlice.actions;
export default commonSlice.reducer;

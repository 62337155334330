import { combineReducers } from "redux";
import tableSpace from "./tableSpace";
import blueprints from "./blueprints";
import projects from "./projects";
import adapters from "./adapters";

export default combineReducers({
  tableSpace,
  blueprints,
  adapters,
  projects,
});

import axios from "axios";
import Config from "../config";

const getServicesList = ({ mode = '', request = "" }) => {
  console.log('getServicesList:', mode, request)
  return axios({
    method: "get",
    baseURL: Config.baseUrl,
    url: `/microservices?mode=${mode}&request=${request}`,
  });
};

const getDeletedList = () => {
  return axios({
    method: "get",
    baseURL: Config.baseUrl,
    url: `/microservices/deleted`,
  });
};

const getMSTask = ({ microserviceId, taskId }) => {
  return axios({
    method: "get",
    baseURL: Config.baseUrl,
    url: `/microservices/${microserviceId}/${taskId}`,
  });
};

const getMSLaunches = () => {
  return axios({
    method: "get",
    baseURL: Config.baseUrl,
    url: `/microservices/launches`,
  });
};

const getServiceById = ({ microserviceId }) => {
  return axios({
    method: "get",
    baseURL: Config.baseUrl,
    url: `/microservices/${microserviceId}`,
  });
};

const fetchIO = ({ projectId, projectSnapshot, formData, meta }) => {
  console.log('FetchIO:', projectId, projectSnapshot, formData, meta)
  return axios({
    method: "get",
    baseURL: Config.baseUrl,
    url: `/microservices/fetchio/${projectId}/${projectSnapshot}`,
  });
};

const fetchSnpashots = ({projectId}) => {
  return axios({
    method: "get",
    baseURL: Config.baseUrl,
    url: `/projects/${projectId}?snapshots=metadata`,
  });
};

const postRunMicroservice = ({ id, projectId, formData }) => {
  let inputs = {};

  (formData.values).map(input_key => {
    inputs[input_key['id'].slice(0, input_key['id'].indexOf("|", 0))+":"+input_key['name_id']] = JSON.parse(JSON.stringify(input_key['value']));
    // delete new_nodes[node_key].inputs[input_key];
  });

  console.log("MS Run, inputs:", inputs)
  console.log("MS Run, projectId:", projectId)

  return axios({
    method: "post",
    baseURL: Config.baseUrl,
    url: `/microservices/${id}/run`,
    data: {
      microservice_id: id,
      input: {
        ...inputs,
      },
    },
  });
};

const postMSSnapshot = ({projectId, snapName}) => {
  return axios({
    method: "post",
    baseURL: Config.baseUrl,
    url: `/projects/${projectId}/snapshots`,
    data: {
      name: snapName,
    },
  });
}

const postCreateMicroservice = ({ projectId, projectSnapshot, formData, meta }) => {
  console.log("postCreateMicroservice, formData", formData)
  return axios({
    method: "post",
    baseURL: Config.baseUrl,
    url: `/microservices`,
    data: {
      _data: {nodes: formData.nodes,
        meta: meta
      },
      project_id: projectId,
      version: projectSnapshot,
      description: meta.description,
      name: meta.name,
    },
  });
};

const patchRemove = (formData) => {
  const { project_id, check } = formData;

  console.log('Projects patchRemove', project_id, check)

  return axios({
    method: "delete",
    baseURL: Config.baseUrl,
    url: `/microservices/${project_id}`,
    data: {
      f_deleted: true,
    },
  });
};

const patchRestore = (formData) => {
  const { project_id, check } = formData;

  console.log('Projects patchRemove', project_id, check)

  return axios({
    method: "patch",
    baseURL: Config.baseUrl,
    url: `/microservices/${project_id}/restore`,
    data: {
      f_deleted: false,
    },
  });
};

const setFavorite = (formData) => {
  const { project_id } = formData;

  console.log('setFavorite:', project_id)

  return axios({
    method: "put",
    baseURL: Config.baseUrl,
    url: `/microservices/${project_id}/set_fav`,
    data: {
    },
  });
};

const removeFavorite = (formData) => {
  const { project_id } = formData;

  console.log('removeFavorite:', project_id)

  return axios({
    method: "put",
    baseURL: Config.baseUrl,
    url: `/microservices/${project_id}/remove_fav`,
    data: {
    },
  });
};

export const Microservices = {
  get: {
    list: getServicesList,
    delList: getDeletedList,
    byId: getServiceById,
    msTaskById: getMSTask,
    msLaunches: getMSLaunches,
    fetchIO: fetchIO,
    fetchSnaps: fetchSnpashots,
  },
  post: {
    create: postCreateMicroservice,
    run: postRunMicroservice,
    msSnap: postMSSnapshot,
  },
  put: {
    setFav: setFavorite,
    removeFav: removeFavorite,
  },
  patch: {
    removeFlg: patchRemove,
    restoreFlg: patchRestore,
  }
};

import React from "react";
import { Box, Dialog, TextField, Typography } from "@material-ui/core";
import { CodeEditor } from "./CodeEditor";
export const EditAdapter = ({ isOpen, classes, onClose }) => {
  return (
    <Dialog
      open={isOpen}
      classes={{ paper: classes.dialogPaperRoot }}
      onClose={onClose}
    >
      <Typography variant={"h2"}>Create/Edit adapter</Typography>
      <Typography variant={"body"}>Target language: Python</Typography>
      <Typography variant={"body"}>Name: JSON</Typography>
      <Box mb={3} width={"100%"}>
        <TextField multiline rows={6} label={"description"} fullWidth />
      </Box>
      <Box
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        flexDirection={"column"}
        height={"500px"}
      >
        <Box width={"100%"} height={"50%"}>
          <CodeEditor title={"Load"} editorHeight={"200px"} />
        </Box>
        <Box width={"100%"} height={"50%"}>
          <CodeEditor title={"Save"} editorHeight={"200px"} />
        </Box>
      </Box>
    </Dialog>
  );
};

import React, { useEffect, useRef, useState } from "react";
import { SignIn } from "./SignIn";
import {
  Box,
  Button,
  Container,
  Dialog,
  Divider,
  Grid,
  Icon,
  makeStyles,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import {
  authScreenStates,
  setAuthScreenForgotPassword,
  setAuthScreenResetPassword,
  setAuthScreenSignUp,
} from "../../store/ui/auth";
import { SignUp } from "./SignUp";
import {
  linkSocialAccountToExistUser,
  providerTypes as prividerTypes,
  signInGithub,
  signInGoogle,
  signOut,
} from "../../api/auth";
import { ForgotPassword } from "./ForgotPassword";
import { useLocation } from "react-router-dom";
import { ResetPassword } from "./ResetPassword";
import GitHubIcon from "@material-ui/icons/GitHub";
import { ReactComponent as GoogleIcon } from "../../assets/GoogleIcon.svg";
import queryString from "query-string";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.grey[100],
    height: "100%",
  },
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
  formContainer: {
    padding: theme.spacing(2),
  },
  buttonGithub: {
    backgroundColor: "rgba(0,0,0,1)",
    color: "#fff",
    "&:hover": {
      backgroundColor: "rgba(0,0,0,0.8)",
    },
  },
  buttonGoogle: {
    backgroundColor: theme.palette.error.main,
    "&:hover": {
      backgroundColor: theme.palette.error.light,
    },
  },
}));

export const AuthScreenContainer = () => {
  const screenState = useSelector((state) => state.ui.auth.authScreenState);
  /*
   * pendingErr: object: auth/err
   * provider: string, ex: "google.com"
   * */
  const [linkingData, setLinkingData] = useState(null);
  const [resetData, setResetData] = useState(null);
  const passwordRef = useRef();
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    const resetData = queryString.parse(location.search);
    if (resetData?.mode === "resetPassword") {
      dispatch(setAuthScreenResetPassword());
    }
    setResetData(resetData);
  }, []);

  const getAuthScreen = () => {
    switch (screenState) {
      case authScreenStates.signUp:
        return <SignUp />;

      case authScreenStates.forgotPassword: {
        return <ForgotPassword />;
      }
      case authScreenStates.resetPassword: {
        return <ResetPassword resetData={resetData} />;
      }
      default:
        return <SignIn />;
    }
  };
  const handleClickSignUp = () => {
    dispatch(setAuthScreenSignUp());
  };

  const handleClickForgotPassword = () => {
    dispatch(setAuthScreenForgotPassword());
  };

  const handleSignInGithub = () => {
    signInGithub({ onError: setLinkingData });
  };

  const handleLinkAccount = () => {
    let password = null;
    if (passwordRef.current) {
      password = passwordRef.current.value;
    }
    const onSuccess = () => {
      setLinkingData(null);
    };
    linkSocialAccountToExistUser(linkingData, password, onSuccess);
  };

  const handleSignInGoogle = () => {
    signInGoogle();
  };

  return (
    <Box className={classes.root}>
      <Dialog open={linkingData}>
        <Typography variant={"h4"}>
          User with e-mail %EMAIL% already exist. Type your password to connect
          account
        </Typography>
        {linkingData?.provider === prividerTypes.default && (
          <TextField variant={"filled"} inputRef={passwordRef} />
        )}

        <Button onClick={handleLinkAccount}>Link my account</Button>
      </Dialog>
      <Container maxWidth={"xs"} className={classes.container}>
        <Paper className={classes.formContainer}>
          {getAuthScreen()}
          <Box m={2}>
            <Divider />
          </Box>
          <Box display={"flex"} flexDirection={"column"}>
            <Button onClick={handleClickSignUp}>Sign Up</Button>
            <Button onClick={handleClickForgotPassword}>Forgot password</Button>
            <Box m={2}>
              <Divider />
            </Box>
            <Box mb={2}>
              <Typography variant={"h4"}>Login with social: </Typography>
            </Box>
            <Grid container spacing={1}>
              <Grid item>
                <Button
                  onClick={handleSignInGithub}
                  className={classes.buttonGithub}
                  color={"inherit"}
                  startIcon={<GitHubIcon />}
                >
                  GitHub
                </Button>
              </Grid>
              <Grid item>
                <Button
                  onClick={handleSignInGoogle}
                  className={classes.buttonGoogle}
                  startIcon={
                    <GoogleIcon style={{ width: "20px", height: "20px" }} />
                  }
                >
                  Google
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </Container>
    </Box>
  );
};

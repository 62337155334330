import React from "react";
import { Box } from "@material-ui/core";
import { CreateEditNodeForm } from "../components/createEditForm/CreateEditForm";

export const NewNode = () => {
  return (
    <Box justifyContent={"center"} padding={2}>
      <CreateEditNodeForm />
    </Box>
  );
};

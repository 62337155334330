import React, { useState } from "react";
import MonacoEditor from "react-monaco-editor";
import { Box, MenuItem, TextField, Typography, TableCell, TableRow} from "@material-ui/core";
import AntSwitch from '@material-ui/core/Switch';
import Stack from '@material-ui/utils';
import {ProjectCard} from "../../common/ProjectCard/ProjectCard";

const ColoredLine = ({ color }) => (
    <hr
        style={{
            color: color,
            backgroundColor: color,
            height: 1,
        }}
    />
);

export const CodeEditor = ({
    f_owner,
  value,
  ext,
  lang,
  f_binary,
  nodeType,
  onChange = () => {},
  name = "MonacoEditor",
  title,
  editorHeight,
}) => {
  const [language, setLanguage] = useState(lang);
  //   const [formData, setFormData] = useState({});
  //   const language = lang;
    console.log('CodeEditor, lang:', lang);

  const classes = {};
    const handleEditLanguage = (e) => {
        const value = e.target.value;
        console.log('CodeEditor, handleEditLanguage:', value);
        let name = 'language';
        onChange({
            target: {
                name,
                value,
            }
        });
    };

    const handleEditExtention = (e) => {
        let value = e.target.value;
        console.log('CodeEditor, handleEditExtention:', value);

        if (value[0] !== '.') {
            value = '.' + value;
        }

        console.log('CodeEditor, handleEditExtention:', value);
        let name = 'extension';
        onChange({
            target: {
                name,
                value,
            }
        });
    }

    const handleEditType = (e, nodeType) => {
        const value = e.target.value;

        console.log('CodeEditor, nodeType:', nodeType);

        if (nodeType){
            console.log('CodeEditor1, nodeType:', nodeType);
            let name = 'type';
            let value = 'blueprint';
            onChange({
                target: {
                    name,
                    value,
                }
            });
        } else{
            console.log('CodeEditor2, nodeType:', nodeType);
            let name = 'type';
            let value = 'adapter';
            onChange({
                target: {
                    name,
                    value,
                }
            });
        };
    };
    const handleEditBinary = (e, nodeType) => {
        const value = e.target.checked;
        console.log('CodeEditor, handleEditBinary e.target:', e);
        let name = 'f_binary';
        onChange({
            target: {
                name,
                value,
            }
        });
        console.log('CodeEditor, handleEditBinary e.target.value:', value);
    }

  return (
    <Box width={"100%"}>
        <TableRow>
            <TableCell>
                <Typography>Adapter</Typography>
            </TableCell>
            <TableCell>
                <AntSwitch checked={nodeType}
                           disabled={!f_owner}
                    inputProps={{ 'aria-label': 'ant design' }}
                    onChange={handleEditType}/>

            </TableCell>
            <TableCell>
                <Typography>Node</Typography>
            </TableCell>
            <TableCell>
                <Typography>{title}</Typography>
            </TableCell>
            <TableCell>
                <TextField
                    disabled={!f_owner}
                  select
                  value={lang}
                  onChange={handleEditLanguage}
                >
                  <MenuItem value={"python"}>Python</MenuItem>
                  <MenuItem value={"javascript"}>JavaScript</MenuItem>
                  <MenuItem value={"go"}>Go</MenuItem>
                  <MenuItem value={"octave"}>Octave</MenuItem>
                </TextField>
            </TableCell>
        </TableRow>
        <Typography>
        {((nodeType === 'adapter') || (nodeType === false)) && (
            <TableRow>
                <TableCell>
                    <label>
                        <input
                            disabled={!f_owner}
                            type="checkbox"
                            checked={f_binary}
                            // ref="complete"
                            onChange={handleEditBinary}
                        />
                        Is Binary
                    </label>
                </TableCell>
                <TableCell>
                    <Typography>Extension:</Typography>
                </TableCell>
                <TableCell>
                    <TextField
                        disabled={!f_owner}
                        value={ext}
                        onChange={handleEditExtention}>None</TextField>
                </TableCell>
            </TableRow>
        )}
        </Typography>
        <ColoredLine color="black" />
      <MonacoEditor
          options={{"readOnly": !f_owner}}
        className={classes.monacoEditor}
        language={lang}
        theme={"vs-light"}
        value={value || ""}
        height={editorHeight || "100%"}
        onChange={(value) => onChange({ target: { name, value } })}
        setLanguage={(e) => handleEditLanguage(e)}
      />
    </Box>
  );
};

import React, { useEffect, useState } from "react";
import { Box, Button, Grid, makeStyles, TextField } from "@material-ui/core";
import {Api} from "../../api"

import { EditAdapter } from "./EditAdapter";
import { PortsTable } from "./PortsTable";
import { CodeEditor } from "./CodeEditor";
import queryString from "query-string";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import {
  createNode,
  editNode,
  editAdapter,
  createAdapter,
  fetchAdapters,
  fetchBlueprints
} from "../../store/thunks/tablespace";
import {parseNodes} from "../../helpers/schema";
import {parseAdapters, parseBlueprints} from "../../helpers/blueprints";

const inputsTableHead = [
  { id: 0, title: "name" },
  { id: 1, title: "type" },
  { id: 2, title: "default" },
  { id: 3, title: "adapter" },
  { id: 4, title: "dynamic" },
  { id: 5, title: "delete" },
];

const outputsTableHead = [
  { id: 0, title: "name" },
  { id: 1, title: "type" },
  { id: 2, title: "adapter" },
  { id: 3, title: "delete" },
];

const useStyles = makeStyles((theme) => ({
  src: {
    width: "100%",
    position: "relative",
  },
  tableCellHead: {
    textTransform: "capitalize",
  },
  dialogPaperRoot: {
    minHeight: "80vh",
    minWidth: "600px",
    padding: theme.spacing(2),
  },
}));

export const CreateEditNodeForm = () => {
  console.log('CreateEditForm, Starting CreateEditNodeForm')
  const { local, global } = useSelector((state) => state.entities.blueprints);
  useEffect(() => {
    dispatch(fetchAdapters());
  }, []);
  const adaptersList = useSelector((state) => [
    ...state.entities.adapters.global,
  ]);

  console.log('CreateEditForm, { local, global }:', { local, global })
  const history = useHistory();
  console.log('CreateEditForm, history:', history)
  const location = useLocation();
  console.log('CreateEditForm, location:', location)
  const classes = useStyles();
  const [dialogShow, setDialogShow] = useState(false);
  console.log('CreateEditForm, [dialogShow, setDialogShow]:', [dialogShow, setDialogShow])
  const [formData, setFormData] = useState({});
  console.log('CreateEditForm, [formData, setFormData]:', [formData, setFormData])
  let is_bp = (formData.type === 'blueprint');
  let adaptersList_lang = adaptersList.filter(adapter => adapter.language === (formData.language));
  console.log("CreateEditForm, adaptersList_lang:", adaptersList_lang);
  console.log("is_bp:", is_bp)
  const dispatch = useDispatch();

  const result = queryString.parse(location.search);
  // return console.log(formData);
  const isEdit = result.edit;

  if((!isEdit) & (formData['language'] === undefined)) {
    setFormData((prev) => ({
      ...prev,
      ['language']: 'python',
    }))
  };

  const handleChangePublic = (e) => {
    setFormData((prev) => ({
      ...prev,
      ['f_public']: e.target.checked,
    }));
  }

  const handleChange = (e) => {
    console.log('CreateEditForm, handleChange e:', e)
    if (!e.target.name === 'inputs' || !e.target.name === 'outputs' ) {
      const {name, value} = e.target;
      setFormData((prev) => ({
        ...prev,
        [name]: value,
      }));
    } else if (e.target.name === 'src'){
      const {name, value} = e.target;

      setFormData((prev) => ({
        ...prev,
        [name]: value,
      }));
    } else {
      const {name, value} = e.target;
      setFormData((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const handleSubmit = async (e) => {
    //Submit
    e.preventDefault();
    const result = queryString.parse(location.search);
    // return console.log(formData);
    const isEdit = result.edit;

    console.log('handleSubmit, result', result)

    if (isEdit) {
      if (formData.type == 'blueprint') {
        await dispatch(editNode({formData}));

        await dispatch(fetchBlueprints());
        history.goBack();
      } else {
        console.log('CreateEditForm, try to save Adapter')
        await dispatch(editAdapter({formData}));

        await dispatch(fetchAdapters());
        history.goBack();
      }
    } else {
      if (formData.type == 'blueprint') {
        await dispatch(
            createNode({
              formData,
              cb: (blueprintId) => {
                setFormData((prev) => ({
                  ...prev,
                  blueprint_id: blueprintId,
                  typeId: formData.typeId,
                  isPublic: true,
                  description: formData.description,
                }));
              },
            })
        );
        await dispatch(fetchBlueprints());
        history.goBack();
      } else {
        console.log('CreateEditForm, try to save Adapter')
        await dispatch(
            createAdapter({
              formData,
              cb: (blueprintId) => {
                setFormData((prev) => ({
                  ...prev,
                  blueprint_id: blueprintId,
                  typeId: formData.typeId,
                  isPublic: true,
                  description: formData.description,
                }));
              },
            })
        );
        await dispatch(fetchAdapters());
        history.goBack();
      };
    };
  };

  const fetchBlueprintData = async (nodeId, typeId) => {
    console.log('CreateEditForm, Starting fetchBlueprintData', nodeId, typeId)
    let node = [...global, ...local, ...adaptersList].find(
      (node) => ((node.blueprint_id === Number(nodeId)) && (node.type === typeId))
    );
    if (!node){
      if(typeId === 'blueprint') {
        node = parseBlueprints([(await Api.blueprints.get.byId({blueprintId: nodeId})).data])[0];
        console.log("CreateEditForm, fetched:", node)
      }else{
        node = parseAdapters([(await Api.adapters.get.byId({adapterId: nodeId})).data])[0];
      }
    }
    console.log('CreateEditForm, node:', node)
    if (node) {
      /*if (node.type === "global") {
        alert("TEMP WARNING: Can't edit node, because you are not owner");
        return history.push("/tablespace");
      }*/
      const versionArray = node.versions
      console.log('CreateEditForm, versionArray1:', versionArray)
      // versionArray[2] = ++versionArray[2];
      // const currentVersion = versionArray.join(".");
      console.log('CreateEditForm, currentVersion:', node.currentVersion)
      setFormData({ ...node, currentVersion: node.currentVersion });
    } else {
      history.goBack();
    }
  };

  const handleBack = async () => {
    console.log("History:", history)
    history.goBack();
  }

  useEffect(async () => {
    const result = queryString.parse(location.search);
    if (result.edit) {
      const nodeId = result.node_id;
      const typeId = result.type_id;
      console.log('CreateEditForm, location1:', location)
      console.log('CreateEditForm, result:', result)
      await fetchBlueprintData(nodeId, typeId);
    }
  }, []);
  return (
    <>
      <Box position={"fixed"} bottom={15} right={15} zIndex={9}>
        {formData.f_owner || !isEdit?
          <Button onClick={handleSubmit} style={{marginRight:10}}>DEV: Submit</Button>
           :  <></>}
        <Button onClick={handleBack}>Back</Button>
      </Box>
      <EditAdapter
        onClose={() => setDialogShow(false)}
        classes={classes}
        isOpen={dialogShow}
      />
      <Grid container spacing={2}>
        <Grid container item xs={6} sm={6} md={6}>
          <Box width={"100%"} marginBottom={2}>
            <TextField
                disabled={!formData.f_owner && isEdit}
              label={"Node name"}
              fullWidth
              variant={"filled"}
              name={"typeId"}
              value={formData.typeId || ""}
              onChange={handleChange}
            />
          </Box>
          <Box width={"100%"} marginBottom={2}>
            <TextField
              disabled={!formData.f_owner && isEdit}
              multiline
              minRows={6}
              label={"Description"}
              fullWidth
              variant={"filled"}
              name={"description"}
              value={formData.description || ""}
              onChange={handleChange}
            />
          {/*</Box>*/}
          {/*<Box>*/}
            <label>
              <input
                  disabled={!formData.f_owner && isEdit}
                  type="checkbox"
                  checked={formData.f_public}
                  name={"f_public"}
                  // ref="complete"
                  onChange={handleChangePublic}
              />
              Is Public
            </label>
          </Box>
          <PortsTable
              f_owner = {(formData.f_owner || !isEdit)}
            classes={classes}
            tableHeadCells={inputsTableHead}
            ports={formData.inputs || []}
            adapters={adaptersList_lang || []}
            onChange={handleChange}
            name={"inputs"}
            title={"Inputs"}
          />
          {formData.type === 'blueprint'?
              <PortsTable
                  f_owner = {formData.f_owner || !isEdit}
              classes={classes}
              tableHeadCells={outputsTableHead}
              ports={formData.outputs || []}
              adapters={adaptersList_lang || []}
              onChange={handleChange}
              name={"outputs"}
              title={"Outputs"}
              />:<></>}

        </Grid>
        <Grid container item md={6}>
          <CodeEditor
              f_owner = {formData.f_owner || !isEdit}
            name={"src"}
            title={"Source: "}
            ext={formData.extension || ""}
            lang={formData.language || "python"}
            isedit={isEdit || ""}
            nodeType = {is_bp}
            f_binary = {formData.f_binary}
            value={formData.src}
            editorHeight = {800}
            onChange={handleChange}
          />
        </Grid>
      </Grid>
    </>
  );
};

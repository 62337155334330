export const generateNodeId = (nodesList, newNode) => {
  const nodeType = newNode.typeId || newNode.data.typeId;
  const sortedNodesByTypes = {};
  nodesList.forEach((node) => {
    let nodeNumber = +(node.id.split("-")[node.id.split("-").length - 1]);
    const typeId = node.typeId || node.data.typeId;
    console.log('generateNodeId, node', node)
    console.log('generateNodeId, typeId', typeId)
    // const { typeId } = nodeType;
    console.log('generateNodeId, typeId', typeId)
    const nodeTypeExists = sortedNodesByTypes.hasOwnProperty(typeId);
    console.log('generateNodeId, nodeTypeExists', {...nodeTypeExists})
    if (nodeTypeExists) {
      sortedNodesByTypes[typeId].count++;
      sortedNodesByTypes[typeId].maxNumber = Math.max(
        nodeNumber,
        sortedNodesByTypes[typeId].maxNumber
      );
    } else {
      sortedNodesByTypes[typeId] = {
        count: 1,
        maxNumber: nodeNumber,
      };
    }
  });
  console.log('generateNodeId, sortedNodesByTypes', {...sortedNodesByTypes})
  console.log('generateNodeId, nodeType', nodeType)

  const nextNodeNumber = sortedNodesByTypes[nodeType]
    ? ++sortedNodesByTypes[nodeType].maxNumber
    : 0;
  console.log('generateNodeId, nextNodeNumber', nextNodeNumber)
  let nodeId;
  if (nodeType === "dummy") {
    nodeId = `${nodeType}-1.0.0-${nextNodeNumber}`;
  } else {
    nodeId = `${nodeType}-${newNode.currentVersion || 'dev'}-${nextNodeNumber}`;
  }
  console.log('generateNodeId, nodeId', nodeId)
  return nodeId;
};

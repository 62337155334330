import React from "react";
import { makeStyles } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  nodeWrapper: {
    display: "block",
    position: "absolute",
    backgroundColor: theme.palette.primary.light,
  },
}));
export const MiniMapNode = ({ coordinates, scaling }) => {
  const classes = useStyles();
  return (
    <div
      className={classes.nodeWrapper}
      style={{
        width: Math.floor(100 / scaling),
        height: Math.floor(80 / scaling),
        left: Math.floor(coordinates[0] / scaling),
        top: Math.floor(coordinates[1] / scaling),
      }}
    />
  );
};

import React, {useEffect, useState} from "react";
import {
  Box,
  IconButton,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Paper,
  Tooltip,
  Divider, CircularProgress
} from "@material-ui/core";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import Stop from "@material-ui/icons/Stop";
import ListIcon from "@material-ui/icons/List";
import MoveToInboxIcon from "@material-ui/icons/MoveToInbox";
import SettingsIcon from '@material-ui/icons/Settings';
import ReplyIcon from '@material-ui/icons/Reply';
import { useDispatch, useSelector } from "react-redux";
import { nodeListClose, nodeListOpen } from "../../store/ui/tableSpace";
import PropTypes from "prop-types";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import { useHistory } from "react-router-dom";
import {parseProjectBlueprint, parseProjects} from "../../helpers/blueprints";
import {EditMS} from "./EditMS";
import {fetchBlueprints} from "../../store/thunks/tablespace";


const useStyles = makeStyles((theme) => ({
  list: {
    backgroundColor: theme.palette.background.paper,
    border: `1px solid ${theme.palette.grey[200]}`,
    marginLeft: theme.spacing(0.5),
    position: "relative",
    overflowY: "auto",
    maxHeight: "450px",
  },
  rootWrapper: {
    position: "fixed",
    zIndex: 51,
    display: "flex",
    top: "65%",
    transform: "translateY(-50%)",
    height: "60vh",
    alignItems: "flex-start",
    transitionDuration: "200ms",
  },
  controlWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
}));

export const GraphControl = ({
  addNode,
  isStarted,
  startGraph, stopGraph,
  createMicroservice,
  editMS,
  editSettings,
  deleteNodes,
  addDummyNode,
  // groupNodes,
  f_owner,
  projectSnapshot,
  projectId,
  showSnapshots,
  changeProjectSnapshot,
}) => {
  //state
  const sidebarIsOpen = useSelector((state) => state.ui.common.sidebar.isOpen);
  const isNodesListOpen = useSelector(
    (state) => state.ui.tableSpace.nodesList.isOpen
  );

  const { isFetchingGlobal, isGraphRunning } = useSelector((state) => state.ui.common);

  useEffect(() => {
    dispatch(fetchBlueprints());
  }, []);

  const nodesList = useSelector((state) => [
    ...state.entities.blueprints.global,
    ...state.entities.blueprints.local,
  ]);

  const projectNodesList = (useSelector((state) => [
    ...state.entities.projects.user,
  ]));

  console.log('GraphCanvas, nodesList:', nodesList)
  console.log('GraphCanvas, projectNodesList:', projectNodesList)

  const history = useHistory();
  //utils
  const classes = useStyles();
  const dispatch = useDispatch();

  //methods
  const nodesListOpen = () => {
    dispatch(nodeListOpen());
  };

  const nodesListClose = () => {
    dispatch(nodeListClose());
  };

  //handlers
  const handleNodeAdd = (node) => {
    console.log("GraphControl, add node:", node);
    if (node.incomplete) {
      return history.push(
          `/nodes/create_node?edit=true&node_id=${node.blueprint_id}`
      );
    }
    addNode(node);
    nodesListClose();
  };

  const handleBackDEV = () => {
    console.log("GraphControl, BaqckDEV");
    changeProjectSnapshot("dev");
    return history.push(`/tablespace/${projectId}`);

  };

  console.log("GraphControl, f_owner:", f_owner);
  return (
    <Box
      className={classes.rootWrapper}
      style={{ marginLeft: sidebarIsOpen ? "286px" : "8px", left: "8px" }}
    >
      <Paper elevation={4} className={classes.controlWrapper}>
        <Tooltip title={"Add node"}>
          <IconButton onClick={nodesListOpen}>
            <AddCircleIcon />
          </IconButton>
        </Tooltip>
        {/*<Tooltip title={"Group nodes"}>*/}
        {/*  <IconButton onClick={groupNodes}>*/}
        {/*    <GroupWorkIcon />*/}
        {/*  </IconButton>*/}
        {/*</Tooltip>*/}
        {/*<Tooltip title={"Ungroup nodes"}>*/}
        {/*  <IconButton>*/}
        {/*    <OpenWithIcon />*/}
        {/*  </IconButton>*/}
        {/*</Tooltip>*/}
        <Tooltip title={"Remove node"}>
          <IconButton onClick={deleteNodes}>
            <DeleteForeverIcon />
          </IconButton>
        </Tooltip>

        <Tooltip title={"Create new Microservice"}>
          <IconButton onClick={editMS}>
            <MoveToInboxIcon />
          </IconButton>
        </Tooltip>

        <Tooltip
          title={isGraphRunning ? "Pipeline started" : "Start pipeline"}
        >
          {isGraphRunning ?
              <IconButton onClick={stopGraph}>
                <Stop style={{ color:  "red"}} />
              </IconButton>
               :
              <IconButton onClick={startGraph}>
                <PlayArrowIcon style={{ color:  "green"}} />
              </IconButton>}

        </Tooltip>
        <Tooltip title={"Snapshots"}>
          <IconButton onClick={showSnapshots}>
            <ListIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title={"Settings"}>
          <IconButton disabled={!f_owner} onClick={editSettings}>
            <SettingsIcon />
          </IconButton>
        </Tooltip>
        {Boolean(projectSnapshot) && projectSnapshot !== 'dev' ? (
            <Tooltip title={"Back to DEV"}>
              <IconButton onClick={handleBackDEV}>
                <ReplyIcon />
              </IconButton>
            </Tooltip>
        ):(<></>)}


      </Paper>
      {isNodesListOpen && (
        <List className={classes.list} onMouseLeave={nodesListClose}>
          <ListItem button onClick={addDummyNode}>
            <ListItemText>Dummy node</ListItemText>
          </ListItem>
          {nodesList &&
              nodesList.map((node) => {
                const handleClick = () => {
                  handleNodeAdd(node);
                };
                return (
                    <ListItem
                        button
                        onClick={handleClick}
                        key={node.typeId || node.data.typeId}
                    >
                      <ListItemText>{node.typeId || node.data.typeId}</ListItemText>
                    </ListItem>
                );
              })}
          <Divider />
          {projectNodesList &&
              projectNodesList.map((node) => {
                const handleClickProject = async () => {
                  const projectBlueprint = await (parseProjectBlueprint([node]));
                  console.log('GraphControl, projectBlueprint:', projectBlueprint)
                  handleNodeAdd(projectBlueprint[0]);
                };
                return (
                    <ListItem
                        button
                        onClick={handleClickProject}
                        key={node.name}
                    >
                      <ListItemText>{node.name}</ListItemText>
                    </ListItem>
                );
              })}
        </List>
      )}
    </Box>
  );
};

GraphControl.propTypes = {
  addNode: PropTypes.func.isRequired,
  isStarted: PropTypes.bool,
  startGraph: PropTypes.func.isRequired,
  createMicroservice: PropTypes.func.isRequired,
  editMS: PropTypes.func.isRequired,
  editSettings: PropTypes.func.isRequired
};

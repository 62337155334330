import React, { useEffect } from "react";
import "./index.css";
import { Switch, Route } from "react-router-dom";
import { MainLayout } from "./components/layout/MainLayout";
import { Typography } from "@material-ui/core";
import { TableSpace } from "./pages/TableSpace";
import { NewNode } from "./pages/NewNode";
import { Search } from "./pages/Search";
import { SearchRequest } from "./pages/SearchRequest";
import { Dashboard } from "./pages/Dashboard";
import { DashboardUser } from "./pages/DashboardUser";
import { Blueprints } from "./pages/Blueprints";
import firebase from "firebase";
import { useDispatch, useSelector } from "react-redux";
import { setUpdatedUserToken, userSignOut } from "./store/user/user";

import { useHistory } from "react-router-dom";
import { userSignIn } from "./store/thunks/user";
import { Microservices } from "./pages/Microservices";
import { MicroserviceForm } from "./components/MicroserviceForm/MicroserviceForm";
import { MicroserviceFormTask } from "./components/MicroserviceForm/MicroserviceFormTask";
import { LaunchTable } from "./components/MicroserviceForm/LaunchTable";

const App = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const currentUser = useSelector((state) => state.user.userData);
  useEffect(() => {

    firebase.auth().onAuthStateChanged((user) => {
      if (!user) {
        history.push("/");
        return dispatch(userSignOut());
      }
      dispatch(userSignIn(user));
      console.log("user:", user)
    });
    firebase.auth().onIdTokenChanged((token) =>
      token
        .getIdToken(true)
        .then((token) => {
          sessionStorage.setItem("user_token", token);
          currentUser && dispatch(setUpdatedUserToken(token));
        })
        .catch((err) =>
           console.log("Error, when try to update firebase token", err)
        )
    );
  }, []);

  return (
    <MainLayout>
      <Switch>
        <Route exact path="/">
          <Dashboard />
        </Route>
        <Route exact path="/u:userId">
          <DashboardUser />
        </Route>
        <Route path="/blueprints">
          <Blueprints />
        </Route>
        <Route path="/tablespace/:projectId/:projectSnapshot">
          <TableSpace />
        </Route>
        <Route path="/tablespace/:projectId">
          <TableSpace />
        </Route>
        <Route path="/tablespace">
          <TableSpace />
        </Route>
        <Route path="/nodes/create_node">
          <NewNode />
        </Route>
        <Route exact path="/microservices">
          <Microservices />
        </Route>
        <Route exact path="/launch_table">
          <LaunchTable />
        </Route>
        <Route path="/microservices/:id">
          <MicroserviceForm />
        </Route>
        <Route path="/ms_task">
          <MicroserviceFormTask />
        </Route>
        <Route path="/settings">
          <Typography variant={"h2"} align={"center"}>
            Settings Page
          </Typography>
        </Route>

        <Route path="/search/tab=:activeTab&req=:request">
          <SearchRequest />
        </Route>
        <Route path="/search">
          <Search />
        </Route>
      </Switch>
    </MainLayout>
  );
};

export default App;

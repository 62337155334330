import axios from "axios";
import Config from "../config";

//Get list of tasks
const getTasks = () => {
  return axios({
    method: "get",
    baseURL: Config.baseUrl,
    url: `/tasks`,
  });
};

const getTaskById = ({ taskId }) => {
  return axios({
    method: "get",
    baseURL: Config.baseUrl,
    url: `/tasks/${taskId}`,
  });
};

const getNodesByTask = ({ taskId }) => {
  return axios({
    method: "get",
    baseURL: Config.baseUrl,
    url: `/tasks/${taskId}/nodes`,
  });
};

const getNodesOutputByTask = ({taskId, nodeId, output}) => {
  const outputId = output.name_id;
  console.log('getNodesOutputByTask, nodeId, output:', nodeId.replace('/', ':'), output)
  return axios({
    method: "get",
    baseURL: Config.baseUrl,
    url: `/tasks/${taskId}/nodes/${nodeId.replace('/', ':')}/${outputId}`,
  });
};

const getNodeResultFromTask = ({ taskId, nodeId }) => {
  return axios({
    method: "get",
    baseURL: Config.baseUrl,
    url: `/tasks/${taskId}/nodes/${nodeId.replace('/', '\\')}`,
  });
};

export const Tasks = {
  get: {
    list: getTasks,
    byId: getTaskById,
    nodesTask: getNodesByTask,
    nodesOutput: getNodesOutputByTask,
    nodeResult: getNodeResultFromTask,
  },
};

import { createAsyncThunk } from "@reduxjs/toolkit";
import { Api } from "../../api";
import {
    convertLinks,
    convertNodes, divideProjectBlueprints,
    parseLinks,
    parseNodes,
} from "../../helpers/schema";
import { clearErrorNodes, setErrorNodes } from "../entities/tableSpace";
import { getArtifacts, getMSArtifacts } from "../../helpers/projects";
import {keys} from "@material-ui/core/styles/createBreakpoints";
import {GraphCanvas} from "../../components/graphCanvas/GraphCanvas";
import { changeRunTaskFlg, changeRunTaskId } from "../run_task/run_task";
import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import {fetchBlueprints} from "./tablespace";
import {parseBlueprints, parseProjectBlueprint} from "../../helpers/blueprints";
import {NodePort} from "../../components/graphCanvas/schemaControls";

export const fetchListOfProjects = createAsyncThunk(
    "projects.get",
    async () => {
        const response = await Api.projects.get.list({ filters: "contents" });
        console.log('fetchListOfProjects, response:', response.data)
        return response.data;
    }
);

// export const fetchProjectBlueprints = createAsyncThunk(
//     "projects.get.parse",
//     async () => {
//         const response = await Api.projects.get.list({ filters: "contents" });
//         console.log('fetchProjectBlueprints, response:', response.data)
//         const parsedProjects = await (parseProjectBlueprint(response.data));
//         console.log('fetchProjectBlueprints, parsed:', parsedProjects)
//         return parsedProjects;
//     }
// );

// export const fetchAllVisibleProjects = createAsyncThunk(
//   "projects.get",
//   async () => {
//     const response = await Api.projects.get.list({ filters: "" });
//       console.log('fetchAllVisibleProjects, response:', response.data)
//     return response.data;
//   }
// );

export const fetchDeletedProjects = createAsyncThunk(
    "projects.get.delList",
    async () => {
        console.log('fetchDeletedProjects')
        const response = await Api.projects.get.delList();
        console.log('fetchDeletedProjects, response:', response.data)
        return response.data;
    }
);


export const createNewProject = createAsyncThunk(
  "projects.post",
  async (formData) => {
      console.log('CreatingProject2, f_public:')
    const res = await Api.projects.post.create(formData);
    console.log('createNewProject (thunks), res:', res)
    return { ...formData, id: res.data.project_id, f_owner: 1 };
  }
);

const _generatePorts = (nodeId, ports, type) => {
    if (ports) {
        console.log('_generatePorts, ports', ports)
        return ports.map((port) => {
            return NodePort(nodeId, port, type === "inputs" ? "input" : "output");
        });
    }
    return [];
};

const checkUpdates = async ({new_nodes, links, uniqueBlueprintList, notes, blueprintBackList}) => {
    // ToDo
    // [x] Create unique pairs
    function unique(arr, keyProps) {
        const kvArray = arr.map(entry => {
            const key = keyProps.map(k => entry[k]).join('|');
            return [key, entry];
        });
        const map = new Map(kvArray);
        return Array.from(map.values());
    }
    console.log('set uniqueBlueprintList:', unique(uniqueBlueprintList, ['id', 'snapshot']));
    // uniqueBlueprintList = unique(uniqueBlueprintList, ['id', 'snapshot']);

    // [x] Fetch i/o
    try {
        blueprintBackList = blueprintBackList['data'];
        console.log('set uniqueBlueprintList:',blueprintBackList);
    } catch (err) {
        blueprintBackList = [];
        console.log("ERROR updater", err);
    }
    console.log('set blueprintBackList:', blueprintBackList);
    console.log('set new_nodes:', new_nodes);


    // [x] Comparing current state in projectData.data with fetched
    Object.keys(new_nodes).forEach(node_key => {
        if((node_key.indexOf('dummy', 0) === -1) && (new_nodes[node_key].type !== 'projectBlueprintPart')) {
            try {
                if (!Boolean(new_nodes[node_key].currentVersion) || Object.keys(new_nodes[node_key].versions).length === 0) {
                    new_nodes[node_key].currentVersion = new_nodes[node_key].blueprint[1];
                    new_nodes[node_key].versions = {
                        ...(blueprintBackList
                            .filter(bp => bp.id === new_nodes[node_key].blueprint[0]))[0].snapshots
                    }
                }
            } catch (e) {
                console.log("Error in versions:", (blueprintBackList
                    .filter(bp => bp.id === new_nodes[node_key].blueprint[0]))[0])
            }
            let bpBack;
            try {
                bpBack = blueprintBackList.filter(bp => bp.id === new_nodes[node_key].blueprint[0])
            }catch (e) {
                console.log("Error in filter:", e)
            }
            console.log("set bpBack:", bpBack);
            if(Boolean(bpBack.length)) {
                let snapshotBack = bpBack[0].snapshots.filter(snap => snap.name === new_nodes[node_key].blueprint[1])
                console.log('set snapshotBack:', snapshotBack);
                //    Inputs
                snapshotBack[0].inputs.forEach(snapInp => {
                    let locInp = new_nodes[node_key].inputs.filter(nnInp => nnInp.name_id === snapInp.name);
                    if (new_nodes[node_key].inputs.filter(nnInp => nnInp.name_id === snapInp.name).length > 0) {
                        console.log('Res:', new_nodes[node_key].inputs.filter(nnInp => nnInp.name_id === snapInp.name))
                        // new_nodes[node_key].inputs[new_nodes[node_key].inputs.length - 1][snapInp.name] = {
                        //     ...new_nodes[node_key].inputs[new_nodes[node_key].inputs.length - 1],
                        //     adapter: snapInp.adapter,
                        //     value: [...snapInp.value],
                        //     dynamic: snapInp.dynamic,
                        //     is_file: snapInp.type
                        // }
                        console.log("OK:",  new_nodes[node_key].inputs[new_nodes[node_key].inputs.length - 1])
                    } else {
                        new_nodes[node_key].inputs.push(_generatePorts(new_nodes[node_key].id, [snapInp], "inputs")[0])
                        console.log('Added input:', new_nodes[node_key].inputs)
                        new_nodes[node_key].inputs[new_nodes[node_key].inputs.length - 1][snapInp.name] = {
                            adapter: snapInp.adapter,
                            value: [...snapInp.value],
                            dynamic: snapInp.dynamic,
                            is_file: snapInp.type
                        };
                    }
                })


                console.log('set preDelInputs fin:', new_nodes[node_key].inputs)
                let localInputs = [...new_nodes[node_key].inputs];
                new_nodes[node_key].inputs.forEach(nnInp => {
                    console.log('set PostAdd:', snapshotBack[0].inputs.filter(snapInp => snapInp.name === nnInp.name_id))
                    if (snapshotBack[0].inputs.filter(snapInp => snapInp.name === nnInp.name_id).length > 0) {
                        console.log('Res:', snapshotBack[0].inputs.filter(snapInp => nnInp.name_id === snapInp.name))
                        console.log("OK")
                    } else {
                        console.log("Not OK")
                        localInputs.splice(localInputs.indexOf(nnInp), 1);
                    }
                });
                new_nodes[node_key].inputs = [...localInputs];
                console.log('set DelInputs fin:', new_nodes[node_key].inputs)
                //    Outputs
                snapshotBack[0].outputs.forEach(snapOutp => {
                    if (new_nodes[node_key].outputs.filter(nnOutp => nnOutp.name_id === snapOutp.name).length > 0) {
                        console.log('Res:', new_nodes[node_key].outputs.filter(nnOutp => nnOutp.name_id === snapOutp.name))
                        console.log("OK")
                    } else {
                        new_nodes[node_key].outputs.push(_generatePorts(new_nodes[node_key].id, [snapOutp], "outputs")[0])
                        console.log('Added output:', new_nodes[node_key].outputs)
                        new_nodes[node_key].outputs[new_nodes[node_key].outputs.length - 1][snapOutp.name] = {
                            adapter: snapOutp.adapter,
                            value: snapOutp.value,
                            is_file: snapOutp.type
                        };
                    }
                })

                let localOutputs = [...new_nodes[node_key].outputs];
                new_nodes[node_key].outputs.forEach(nnOutp => {
                    if (snapshotBack[0].outputs.filter(snapOutp => snapOutp.name === nnOutp.name_id).length > 0) {
                        console.log("OK")
                    } else {
                        console.log('set DelOutput:', new_nodes[node_key])

                        localOutputs.splice(localOutputs.indexOf(nnOutp), 1);
                    }
                });
                new_nodes[node_key].outputs = localOutputs;
                console.log('set DelOutput fin:', new_nodes[node_key].outputs)
            }
        }
    });

    try {
        Object.keys(notes.projectNodes).forEach((projectNode_key) => {
            new_nodes[notes.projectNodes[projectNode_key].id] = {...notes.projectNodes[projectNode_key]};
        });
    }catch (e) {
        console.log('Error with projectNodes:', e);
    }

    // [x] Comparing current links with new node's i/o
    let localLinks = [... links];
    console.log('set Links:', links);
    try {
        links.forEach(link => {

            console.log('set Links dst:', new_nodes[link['dst'][0]].inputs.filter(nnInp => nnInp.name_id === link['dst'][1]))
            console.log('set Links src:', new_nodes[link['src'][0]].outputs.filter(nnInp => nnInp.name_id === link['src'][1]))
            if (((new_nodes[link['dst'][0]].inputs.filter(nnInp => (nnInp.name_id === link['dst'][1] && (nnInp.dynamic || nnInp.dynamic === undefined))).length > 0) &&
                (new_nodes[link['src'][0]].outputs.filter(nnInp => nnInp.name_id === link['src'][1]).length > 0)) && (link.type !== 'projectBlueprintLink')) {
                console.log('set Link OK')
            } else {
                localLinks.splice(localLinks.indexOf(link), 1);
            }
        });
    }catch (e) {
        console.log("Error with Links:", e)
    }

    try {
        Object.keys(new_nodes).forEach(node_key => {
            if (new_nodes[node_key].type === 'projectBlueprintPart') {
                delete new_nodes[node_key];
            }
            ;
        })
    } catch (e) {
        console.log('Error with nodes delete:', e)
    }

    links = localLinks;
    console.log('set Links fin:', links)
    console.log('set Nodes fin:', new_nodes)
    // EndCheck
    return {links, new_nodes}
};

export const fetchProjectInfoById = createAsyncThunk(
  "projects.get.byId",
  async (args) => {
      const {projectId, snapshotName} = args;
      console.log('fetchProjectInfoById (thunks) 0', args);
      console.log('fetchProjectInfoById (thunks)', 1);
    const projectMetaInfo = await Api.projects.get.byId({ projectId });
      console.log('fetchProjectInfoById (thunks)', 2);
      console.log('fetchProjectInfoById (thunks) 2.5:', snapshotName);
    const projectData = await Api.projects.get.snapshot({ projectId, snapshotName });
    const blueprintBackList = await Api.blueprints.get.list({mode: '', request: ''})
      console.log('fetchProjectInfoById (thunks)', 3);
      console.log('fetchProjectInfoById (thunks), projectData', projectData);
      if (!projectData.data.links) {
          console.log('IF Links');
          projectData.data.links = [];
      }
      if (!projectData.data.nodes) {
          console.log('IF Nodes');
          projectData.data.nodes = [];
      }
    let { nodes, links, notes } = await projectData.data;
      console.log('fetchProjectInfoById (thunks)', 3.1);
      console.log('fetchProjectInfoById (thunks)', 3.2, notes);
      let run_task_id;
      let run_task_flg;
      let f_owner;

      if (projectData.data.geometry !== null) {
          run_task_id = projectData.data.geometry.run_task_id || -1;
          run_task_flg = projectData.data.geometry.run_task_flg || "";
          f_owner = projectData.data.f_owner || 0;
      } else{
          run_task_id = -1;
          run_task_flg = "";
          f_owner = 0;
      }
      console.log('fetchProjectInfoById (thunks)', 3.2);
      console.log('fetchProjectInfoById (thunks), runs', run_task_id, run_task_flg);

      let new_nodes = JSON.parse(JSON.stringify(nodes));
      let uniqueBlueprintList = []

      Object.keys(new_nodes).forEach(node_key => {
          new_nodes[node_key].inputs = [];
          new_nodes[node_key].outputs = [];
          Object.keys(nodes[node_key].inputs).forEach(input_key => {
              new_nodes[node_key].inputs.push(JSON.parse(JSON.stringify(nodes[node_key].inputs[input_key])));
          });
          Object.keys(nodes[node_key].outputs).forEach(output_key => {
              new_nodes[node_key].outputs.push(JSON.parse(JSON.stringify(nodes[node_key].outputs[output_key])));
          });
          uniqueBlueprintList.push({id: new_nodes[node_key].blueprint[0], snapshot: new_nodes[node_key].blueprint[1]});
      });

      let check = await checkUpdates({
      new_nodes: {...new_nodes}, links, uniqueBlueprintList, notes, blueprintBackList});
      new_nodes = check['new_nodes'];
      links = check['links'];

      console.log('fetchProjectInfoById (thunks)', 4);
      console.log('fetchProjectInfoById (thunks), nodes:', parseNodes(new_nodes));
      console.log('fetchProjectInfoById (thunks), 4.5:');
    const parsedSchema = {
      nodes: parseNodes(new_nodes),
      links: parseLinks(links),
      f_owner: f_owner,
      run_task_id: run_task_id,
      run_task_flg: run_task_flg,
    };
      console.log('fetchProjectInfoById (thunks)', 5);
      console.log('fetchProjectInfoById (thunks)', parsedSchema);
      try {
          console.log('fetchProjectInfoById (thunks)', projectData.data.geometry);
          // const dispatch = useDispatch();

          // changeRunTasks(projectData)
      } catch (err){
          console.log('fetchProjectInfoById changeRunTasks ERROR:', err);
      }
      console.log('fetchProjectInfoById (thunks)', 6);
    return {
      meta: projectMetaInfo.data,
      schema: {...parsedSchema},

    };
  }
);

export const patchProjectData = createAsyncThunk(
  "projects.patch.data",
  async (submitData, run_tasks) => {
      let snapshot = 'dev';
      if(Boolean(submitData.projectSnapshot)){
          snapshot = submitData.snapshot;
      }
      console.log('patchProjectData (thunks)', 1);
      console.log('patchProjectData (thunks)', submitData.schema.nodes);
      let convertedNodes;
    try {
        convertedNodes = convertNodes(submitData.schema.nodes);
    } catch (e) {
        console.log("Error with conversion:", e);
    }
    console.log("patchProjectData, convertedNodes:", convertedNodes);
    let convertedLinks = convertLinks(submitData.schema.links);

    console.log('patchProjectData convertedLinks', convertedLinks);
    const run_task_id = run_tasks.run_task_id || "";
    const run_task_flg = run_tasks.run_task_flg || "";

      let nodeProjects = {...convertedNodes.nodeProjectParts};
      convertedNodes = {...convertedNodes.nodesData};
      // Object.keys(convertedNodes).forEach((node) => {
      //     console.log('patchProjectData (thunks)', 1.1);
      //     if (convertedNodes[node].type === 'projectBlueprintPart'){
      //         nodeProjects.push(convertedNodes[node]);
      //     }
      // });

      const nodesLocal = {...submitData.schema.nodes};
      convertedNodes = divideProjectBlueprints({convertedNodes, nodeProjects, submitData: nodesLocal})

      // Links
      let projectNodes = submitData.schema.nodes.filter((node) => node.data.type === 'projectBlueprint');
      console.log('patchProjectData (thunks) 1.3', projectNodes);
      try {
          projectNodes.map((node) =>{
              console.log('patchProjectData (thunks), originalNode:', node);
              // Organic links for conversion
              if ( node.data.f_expand ){
                  const localLinksSrc = convertedLinks.map((link) => {
                    if (link.src[0].split("*")[0] === node.id){
                        return link
                    }
                  })
                  localLinksSrc.map((linkSrc) =>{
                      let newSrc;
                      try {
                          newSrc = Object.values(convertedNodes).filter((localNode) => {
                              if (localNode.last_id === linkSrc.src[0]) {
                                  return localNode;
                              }
                          })[0].id;
                          linkSrc.src[0] = newSrc;
                          linkSrc.id = `${linkSrc.src[0]},${linkSrc.src[1]},${linkSrc.dst[0]},${linkSrc.dst[1]}`
                      }catch (e) {
                          console.log("Error with Links 1:", e);
                      }
                      //
                      console.log("patchProjectData (thunks), Organic linkSrc", linkSrc)
                      console.log("patchProjectData (thunks), Organic newSrc", newSrc)
                  })
                  const localLinksDst = convertedLinks.map((link) => {
                      if (link.dst[0].split("*")[0] === node.id){
                          return link
                      }
                  })
                  localLinksDst.map((linkDst) =>{
                      let newDst;
                      try {
                          newDst = Object.values(convertedNodes).filter((localNode) => {
                              if (localNode.last_id === linkDst.dst[0]) {
                                  return localNode;
                              }
                          })[0].id;
                          linkDst.dst[0] = newDst;
                          linkDst.id = `${linkDst.src[0]},${linkDst.src[1]},${linkDst.dst[0]},${linkDst.dst[1]}`
                      }catch (e) {
                          console.log("Error with Links 2:", e);
                      }
                      //
                      console.log("patchProjectData (thunks), Organic linkSrc", linkDst)
                      console.log("patchProjectData (thunks), Organic newSrc", newDst)
                  })
              }

              // Links from projectBlueprints
              node.data.snapshot.links.map((link) => {
                  let dst = [];
                  let localDst, localSrc;
                  try {
                      localDst = Object.values(convertedNodes).filter((localNode) => {
                          console.log("Error reason:", localNode.last_id.split("*"))
                          if (localNode.last_id.split("*")[localNode.last_id.split("*").length-2] === node.id && localNode.last_id.split("*")[localNode.last_id.split("*").length-1] === link.dst[0]) {
                              return localNode;
                          }
                      })[0].id;
                  }catch (e) {
                      console.log("Error with Links 3:", e);
                  }
                  try{
                      localSrc = Object.values(convertedNodes).filter((localNode) => {
                          if (localNode.last_id.split("*")[localNode.last_id.split("*").length-2] === node.id && localNode.last_id.split("*")[localNode.last_id.split("*").length-1] === link.src[0]) {
                              return localNode;
                          }
                      })[0].id;
                  }catch (e) {
                      console.log("Error with Links 4:", e);
                  }
                  console.log("patchProjectData (thunks), localDst&localSrc:", [localDst, localSrc])
                  dst[0] = node.data.f_expand ? localDst : node.id + "*" + link.dst[0];
                  dst[1] = link.dst[1];
                  let src = [];
                  src[0] = node.data.f_expand ? localSrc : node.id + "*" + link.src[0];
                  src[1] = link.src[1];
                  let id = `${src[0]},${src[1]},${dst[0]},${dst[1]}`
                  let type = node.data.f_expand ? '' : 'projectBlueprintLink';
                  // let type = 'projectBlueprintLink';
                  convertedLinks.push({dst: dst, src: src, id: id, type: type});
              })
          })
      }catch (e) {
          console.log("Error with patching Links:", e)
      }
      console.log('patchProjectData (thunks) links', convertedLinks);
    let description;
    if(!Boolean(submitData.description)){
        description = ""
    } else {
        description = submitData.description;
    }
    const convertedSchema = { nodes: convertedNodes, links: convertedLinks,
                                run_task_id: run_task_id,
                                run_task_flg: run_task_flg,
                                notes: {projectNodes: {...projectNodes.filter((node) => node.data.f_expand === false),
                                        },
                                        description: description}};
      console.log('patchProjectData (thunks)', 4);
      console.log('patchProjectData (thunks), snapshot', snapshot);
    const formData = { ...submitData, schema: convertedSchema, snapshot };
      console.log('patchProjectData (thunks)', 5);
      console.log('patchProjectData (thunks), formData:', formData);
    const res = await Api.projects.patch.data(formData);
      console.log('patchProjectData (thunks)', 6);
    if (res) {
      localStorage.removeItem("unsent_project");
    }
  }
);

export const getMicroservicesList = createAsyncThunk(
  "microservices.get.list",
  async () => {
    const result = await Api.microservices.get.list({mode: '', request: ''});
    console.log('Microservices thunks:', result)
    return result.data.filter(function (elem) {
        return elem.f_owner || elem.f_fav;
    });
  }
);

export const getDeletedMicroservices = createAsyncThunk(
    "microservices.get.delList",
    async () => {
        console.log('fetchDeletedProjects')
        const response = []//await Api.microservices.get.delList();
        // console.log('getDeletedMicroservices, response:', response.data)
        return []//response.data;
    }
);

export const getMicroserviceInfo = createAsyncThunk(
    "microservice.get.byId",
    async ({ microserviceId }) => {
        const result = await Api.microservices.get.byId({ microserviceId });
        return result.data;
    }
);

export const getMicroserviceTaskInfo = createAsyncThunk(
    "microservice.get.msTaskById",
    async ({ microserviceId, taskId }) => {
        const result = await Api.microservices.get.msTaskById({microserviceId, taskId});
        console.log('MS getTaskInfo:', result)
        return result.data;
    }
);

export const getMicroserviceLaunches = createAsyncThunk(
    "microservice.get.msLaunches",
    async ({ microserviceId, taskId }) => {
        const result = await Api.microservices.get.msLaunches();
        console.log('MS Launches:', result)
        return result.data;
    }
);

export const getMicroserviceTaskResults = createAsyncThunk(
    "tasks.get.byId",
    async ({ taskId, schema }) => {
        console.log("getMicroserviceTaskResults, taskId:", taskId)
        // let response = await Api.tasks.get.byId({ taskId });
        // console.log("getMicroserviceTaskResults, response:", response)
        if (true){
        // if ((response.data.status === "done") || (response.data.status === "failed")) {
            const result = await getMSArtifacts({taskId, schema});
            console.log('MS getTaskResults fin:', result)
            return result;
        }
    }
);

export let fetchResults = createAsyncThunk(
    console.log('Thunks projects, Try to Fetch!'),
    async ({ task_id, cb, schema} , { dispatch }) => {
            console.log("fetchResults1")
            console.log("fetchResults, Schema:", schema)
            const { isGraphRunning } = useSelector((state) => state.ui.common);
            const artifacts = await getArtifacts({taskId: task_id, schema: {...schema}});
            dispatch(changeRunTaskFlg(0))
            console.log('fetchResults, graphResult_fin', artifacts)
            cb({...artifacts});
        }
);

export const runProject = createAsyncThunk(
  "projects.post.run",
  async ({stopGraphVar, projectId, cb, schema, workSpace }, { dispatch }) => {
    dispatch(clearErrorNodes());
    try {
        console.log('Start running')
        console.log('Start running, stopGraphVar', stopGraphVar)
        console.log('runProject, workSpace', workSpace)
      const res = await Api.projects.post.run({ projectId });
        dispatch(changeRunTaskFlg(1))
        dispatch(changeRunTaskId(res.data.task_id))

        console.log('Start patching in run Project1')
        const submitData = {
            projectId,
            schema: {...schema},
            run_tasks: {run_task_flg: 1, run_task_id: res.data.task_id}
        };
        console.log('Start patching in run Project2, submitData:', submitData)
        dispatch(patchProjectData(submitData));

        // workSpace.run_task_flg = 1;
        console.log("runProject, Schema:", schema)

      const artifacts = await getArtifacts({stopGraphVar, taskId: res.data.task_id, schema: {...schema}});


        console.log('runProject, graphResult_fin', artifacts)
        cb({...artifacts});
    } catch (err) {
      if (err.response.data.message === "incomplete input") {
        const { missing_fields: nodes } = err.response.data;
        const errNodesData = nodes.map((node) => {
          const [id, inputId] = node.split(":");
          return {
            id,
            inputId,
            message: `${err.response.data.message}: Input: ${inputId}`,
          };
        });
        dispatch(setErrorNodes(errNodesData));
      }
      console.log("Error when start graph", err);
    }
  }
);

import { createAsyncThunk } from "@reduxjs/toolkit";
import { parseBlueprints, parseAdapters } from "../../helpers/blueprints";
import { Api } from "../../api";

export const fetchBlueprints = createAsyncThunk("blueprints.get", async () => {
    try {
        const blueprints = await Api.blueprints.get.list({
            mode: "",
        });
        const deleted = []//await Api.blueprints.get.delList();

        console.log('BPs blueprints: ',blueprints);
        console.log('BPs deleted: ',deleted);
        return {
            global: parseBlueprints(blueprints || [], "dev"),
            deleted: [],//parseBlueprints(deleted || [], ""),
            // local: parseBlueprints(blueprints, "local"),
            local: [],
        };
    } catch (err) {
        console.log("ERROR", err);
    }

    // return parseBlueprints(blueprints);
});

export const fetchAdapters = createAsyncThunk("adapters.get", async () => {
    try {
        const adapters = await Api.adapters.get.list({
            mode: "",
        });
        const deleted = [] // await Api.adapters.get.delList()
        console.log('Adapters blueprints: ',parseBlueprints(adapters, "dev"));
        //console.log('Adapters deleted: ',parseBlueprints(deleted, ""));
        return {
            global: parseAdapters(adapters || [], "adapter"),
            deleted: [], //parseAdapters(deleted || [], "adapter"),
            // local: parseBlueprints(blueprints, "local"),
            local: [],
        };
    } catch (err) {
        console.log("ERROR", err);
    }

    // return parseBlueprints(blueprints);
});


export const editNode = createAsyncThunk("node.edit", async ({ formData }) => {
    console.log('editNode, formData', formData)
    await Api.blueprints.patch.dev(formData);
    await Api.blueprints.patch.main(formData);
  // await Api.blueprints.post.snapshot(formData);
});
export const createNode = createAsyncThunk(
    "node.create",
    async ({ formData, cb }) => {
        console.log('tablespace CreateNode')
        const bluePrint = await Api.blueprints.post.create(formData);
        console.log('tablespace CreateNode OK')
        console.log('tablespace CreatePatch')
        await Api.blueprints.patch.dev({
            ...formData,
            blueprint_id: bluePrint.data.blueprint_id,
        });
        console.log('tablespace CreatePatch OK')
        if (cb) {
            cb(bluePrint.data.blueprint_id);
        }
    }
);

export const editAdapter = createAsyncThunk("adapter.edit", async ({ formData }) => {
    console.log('editAdapter, formData', formData)
    await Api.adapters.patch.dev(formData);
    await Api.adapters.patch.main(formData);

    return "OK"
    // await Api.blueprints.post.snapshot(formData);
});
export const createAdapter = createAsyncThunk(
    "adapter.create",
    async ({ formData, cb }) => {
        console.log('tablespace createAdapter')
        const adapter = await Api.adapters.post.create(formData);
        console.log('tablespace createAdapter OK')
        console.log('tablespace CreatePatchAdapter, adapter', adapter)
        await Api.adapters.patch.dev({
            ...formData,
            blueprint_id: adapter.data.adapter_id,
        });
        console.log('tablespace CreatePatch OK')
        if (cb) {
            cb(adapter.data.adapter_id);
        }
    }
);

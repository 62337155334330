import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Typography,
  TextField,
  Box,
  MenuItem,
  Button,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import {getMicroserviceInfo, getMicroserviceTaskInfo} from "../../store/thunks/projects";
import { Api } from "../../api";
import IconButton from "@material-ui/core/IconButton";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import {fetchAdapters} from "../../store/thunks/tablespace";
import { useHistory } from "react-router-dom";

const FileButton = () => (
    <IconButton
        variant="contained"
        component="label"
    >
      <InsertDriveFileIcon />
    </IconButton>
)

export const MicroserviceForm = () => {
  const history = useHistory();
  let values = [];
  let out_values = [];
  let [inputs, setInputs] = useState({
    values: [],
  });
  let [outputs, setOutputs] = useState({
    out_values: [],
  });
  // const [formData, setFormData] = useState({});
  const {microserviceInfo} = useSelector((state) => state.entities.projects);
  // const [microserviceData, setMicroserviceData] = useState(null);
  const dispatch = useDispatch();
  const {id} = useParams();
  useEffect(() => {
    if (microserviceInfo) {
      dispatch(fetchAdapters());
    }
  }, [microserviceInfo]);
  const adaptersList = useSelector((state) => [
    ...state.entities.adapters.global,
    //     ...state.entities.adapters.local,
  ]);

  console.log('MS0, id:', id)
  // console.log('MS1, formData:', formData)
  console.log('MS2, microserviceInfo:', microserviceInfo)
  // console.log('MS3, microserviceData:', microserviceData)
  useEffect(() => {
    if (microserviceInfo) {
      if (parseInt(microserviceInfo.id) === parseInt(id)) {
        console.log("MS Inputs")
        // input_key['id'].slice(0, input_key['id'].indexOf("|", 0))+":"+input_key['name_id']
        microserviceInfo.inputs.forEach((input) => {
          microserviceInfo._data.nodes.forEach((node) => {
            if ((input.slice(0, input.indexOf(":", 0)) === node.id) ||
                (input.split("*")[0] === node.id)) {
              node.inputs.forEach((inp) => {
                if (input.slice(input.indexOf(":", 0) + 1) === inp['name_id']) {
                  values.push({...inp});
                }
              });

              node.outputs.forEach((inp) => {
                if (inp.avaliable) {
                  out_values.push({...inp});
                };
              });
            }
            ;
          });
        })

        setInputs({values});
        setOutputs({out_values});
        console.log("MS Inputs:", inputs)
      }
    }
  }, [microserviceInfo]);

  const handleEditPortValue = (e, name) => {
    console.log('NodeProperties, handleEditPortValue, e', e.target.value)
    console.log('NodeProperties, handleEditPortValue, name', name)
    inputs.values.forEach(inp=>{
      if (inp.id === name) {
        inp.value = ['inline', e.target.value];
      }
    });
    console.log('NodeProperties, handleEditPortValue, inputs', inputs)
  };

  const handleClickEditPortValueFile = async (e, name) => {
    const input = document.getElementById('file-upload');

    input.click()
  };

  const handleEditPortValueFile = async (e, name) => {
    console.log('MS, handleEditPortValueFile, event.target.files[0]:', e.target.files[0])
    console.log('MS, handleEditPortValueFile, name:', name)
    console.log('MS, handleEditPortValueFile, project_id:', microserviceInfo.project_id)

    let f = e.target.files[0];
    let formData = new FormData();
    formData.append('file', f)

    const a = await Api.projects.post.upfile({
      formData,
      projectId: microserviceInfo.project_id
    });
    console.log("File upload output:", a);

    inputs.values.forEach(inp=>{
      if (inp.name_id === name) {
        inp.value = ['userfile', [a.data.file_id, a.data.file_id]];
        inp.f_name = a.data.file_id;
        inp.value[1][0] = a.data.file_id;
        inp.f_key = a.data.file_id;
      }
    });
  };
  //
  // const handleClickDownloadPortValueFile = async (e, name) => {
  //   let taskId;
  //   let nameId;
  //   let nodeId;
  //   let extension = "";
  //
  //   console.log("Adapters in NodeProperties:", adaptersList)
  //
  //   outputs.outp_values.forEach(out=>{
  //     if (out.name_id === name) {
  //       taskId = out.task_id;
  //       nameId = out.name_id;
  //       nodeId = out.node_id;
  //
  //       adaptersList.forEach(adapter =>{
  //         if (adapter.blueprint_id === out.adapter[0]){
  //           extension = adapter.extension;
  //           console.log("Found ext:", extension);
  //         }
  //       });
  //     }
  //   });
  //
  //
  //
  //   var file = await Api.projects.get.downfile({
  //     task_id: taskId,
  //     output_name: nameId,
  //     node_id: nodeId
  //   });
  //
  //   var blob=new Blob([file.data], {type:(file.headers['content-type']+";charset=utf-8")});
  //   var link=document.createElement('a');
  //   link.type = file.headers['content-type']+";charset=utf-8";
  //   link.href=window.URL.createObjectURL(blob);
  //   var name = (nameId + extension).toString();
  //   link.download=name;
  //   link.click();
  // };


  const handleSubmit = async () => {
    const { id: id, project_id: project_id } =
      microserviceInfo;

    console.log('MS pre-run, inputs:', inputs)
    console.log('MS pre-run, id:', id)

    const result = await Api.microservices.post.run({
      id: id,
      projectId: project_id,
      formData: inputs,
    });
    let task_id = result.data.task_id;

    history.push(`/ms_task?ms_id=${id}&task_id=${task_id}`)
  };

  useEffect(() => {
    if (id) {
      dispatch(getMicroserviceInfo({ microserviceId: id }));
    }
  }, [id]);

  // useEffect(() => {
  //   if (microserviceInfo && microserviceInfo.length) {
  //     const latest = microserviceInfo.reduce((prev, cur) => {
  //       const prevDate = new Date(prev.update_dt);
  //       const currentDate = new Date(cur.update_dt);
  //       return currentDate > prevDate && cur;
  //     });
  //     if (latest) {
  //       setFormData(latest.inputs);
  //       setMicroserviceData(latest);
  //     }
  //   }
  // }, [microserviceInfo]);

  console.log('MS Inputs:', inputs)

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      alignItems={"center"}
      justifyContent={"center"}
      height={"90vh"}
    >
      {/*{Boolean(microserviceInfo && microserviceInfo.length) && (*/}
      {/*  <TextField*/}
      {/*    select*/}
      {/*    variant={"filled"}*/}
      {/*    value={microserviceData}*/}
      {/*    onChange={(e) => setMicroserviceData(e.target.value)}*/}
      {/*    label={"Select service version"}*/}
      {/*    style={{ marginBottom: "1rem" }}*/}
      {/*    InputLabelProps={{ shrink: true }}*/}
      {/*  >*/}
      {/*    {microserviceInfo.map((service) => (*/}
      {/*      <MenuItem value={service}>*/}
      {/*        {(() => {*/}
      {/*          const date = new Date(service.update_dt);*/}
      {/*          const z = (value) => {*/}
      {/*            return ("0" + value).slice(-2);*/}
      {/*          };*/}
      {/*          const YY = date.getFullYear();*/}
      {/*          const MM = z(date.getMonth() + 1);*/}
      {/*          const DD = z(date.getDate());*/}
      {/*          const hh = z(date.getHours());*/}
      {/*          const mm = z(date.getMinutes());*/}
      {/*          const ss = z(date.getSeconds());*/}
      {/*          return `${DD}/${MM}/${YY} ${hh}:${mm}:${ss}`;*/}
      {/*        })()}*/}
      {/*      </MenuItem>*/}
      {/*    ))}*/}
      {/*  </TextField>*/}
      {/*)}*/}
      {Boolean(Object.keys(inputs).length) ? (
        <>
          <Typography variant={"h3"}>Inputs:</Typography>

          {inputs.values.map((inp) => (
              (inp.available)?
                (inp.value[0] === 'inline')
                    ?<TextField
                        variant={"filled"}
                        style = {{width: 300}}
                        key={inp.id}
                        label={inp.id.toString().length > 25 ? inp.id.toString().slice(0, 25)+"..." : inp.id}
                        defaultValue={inp.value[1]}
                        onChange={(e) => handleEditPortValue(e, inp.id)}
                    />:
                    <form>

                      <TextField
                          style = {{width: 300}}
                          variant={"filled"}
                          type="file"
                          multiline
                          // maxRows={3}
                          disabled={true}
                          key={inp.id}
                          label={inp.id.length > 25 ? inp.id.toString().slice(0, 25)+"..." : inp.id}
                          defaultValue={inp.value[1][0]}
                          onClick={(e) => handleClickEditPortValueFile(e, inp.id)}
                          InputProps={{endAdornment: <FileButton />}}
                      />
                      <input
                          id="file-upload"
                          type="file"
                          hidden
                          onChange={(e) => handleEditPortValueFile(e, inp.name_id)}
                      />
                    </form>
                : <></>
          ))}
          <Box mt={2}>
            <Button onClick={handleSubmit}>Submit</Button>
          </Box>
        </>
      ) : (
        "No info or incorrect data"
      )}
    </Box>
  );
};

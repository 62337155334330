import React, {useState, setState, useEffect} from "react";
import {
  Box,
  Button,
  ButtonGroup,
  Grid,
  makeStyles,
  Table, TableRow,
  TextField,
  Typography,
} from "@material-ui/core";
import { ProjectCard } from "../common/ProjectCard/ProjectCard";
import { Api } from "../api";
import projects from "../store/entities/projects";
import {adapters} from "../store/entities/adapters";
import {blueprints} from "../store/entities/blueprints";
import {useHistory, useParams} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  rootWrapper: {
    position: "relative",
    display: "flex",
    justifyContent: "center",
    width: "100%",
    margin: "0 auto",
  },
  searchInput: {
    marginBottom: theme.spacing(2),
  },
  projectsWrapper: {},
  projectsList: {
    // display: "flex",
    flexDirection: "column",
    width: "100%",
    alignItems: "center",
  },
  projectsHeader: {
    padding: theme.spacing(2, 0),
  },
  popularWrapper: {},
  chipItem: {
    margin: theme.spacing(0.1),
  },
}));

const popularTags = [
  { id: 1, label: "java" },
  { id: 2, label: "hold" },
  { id: 3, label: "case" },
  { id: 4, label: "deep" },
  { id: 5, label: "list" },
  { id: 6, label: "army" },
  { id: 7, label: "bird" },
  { id: 8, label: "blender" },
  { id: 9, label: "C++" },
];

export const DashboardUser = () => {
  const classes = useStyles();
  let { userId } = useParams();
  const [searchRequest, setSearchRequest] = useState('');
  const [buttonsGroupAnchor, setButtonGroupAnchor] = useState(null);
  const [selectedTags, setSelectedTags] = useState([]);
  const [showOwners, setShowOwners] = useState(false);
  const [selectedEntities, setSelectedEntities] = useState({
    projects:1, blueprints: 0, adapters: 0, microservices: 0});
  const history = useHistory();

  const [query, setQuery] = useState({
    projects: [], blueprints: [], adapters: [], microservices: []});

  useEffect(async () => {
    if (userId) {
      console.log('DashboardUser, request: ', userId);
      let query = {...await Api.user.get.content({userId})}
      console.log('DashboardUser, query: ', query.data);
      setQuery({...(query.data)});
      // let selectedEntities_local = {...selectedEntities}
      setSelectedEntities({projects:1, blueprints: 0, adapters: 0, microservices: 0});
      setSelectedEntities({...selectedEntities});
    };
  }, [])

  const handleTagClick = (tagId) => {
    const isTagSelected = selectedTags.includes(tagId);
    if (isTagSelected) {
      return setSelectedTags((prev) => prev.filter((tag) => tag !== tagId));
    }
    return setSelectedTags((prev) => [...prev, tagId]);
  };

  const checkSelectedTag = (tagId) => selectedTags.includes(tagId);

  const handleOpenMenu = (e) => {
    setButtonGroupAnchor(e.currentTarget);
  };
  const handleCloseMenu = () => {
    setButtonGroupAnchor(null);
  };

  const handleEditSearchRequest = (e) => {
    console.log('DashboardUser, handleEditSearchRequest, e', e.target.value)
    setSearchRequest(e.target.value.toLowerCase())
  };

  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  console.log("rendering...");

  const handleClickChangeEntity = async (e) => {
    console.log('DashboardUser, handleClickChangeEntity, e', e)

    Object.keys(selectedEntities).map(ent => {
      if(ent === e){
        selectedEntities[ent] = 1;
      } else {
        selectedEntities[ent] = 0;
      }
    })

    console.log('DashboardUser, handleClickChangeEntity, selectedEntities', selectedEntities)
    setSelectedEntities({...selectedEntities});
  };

  const checkSelectedEntities = (tagId) =>Boolean(selectedEntities[tagId]);

  const handleOpenProject = async ({ id }) => {
    history.push(`/tablespace/${id}`);
  };

  const handleOpenBlueprint = async ({ blueprint_id, type, f_owner }) => {
    if (f_owner) {
      history.push(`/nodes/create_node?edit=true&node_id=${blueprint_id}&type_id=${type}`);
    } else {
      history.push(`/nodes/create_node?edit=false&node_id=${blueprint_id}&type_id=${type}`);
    }
  };

  const handleEditShowOwner = async (e) => {
    // console.log('EditMS, handleEditName:', e.target.value)
    setShowOwners(e)
  };

  const handleSetFav = async (id) => {
    let localQuery = {...query};
    localQuery.microservices.filter(ms => ms.id === id)[0].f_fav = !(localQuery.microservices.filter(ms => ms.id === id)[0].f_fav);

    setQuery({...localQuery})
    setSelectedEntities({projects:1, blueprints: 0, adapters: 0, microservices: 0});
    setSelectedEntities({...selectedEntities});
  };

  return (
      <Grid container spacing={3} className={classes.rootWrapper}>
        <Grid item xs={12} sm={8}>
          <TextField
              label={"Enter search request"}
              variant={"filled"}
              fullWidth
              defaultValue={''}
              className={classes.searchInput}
              onChange={(e) => handleEditSearchRequest(e)}
          />
          <Box>
            <ButtonGroup color={"primary"} size={"small"}>
              <Button variant={checkSelectedEntities("projects") ? "contained" : "outlined"}
                      onClick={() => handleClickChangeEntity("projects")}> Projects
              </Button>
              <Button variant={checkSelectedEntities("blueprints") ? "contained" : "outlined"}
                      onClick={() => handleClickChangeEntity("blueprints")}> blueprints
              </Button>
              <Button variant={checkSelectedEntities("adapters") ? "contained" : "outlined"}
                      onClick={() => handleClickChangeEntity("adapters")}> adapters
              </Button>
              <Button variant={checkSelectedEntities("microservices") ? "contained" : "outlined"}
                      onClick={() => handleClickChangeEntity("microservices")}> microservices
              </Button>

              {/*</Menu>*/}
            </ButtonGroup>
          </Box>
          <Box>
            <br/>
            <label>
              <input
                  type="checkbox"
                  defaultChecked={false}
                  // ref="complete"
                  onChange={(e) => handleEditShowOwner(e.target.checked)}
              />
              Show My Projects
            </label>

          </Box>
          <Typography
              variant={"h3"}
              className={classes.projectsHeader}
              align={"center"}
          >
          </Typography>


          {/*Projects*/}
          {Boolean(query.projects.length) & Boolean(selectedEntities.projects) ?
              (query.projects.filter(project => project.name.toLowerCase().indexOf(searchRequest) > -1 ||
                                              project.description.toLowerCase().indexOf(searchRequest) > -1).map((project) => (
                  <Table className={classes.projectsList}>
                    <TableRow hover role="checkbox" tabIndex={-1} >
                      <ProjectCard
                          title={project.name}
                          tags={Array.isArray(project.tags)?project.tags.join().split(',')
                              :project.tags.split(',')}
                          key={project.id}
                          description={project.description}
                          onClick={() => handleOpenProject(project)}
                          f_owner={project.f_owner}
                          entity={'project'}
                          id={project.id}
                          checked_def={false}
                          language={project.language}
                          search={true}
                      />

                    </TableRow>
                  </Table>)
              )): (<></>)}

          {/*Blueprints*/}
          {Boolean(query.blueprints.length) & Boolean(selectedEntities.blueprints) ?
              (query.blueprints.filter(blueprint => blueprint.name.toLowerCase().indexOf(searchRequest) > -1 ||
                  blueprint.description.toLowerCase().indexOf(searchRequest) > -1).map((project) => (
                  <Table>
                    <TableRow hover role="checkbox" tabIndex={-1} >
                      <ProjectCard
                          title={project.typeId}
                          tags={[]}
                          key={project.name}
                          description={project.description}
                          onClick={() => handleOpenBlueprint(project)}
                          f_owner={project.f_owner}
                          entity={project.type}
                          id={project.blueprint_id}
                          checked_def={false}
                          language={project.language}
                          search={true}
                      />

                    </TableRow>
                  </Table>)
              )): (<></>)}

          {/*Adapters*/}
          <Box>
            {Boolean(query.adapters.length) & Boolean(selectedEntities.adapters) ?
                (query.adapters.filter(adapter => adapter.name.toLowerCase().indexOf(searchRequest) > -1 ||
                    adapter.description.toLowerCase().indexOf(searchRequest) > -1).map((project) => (
                    <Table>
                      <TableRow hover role="checkbox" tabIndex={-1} >
                        <ProjectCard
                            title={project.typeId}
                            tags={[]}
                            key={project.name}
                            description={project.description}
                            onClick={() => handleOpenBlueprint(project)}
                            f_owner={project.f_owner}
                            entity={project.type}
                            id={project.blueprint_id}
                            checked_def={false}
                            language={project.language}
                            search={true}
                        />

                      </TableRow>
                    </Table>)
                )): (<></>)}
          </Box>

          {/*Microservices*/}
          <Box>
            {Boolean(query.microservices.length) & Boolean(selectedEntities.microservices) ?
                (query.microservices.filter(microservice => microservice.name?.toLowerCase().indexOf(searchRequest) > -1 ||
                    microservice.description?.toLowerCase().indexOf(searchRequest) > -1).map((mService) => (
                    <Table>
                      <TableRow hover role="checkbox" tabIndex={-1} >
                        <ProjectCard
                            key={mService.id}
                            title={mService._data?.meta?.name || ''}
                            onClick={() => history.push(`/microservices/${mService.id}`)}
                            description={mService._data?.meta?.description || ''}
                            f_owner={mService.f_owner}
                            entity={'microservice'}
                            id={mService.id}
                            search={true}
                            checked_def={mService.f_fav}
                            cust_func={handleSetFav}
                        />

                      </TableRow>
                    </Table>)
                )): (<></>)}
          </Box>
        </Grid>
      </Grid>
  );
};

import { createSlice } from "@reduxjs/toolkit";
import { parseObjectToArray } from "../../helpers/parseObjectToArray";
import { fetchAdapters } from "../thunks/tablespace";

export const adapters = createSlice({
    name: "adapters",
    initialState: {
        global: [],
        local: [],
        deleted: [],
    },
    reducers: {
        setUserAdapters: (state, action) => {
            state.userAdapters = parseObjectToArray(action.payload);
        },
    },
    extraReducers: {
        [fetchAdapters.fulfilled]: (state, action) => {
            const { global, local, deleted } = action.payload;
            state.global = global;
            state.deleted = deleted;
            state.local = local;
        },
    },
});

export const { setUserAdapters } = adapters.actions;
export default adapters.reducer;

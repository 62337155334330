import React, { useEffect, useState } from "react";

export const useDebounce = (callback, debounce = 500) => {
  const [inputData, setInputData] = useState(null);

  useEffect(() => {
    let timer = null;
    if (inputData && callback) {
      timer = setTimeout(callback, debounce);
    }
    return () => clearTimeout(timer);
  }, [debounce, inputData]);

  return setInputData;
};

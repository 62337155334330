import React, { useEffect, useState } from "react";
import { ClickAwayListener, fade, makeStyles, Paper } from "@material-ui/core";
import { MiniMapNode } from "./MinimapNode";

const getLayoutParams = (canvas) => ({
  page: { x: canvas.offsetWidth, y: canvas.offsetHeight },
  screen: { x: window.innerWidth, y: window.innerHeight },
  scroll: { x: window.scrollX, y: window.scrollY },
});

const useStyles = makeStyles((theme) => ({
  page: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    overflow: "hidden",
    cursor: "crosshair",
  },
  screen: {
    position: "absolute",
    backgroundColor: fade(theme.palette.background.secondary, 0.3),
  },
}));

export const Minimap = ({ nodes, scaling = 10, canvasSize }) => {
  const [isMoving, setMoving] = useState(false);
  const [minimapConfig, setMinimapConfig] = useState(null);
  const classes = useStyles();
  const handleMouseDown = () => {
    setMoving(true);
  };

  const handleMouseUp = () => {
    setMoving(false);
  };

  const handleScroll = () => {
    const { scrollX: x, scrollY: y } = window;
    setMinimapConfig((prev) => ({ ...prev, scroll: { x, y } }));
  };

  const handleMoveScreen = (coords) => {
    const { x, y } = coords;
    setMinimapConfig((prev) => ({
      ...prev,
      scroll: { x: Math.floor(x) * scaling, y: Math.floor(y) * scaling },
    }));
    window.scrollTo(Math.floor(x) * scaling, Math.floor(y) * scaling);
  };

  const handleMouseMove = (e) => {
    //TODO: set screen position on click event
    if (isMoving) {
      e.preventDefault();
      const rect = e.currentTarget.getBoundingClientRect();
      let x = e.clientX - rect.left - 50; //x position within the element.
      let y = e.clientY - rect.top - 35; //y position within the element.
      if (x <= 0) {
        x = 0;
      }
      if (y <= 0) {
        y = 0;
      }
      const canvasWidth = canvasSize.x / scaling;
      const canvasHeight = canvasSize.y / scaling;
      const rightScreenEdge = minimapConfig.screen.x / scaling + x;
      const bottomScreenEdge = minimapConfig.screen.y / scaling + y;

      if (rightScreenEdge >= canvasWidth) {
        x = canvasWidth - minimapConfig.screen.x / scaling + 2;
      }
      if (bottomScreenEdge >= canvasHeight) {
        y = canvasHeight - minimapConfig.screen.y / scaling + 2;
      }
      handleMoveScreen({ x, y });
    }
  };

  const handleResize = () => {
    setMinimapConfig((prev) => ({
      ...prev,
      screen: { x: window.innerWidth, y: window.innerHeight },
    }));
  };

  useEffect(() => {
    const canvas = document.getElementsByClassName("_canvas")[0];
    const layouts = getLayoutParams(canvas);
    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleResize);
    //TODO: change size of layers when window resized
    setMinimapConfig(layouts);
  }, []);

  useEffect(() => {
    if (canvasSize.x !== 0 && canvasSize.y !== 0) {
      setMinimapConfig((prev) => ({ ...prev, page: canvasSize }));
    }
  }, [canvasSize]);

  return !minimapConfig ? (
    <div>no minimap config</div>
  ) : (
    <ClickAwayListener onClickAway={handleMouseUp} mouseEvent={"onMouseUp"}>
      <Paper
        variant={"outlined"}
        className={classes.page}
        style={{
          width: minimapConfig.page.x / scaling,
          height: minimapConfig.page.y / scaling,
        }}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        onMouseMove={handleMouseMove}
      >
        {nodes &&
          nodes.map((node, index) => (
            <MiniMapNode
              coordinates={node.coordinates}
              scaling={scaling}
              key={index}
            />
          ))}
        <Paper
          className={classes.screen}
          variant={"outlined"}
          style={{
            left: minimapConfig.scroll.x / scaling - 1,
            top: minimapConfig.scroll.y / scaling - 1,
            width: minimapConfig.screen.x / scaling - 1,
            height: minimapConfig.screen.y / scaling - 1,
          }}
        />
      </Paper>
    </ClickAwayListener>
  );
};

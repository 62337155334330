import React, {useEffect, useState, useCallback} from "react";
import {
  Box,
  Button,
  Dialog,
  TextField,
  Typography,
  Table,
  TableRow,
  TableCell,
  TableHead,
  Checkbox
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import {Api} from "../../api";
// import {patchProjectData} from "../../store/thunks/projects";
import {useDispatch} from "react-redux";
import {convertNodes, divideProjectBlueprints} from "../../helpers/schema";

const FileButton = () => (
    <IconButton
        variant="contained"
        component="label"
    >
      <InsertDriveFileIcon />
    </IconButton>
)

const inputsTableHead = [
  { id: 0, title: "Port's Name and Default Value" },
  { id: 1, title: "Available for User" },
];

const outputsTableHead = [
  { id: 0, title: "Port's Name" },
  { id: 1, title: "Available for User" },
];

let start_flg = false;

export const flgChanger = () =>{
  start_flg = true;
}

export const EditMS = ({ isOpen, classes, onClose, fetchMSIO, patchProject }) => {
  let values = [];
  let out_values = [];
  let ws = {};

  let [inputs, setInputs] = useState({
    values: [],
  });
  let [data, setData] = useState({
    values: null,
  });
  let [outputs, setOutputs] = useState({
    out_values: [],
  });
  let [workspace, setWorkspace] = useState({
    ws: {},
  });
  let [snapshots, setSnapshots] = useState({
    snaps: [],
  });
  let [projectId, setProjectId] = useState({});
  let [meta, setMeta] = useState({name:"", description:""});
  let ms_inputs = null;
  let ms_workspace = null;
  const dispatch = useDispatch();

  const fetchData = useCallback(async () => {
    const data = await fetchMSIO();
    setData(data);
  }, [])

  useEffect(() => {
    if (start_flg) {
      fetchData();
      console.log("Page EditMS, data:", data)
      start_flg = false;
    };
  }, [start_flg])


  useEffect(() => {
    if(data.values !== null) {
      console.log("EditMS, data:", data)
      ms_inputs = data.inputs.data.inputs;
      ms_workspace = JSON.parse(JSON.stringify(data.workSpace));
      setProjectId(data.projectId);
      setMeta({...data.workProject});
      setSnapshots(data.snapshots);
      console.log("EditMS, ms_inputs:", ms_inputs)
      console.log("EditMS, ms_workspace:", ms_workspace)
      console.log("EditMS, data.workProject:", data.workProject)
      console.log("EditMS, snapshots:", data.snapshots)
      ms_inputs.forEach((input) => {
        ms_workspace.nodes.forEach((node) => {
          if ((input.slice(0, input.indexOf(":", 0)) === node.id) ||
              (input.split("*")[0] === node.id)) {
            node.inputs.forEach((inp) => {
              if ((input.slice(input.indexOf(":", 0) + 1) === inp['name_id'])) {
                let tmp_obj = {...inp};
                tmp_obj.available = true;
                values.push({...tmp_obj});
                ms_workspace.nodes[ms_workspace.nodes.indexOf(node)].inputs[node.inputs.indexOf(inp)] = {...tmp_obj};
              }
            });
          }
        });
      });

      ms_workspace.nodes.forEach((node) => {
        if(node.id.slice(0, 5) !== 'dummy')
        {
          node.outputs.forEach((inp) => {
            let tmp_obj = {...inp};
            tmp_obj.available = false;
            out_values.push({...tmp_obj});
            ms_workspace.nodes[ms_workspace.nodes.indexOf(node)].outputs[node.outputs.indexOf(inp)] = {...tmp_obj};
          });
        }
      });

      ws = JSON.parse(JSON.stringify(ms_workspace));

      setWorkspace({ws});
      setInputs({values});
      setOutputs({out_values});
    }
  }, [data])

  console.log("MS Inputs:", inputs)
  console.log("MS Outputs:", outputs)

  const handleCheckInp = (e, key) => {
    const value = e.target.checked;
    console.log('EditMS, handleCheckInp, value:', value);
    console.log('EditMS, handleCheckInp, key:', key);

    workspace.ws.nodes.forEach((node) => {
      node.inputs.forEach((inp) => {
        if (inp.id === key)
          inp['available'] = value;
      });
    });

    console.log("EditMS, handleCheckInp, workspace:", workspace)
  }

  const handleCheckOut = (e, key) => {
    const value = e.target.checked;
    console.log('EditMS, handleCheckOut, value:', value);
    console.log('EditMS, handleCheckOut, key:', key);
    console.log("EditMS, handleCheckOut, ms_workspace:", workspace)

    workspace.ws.nodes.forEach((node) => {
      node.outputs.forEach((inp) => {
        if (inp.id === key)
          inp['available'] = value;
      });
    });

    console.log("EditMS, handleCheckOut, ms_workspace:", workspace)
  }

  const handleEditPortValue = (e, name) => {
    console.log('NodeProperties, handleEditPortValue, e', e.target.value)
    console.log('NodeProperties, handleEditPortValue, name', name)
    inputs.values.forEach(inp=>{
      if (inp.id === name) {
        inp.value = ['inline', e.target.value];
      }
    });

    workspace.ws.nodes.forEach((node) => {
      node.inputs.forEach((inp) => {
        if (inp.id === name)
          inp.value = ['inline', e.target.value];
      });
    });
    console.log('NodeProperties, handleEditPortValue, inputs', workspace)
  };

  const handleClickEditPortValueFile = async (e, name) => {
    const input = document.getElementById('file-upload');

    input.click()
  };

  const handleEditPortValueFile = async (e, name) => {
    console.log('MS, handleEditPortValueFile, event.target.files[0]:', e.target.files[0])
    console.log('MS, handleEditPortValueFile, name:', name)

    let f = e.target.files[0];
    let formData = new FormData();
    formData.append('file', f)

    const a = await Api.projects.post.upfile({
      formData,
      projectId: projectId
    });
    console.log("File upload output:", a);

    inputs.values.forEach(inp=>{
      if (inp.id === name) {
        inp.value = ['userfile', [a.data.fname, a.data.key]];
        inp.f_name = a.data.fname;
        inp.value[1][0] = a.data.fname;
        inp.f_key = a.data.key;
      }
    });

    let ws = JSON.parse(JSON.stringify(workspace.ws));
    ws.nodes.forEach((node) => {
      node.inputs.forEach((inp) => {
        if (inp.id === name) {
          inp.value = ['userfile', [a.data.fname, a.data.key]];
          inp.f_name = a.data.fname;
          inp.value[1] = [];
          inp.value[1][0] = a.data.fname;
          inp.value[1][1] = a.data.key;
          inp.f_key = a.data.key;
        }
      });
    });
    setWorkspace({ws});
  };

  const handleSubmit = async () => {
    function unique(arr, keyProps) {
      const kvArray = arr.map(entry => {
        const key = keyProps.map(k => entry[k]).join('|');
        return [key, entry];
      });
      const map = new Map(kvArray);
      return Array.from(map.values());
    }

    console.log('GraphCanvas, create Microservice!', projectId)
    console.log('GraphCanvas, create Microservice!', meta=== {})
    if (Object.keys(meta).length === 0 || meta.name === ""){
      return 0
    }
    if (!meta['description']){
      meta['description'] = "";
    }
    let snapshot_names = [-1];

    snapshots.forEach((snap) => {
      snapshot_names.push(parseInt(snap.name.slice(3)))
    })

    console.log("EditMS, snap names:", snapshot_names)
    console.log('EditMs, snap name\'s max:', "ms_"+(Math.max.apply(null, snapshot_names) + 1).toString())

    let new_ms_snap_name = "ms_"+(Math.max.apply(null, snapshot_names) + 1).toString()

    let ms_workspace = JSON.parse(JSON.stringify(data.workSpace));
    let ms_workspaceSnap = JSON.parse(JSON.stringify(data.workSpace));
    let uniqueBPList = [];

    // Unique Blueprints in Project
    let convertedNodes;
    try {
      convertedNodes = convertNodes(ms_workspace.nodes);
      convertedNodes = {...convertedNodes.nodesData};
    } catch (e) {
      console.log("Error with conversion:", e);
    }
    let nodeProjects = {...convertedNodes.nodeProjectParts};
    const nodesLocal = {...ms_workspace.nodes};
    convertedNodes = divideProjectBlueprints({convertedNodes, nodeProjects, submitData: nodesLocal})

    console.log("EditMS, unique entities conv:", convertedNodes)

    // Create set of Blueprints in Project
    Object.values(convertedNodes).forEach((node) => {
      console.log("EditMS, node:", node)
      let new_bp_snap_name = projectId.toString() + "_" + meta.name + "_"+(Math.max.apply(null, snapshot_names) + 1).toString();
      uniqueBPList.push({id: node.data.blueprintId, snapshot: new_bp_snap_name});
    });

    uniqueBPList = unique(uniqueBPList, ['id', 'snapshot']);

    console.log("EditMS, unique entities bps:", uniqueBPList)

    console.log("EditMS, ms_workspaceSnap.nodes", ms_workspaceSnap.nodes)

    uniqueBPList.forEach((bpSnap) => {
      // Create new Snapshot for each unique Blueprint
      Api.blueprints.post.snapshot({blueprintId: bpSnap.id, name: bpSnap.snapshot})
      // Add snapshotName for each Node in Project
      ms_workspaceSnap.nodes.filter((node) => node.data.blueprintId === bpSnap.id).forEach((node) => {
        node['blueprint'] = [bpSnap.id, bpSnap.snapshot];
      });
      // Add snapshotName for each projectNode in Project
      ms_workspaceSnap.nodes.filter((projectNode) => projectNode.data.blueprintId === null).forEach((projectNode) => {
        Object.values(projectNode.data.snapshot.nodes).forEach((node) => {
          if (node.data.blueprintId === bpSnap.id) {
            node['blueprint'] = [bpSnap.id, bpSnap.snapshot];
          }
        });
      });
      console.log("EditMS, pred-finally schema0:", {ms_workspaceSnap})
    });

    let run_tasks_loc = {run_task_id: -1, run_task_flg: 0}

    console.log("EditMS, pred-finally schema:", {...ms_workspaceSnap})
    // Patch Project with Snapshoted Nodes
    dispatch(patchProject({projectId, schema: {...ms_workspaceSnap}, run_tasks: run_tasks_loc, projectSnapshot: 'dev'}));
    // Create new Project's Snapshot for Microservice
    await Api.microservices.post.msSnap({projectId: projectId, snapName: new_ms_snap_name})
    // Create Microservice
    await Api.microservices.post.create({projectId: projectId,
      projectSnapshot:new_ms_snap_name,
      formData: {...workspace.ws, snap_name: "ms_"+(Math.max.apply(null, snapshot_names) + 1).toString()},
      meta: meta})

    console.log("EditMS, finally schema:", ms_workspace)

    // Update Project's 'dev' Snapshot for 'dev' Nodes
    dispatch(patchProject({projectId, schema: {...ms_workspace}, run_tasks: run_tasks_loc, projectSnapshot: 'dev'}));

    onClose();
  };

  const handleEditName = async (e) => {
    console.log('EditMS, handleEditName:', e.target.value)
    let tmp_meta = {...meta};
    tmp_meta.name = e.target.value;
    setMeta(tmp_meta)
  };

  const handleEditDescription = async (e) => {
    console.log('EditMS, handleEditDescription:', e.target.value)
    let tmp_meta = {...meta};
    tmp_meta.description = e.target.value;
    setMeta(tmp_meta)
  };

  return (
    <Dialog
      open={isOpen}
      modal={true}
      classes={{ paper: classes.dialogPaperRoot }}
      onClose={onClose}
    >
      <Typography variant={"h2"}>Create Microservice</Typography>
      <Box mb={3} width={"100%"}>
        <TextField multiline rows={1} label={"Microservice Name"} fullWidth
                   onChange={(e) => handleEditName(e)}
        />
      </Box>
      <Box mb={3} width={"100%"}>
        <TextField multiline rows={2} label={"Description"} fullWidth
                   onChange={(e) => handleEditDescription(e)}
        />
      </Box>
      <Box
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        flexDirection={"column"}
        // height={"500px"}
      >
        <Box width={"100%"} height={"50%"}>
          <Typography variant={"h6"}>{"Inputs"}</Typography>
          <Table
              tableHeadCells={inputsTableHead}
              size={"small"}
          >
            <TableHead>
              <TableRow>
                {inputsTableHead.map((cell) => (
                    <TableCell key={cell.id} className={classes.tableCellHead}>
                      {cell.title}
                    </TableCell>
                ))}
              </TableRow>
            </TableHead>
            {Boolean((inputs.values).length) ? (
                <>
                  {inputs.values.map((inp) => (
                      (inp.value[0] === 'inline')
                          ?<TableRow>
                        <TableCell>
                            <TextField
                              variant={"filled"}
                              multiline
                              maxRows={2}
                              key={inp.id}
                              fullWidth={true}
                              label={inp.id.length > 25 ? inp.id.toString().slice(0, 25)+"..." : inp.id}
                              defaultValue={inp.value[1]}
                              onChange={(e) => handleEditPortValue(e, inp.id)}
                          />
                        </TableCell>
                            <TableCell>
                              <Checkbox
                                  defaultChecked={true}
                                  key={"check"+inp.id}
                                  onClick={(e) => handleCheckInp(e, inp.id)}
                              />
                            </TableCell>
                          </TableRow>:
                          <TableRow>
                            <TableCell>
                          <form>

                            <TextField
                                fullWidth
                                variant={"filled"}
                                type="file"
                                multiline
                                maxRows={2}
                                disabled={true}
                                key={inp.id}
                                label={inp.id.length > 25 ? inp.id.toString().slice(0, 25)+"..." : inp.id}
                                defaultValue={inp.value[1][0]}
                                onClick={(e) => handleClickEditPortValueFile(e, inp.id)}
                                InputProps={{endAdornment: <FileButton />}}
                            />
                            <input
                                id="file-upload"
                                type="file"
                                hidden
                                onChange={(e) => handleEditPortValueFile(e, inp.id)}
                            />
                          </form>
                            </TableCell>
                            <TableCell>
                              <Checkbox
                                  defaultChecked={true}
                                  key={"check"+inp.id}
                                  onClick={(e) => handleCheckInp(e, inp.id)}
                              />
                            </TableCell>
                          </TableRow>
                  ))}
                </>
            ):(
                "No inputs"
            )}
            {/*<CodeEditor title={"Load"} editorHeight={"200px"} />*/}
          </Table>
        </Box>

        {/*OUTPUTS!*/}
        <Box width={"100%"} height={"50%"}>
          <Typography variant={"h6"}>{"Outputs"}</Typography>
          <Table
              tableHeadCells={outputsTableHead}
              size={"small"}
          >
            <TableHead>
              <TableRow>
                {outputsTableHead.map((cell) => (
                    <TableCell key={cell.id} className={classes.tableCellHead}>
                      {cell.title}
                    </TableCell>
                ))}
              </TableRow>
            </TableHead>
            {Boolean((outputs.out_values).length) ? (
                <>
                  {outputs.out_values.map((inp) => (
                      (inp.value[0] === 'inline')
                          ?<TableRow>
                            <TableCell>
                              <TextField
                                  variant={"filled"}
                                  multiline
                                  maxRows={2}
                                  disabled={true}
                                  key={inp.id}
                                  label={inp.id}
                                  // onChange={(e) => handleEditPortValue(e, inp.id)}
                              />
                            </TableCell>
                            <TableCell>
                              <Checkbox defaultChecked={false}
                                        key={"check"+inp.id}
                                        onClick={(e) => handleCheckOut(e, inp.id)}
                              />
                            </TableCell>
                          </TableRow>:
                          <TableRow>
                            <TableCell>
                              <form>

                                <TextField
                                    fullWidth
                                    variant={"filled"}
                                    type="file"
                                    multiline
                                    maxRows={2}
                                    disabled={true}
                                    key={inp.id}
                                    label={inp.id}
                                    // onClick={(e) => handleClickEditPortValueFile(e, inp.id)}
                                    InputProps={{endAdornment: <FileButton />}}
                                />
                                <input
                                    id="file-upload"
                                    type="file"
                                    hidden
                                    // onChange={(e) => handleEditPortValueFile(e, inp.name_id)}
                                />
                              </form>
                            </TableCell>
                            <TableCell>
                              <Checkbox defaultChecked={false}
                                        key={"check"+inp.id}
                                        onClick={(e) => handleCheckOut(e, inp.id)}
                              />
                            </TableCell>
                          </TableRow>
                  ))}
                </>
            ):(
                "No Outputs"
            )}
            {/*<CodeEditor title={"Load"} editorHeight={"200px"} />*/}
          </Table>
        </Box>
        <Box mt={2}>
          <Button
              onClick={handleSubmit}
          >Submit</Button>
        </Box>
      </Box>
    </Dialog>
  );
};

import axios from "axios";
import Config from "../config";

const postNewNode = ({ projectId, id, blueprintId, geometry, inputs, outputs, data, language, versions, currentVersion }) => {
  console.log('postNewNode, all params: ',data);
  console.log('postNewNode, language:',
      language);

  // data.blueprint_id = blueprintId;
  geometry.data = data;
  return axios({
    method: "post",
    baseURL: Config.baseUrl,
    url: `/projects/${projectId}/nodes`,
    data: {
      id,
      versions,
      currentVersion,
      blueprint_id: blueprintId,
      blueprint: [blueprintId, 'dev'],
      inputs,
      outputs,
      //data,
      geometry,
      language,// FIXME: [coordX, coordY] or {x: coord, y: coord}
    },
  });
};

const patchNodeById = ({ projectId, nodeId, data, geometry }) => {
  return axios({
    method: "patch",
    baseURL: Config.baseUrl,
    url: `/projects/${projectId}/nodes/${nodeId}`,
    data: {
      data,
      geometry, // FIXME: [coordX, coordY] or {x: coord, y: coord}
    },
  });
};

const deleteNode = ({ projectId, nodeId }) => {
  console.log('nodes API, deleteNode: ', nodeId);
  return axios({
    method: "delete",
    baseURL: Config.baseUrl,
    url: `/projects/${projectId}/nodes/${nodeId}`,
  });
};

export const Nodes = {
  post: {
    node: postNewNode,
  },
  patch: {
    byId: patchNodeById,
  },
  delete: {
    byId: deleteNode,
  },
};

import React, { useEffect } from "react";
import { Box, Typography } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import {getDeletedMicroservices, getMicroservicesList} from "../store/thunks/projects";
import { ProjectCard } from "../common/ProjectCard/ProjectCard";
import { useHistory } from "react-router-dom";
import {Projects} from "../components/projects/Projects";

export const Microservices = () => {
  //selectores
  const { microservices, microservices_del } = useSelector((state) => state.entities.projects);

  //utils
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(getMicroservicesList());
    dispatch(getDeletedMicroservices());
  }, []);

  console.log("Microservices:", microservices)

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      mx={"auto"}
      width={"80%"}
      alignItems={"left"}
    >
      <Typography variant={"h2"} align={"center"}>
        Available Microservices
      </Typography>

      {microservices &&
          microservices.map((mService) => (
              <ProjectCard
                  key={mService.id}
                  title={mService.name|| ''}
                  onClick={() => history.push(`/microservices/${mService.id}`)}
                  description={mService.description || ''}
                  f_owner={mService.f_owner}
                  checked_def={mService.f_fav}
                  entity={'microservice'}
                  id={mService.id}
              />
          ))}
      <details>
        <summary>Deleted Microservices</summary>
        {microservices_del &&
            microservices_del.map((mService) => (

                <ProjectCard
                    key={mService.id}
                    title={mService.project_name}
                    onClick={() => history.push(`/microservices/${mService.id}`)}
                    description={mService.description}
                    f_owner={mService.f_owner}
                    entity={'microservice'}
                    id={mService.id}
                    checked_def={true}
                />
            ))}
      </details>
    </Box>
  );
};

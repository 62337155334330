import React, {useState, setState, useEffect} from "react";
import {
  Avatar,
  Box,
  Button,
  ButtonGroup,
  Chip,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  makeStyles,
  Menu,
  MenuItem, Table, TableRow,
  TextField,
  Typography,
} from "@material-ui/core";
import { ProjectCard } from "../common/ProjectCard/ProjectCard";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import PersonIcon from "@material-ui/icons/Person";
import { ArrowDropDown } from "@material-ui/icons";
import { Api } from "../api";
import projects from "../store/entities/projects";
import {adapters} from "../store/entities/adapters";
import {blueprints} from "../store/entities/blueprints";
import {useHistory, useParams} from "react-router-dom";
import { parseBlueprints, parseAdapters } from "../helpers/blueprints";

const useStyles = makeStyles((theme) => ({
  rootWrapper: {
    position: "relative",
    display: "flex",
    justifyContent: "center",
    width: "100%",
    margin: "0 auto",
  },
  searchInput: {
    marginBottom: theme.spacing(2),
  },
  projectsWrapper: {},
  projectsList: {
    // display: "flex",
    flexDirection: "column",
    width: "100%",
    alignItems: "center",
  },
  projectsHeader: {
    padding: theme.spacing(2, 0),
  },
  popularWrapper: {},
  chipItem: {
    margin: theme.spacing(0.1),
  },
}));

const popularTags = [
  { id: 1, label: "java" },
  { id: 2, label: "hold" },
  { id: 3, label: "case" },
  { id: 4, label: "deep" },
  { id: 5, label: "list" },
  { id: 6, label: "army" },
  { id: 7, label: "bird" },
  { id: 8, label: "blender" },
  { id: 9, label: "C++" },
];

export const SearchRequest = () => {
  const classes = useStyles();
  let { activeTab } = useParams();
  console.log('SearchRequest, init params:', { activeTab })
  let { request } = useParams();

  console.log('SearchRequest, init params:', { activeTab, request })
  const [searchRequest, setSearchRequest] = useState(' ');
  const [buttonsGroupAnchor, setButtonGroupAnchor] = useState(null);
  const [selectedTags, setSelectedTags] = useState([]);
  const [showOwners, setShowOwners] = useState(false);
  const [selectedEntities, setSelectedEntities] = useState({
    projects:1, blueprints: 0, adapters: 0, microservices: 0});
  const history = useHistory();

  const [query, setQuery] = useState({
    projects: [], blueprints: [], adapters: [], microservices: []});

  const [tab, setTab] = useState('projects');

  useEffect(async () => {
    if (Boolean(request))
    {
      console.log('SearchRequest, request2: ', 1);
      setSearchRequest(request);
    }else
    {
      console.log('SearchRequest, request2: ', 2);
      setSearchRequest(' ');
      request = ' '
    }
    setTab(activeTab);
    Object.keys(selectedEntities).map(ent => {
      if(ent === activeTab){
        selectedEntities[ent] = 1;
      } else {
        selectedEntities[ent] = 0;
      }
    })
    setSelectedEntities(selectedEntities);
    console.log('SearchRequest, request1: ', searchRequest);
    query.projects = (await Api.projects.get.list({filters: "", mode: "search", request: request})).data;
    query.adapters = parseAdapters((await Api.adapters.get.list({mode: "search", request: request})) || [], "");
    query.blueprints = parseBlueprints((await Api.blueprints.get.list({mode: "search", request: request})) || [], "dev");
    query.microservices = (await Api.microservices.get.list({mode: "search", request: request})).data || [];
    console.log('SearchRequest, query: ', query);
    setQuery({...query});
    // let selectedEntities_local = {...selectedEntities}
    setSelectedEntities({projects:1, blueprints: 0, adapters: 0, microservices: 0});
    setSelectedEntities({...selectedEntities});
  }, [])

  const handleTagClick = (tagId) => {
    const isTagSelected = selectedTags.includes(tagId);
    if (isTagSelected) {
      return setSelectedTags((prev) => prev.filter((tag) => tag !== tagId));
    }
    return setSelectedTags((prev) => [...prev, tagId]);
  };

  const checkSelectedTag = (tagId) => selectedTags.includes(tagId);

  const handleOpenMenu = (e) => {
    setButtonGroupAnchor(e.currentTarget);
  };
  const handleCloseMenu = () => {
    setButtonGroupAnchor(null);
  };

  const handleEditSearchRequest = (e) => {
    console.log('SearchRequest, handleEditSearchRequest, e', e.target.value)
    if (Boolean(e.target.value))
    {
      console.log('SearchRequest, request2: ', 1);
      setSearchRequest(e.target.value);
    }else
    {
      console.log('SearchRequest, request2: ', 2);
      setSearchRequest(' ');
      request = ' '
    }
  };

  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  console.log("rendering...");

  const handleClickSearch = async () => {
    console.log('SearchRequest, handleClickSearch')
    console.log('SearchRequest, searchRequest: ', (searchRequest));
    query.projects = (await Api.projects.get.list({filters: "", mode: "search", request: searchRequest})).data;
    query.adapters = parseAdapters((await Api.adapters.get.list({mode: "search", request: searchRequest})) || [], "");
    query.blueprints = parseBlueprints((await Api.blueprints.get.list({mode: "search", request: searchRequest})) || [], "dev");
    query.microservices = (await Api.microservices.get.list({mode: "search", request: searchRequest})).data || [];
    console.log('SearchRequest, query: ', query);
    setQuery({...query});
    // let selectedEntities_local = {...selectedEntities}
    Object.keys(selectedEntities).map(ent => {
      if(ent === activeTab){
        selectedEntities[ent] = 1;
      } else {
        selectedEntities[ent] = 0;
      }
    })
    // setSelectedEntities({...selectedEntities});
    // setSearchRequest(request);
    console.log('SearchRequest, params: ', {tab, searchRequest});
    history.push(`/search/tab=${tab}&req=${searchRequest}`);
    // forceUpdate();
  };

  const handleClickChangeEntity = async (e) => {
    console.log('Search, handleClickChangeEntity, e', e)

    Object.keys(selectedEntities).map(ent => {
      if(ent === e){
        selectedEntities[ent] = 1;
      } else {
        selectedEntities[ent] = 0;
      }
    })

    setTab(e)
    history.push(`/search/tab=${e}&req=${request}`);
    //
    // if(e) {
    //   if (selectedEntities[e] === 1) {
    //     selectedEntities[e] = 0;
    //   } else {
    //     selectedEntities[e] = 1;
    //   }
    // }
    //
    // if (selectedEntities.projects + selectedEntities.blueprints +
    //     selectedEntities.adapters + selectedEntities.microservices === 0){
    //   selectedEntities.projects = 1;
    // }

    console.log('Search, handleClickChangeEntity, selectedEntities', selectedEntities)
    setSelectedEntities({...selectedEntities});
  };

  const checkSelectedEntities = (tagId) =>Boolean(selectedEntities[tagId]);

  const handleOpenProject = async ({ id }) => {
    history.push(`/tablespace/${id}`);
  };

  const handleOpenBlueprint = async ({ blueprint_id, type, f_owner }) => {
    if (f_owner) {
      history.push(`/nodes/create_node?edit=true&node_id=${blueprint_id}&type_id=${type}`);
    } else {
      history.push(`/nodes/create_node?edit=false&node_id=${blueprint_id}&type_id=${type}`);
    }
  };

  const handleEditShowOwner = async (e) => {
    // console.log('EditMS, handleEditName:', e.target.value)
    setShowOwners(e)
  };

  const handleSetFav = async (id) => {
    let localQuery = {...query};
    localQuery.microservices.filter(ms => ms.id === id)[0].f_fav = !(localQuery.microservices.filter(ms => ms.id === id)[0].f_fav);

    setQuery({...localQuery})
    setSelectedEntities({projects:1, blueprints: 0, adapters: 0, microservices: 0});
    setSelectedEntities({...selectedEntities});
  };

  return (
    <Grid container spacing={3} className={classes.rootWrapper}>
      <Grid item xs={12} sm={8}>
        <TextField
          label={"Enter search request"}
          variant={"filled"}
          fullWidth
          defaultValue={request}
          className={classes.searchInput}
          onChange={(e) => handleEditSearchRequest(e)}
          InputProps={{
            endAdornment: <Button
                            onClick={handleClickSearch}
                          >Search</Button>,
          }}
        />
        <Box>
        <ButtonGroup color={"primary"} size={"small"}>
          <Button variant={checkSelectedEntities("projects") ? "contained" : "outlined"}
                   onClick={() => handleClickChangeEntity("projects")}> Projects
          </Button>
          <Button variant={checkSelectedEntities("blueprints") ? "contained" : "outlined"}
                  onClick={() => handleClickChangeEntity("blueprints")}> blueprints
          </Button>
          <Button variant={checkSelectedEntities("adapters") ? "contained" : "outlined"}
                  onClick={() => handleClickChangeEntity("adapters")}> adapters
          </Button>
          <Button variant={checkSelectedEntities("microservices") ? "contained" : "outlined"}
                  onClick={() => handleClickChangeEntity("microservices")}> microservices
          </Button>
          {/*<Button color={"secondary"}>Hot</Button>*/}
          {/*<Button onClick={handleOpenMenu}>*/}
          {/*  <typography>More</typography>*/}
          {/*  <ArrowDropDown />*/}
          {/*</Button>*/}
          {/*<Menu*/}
          {/*  open={buttonsGroupAnchor}*/}
          {/*  anchorEl={buttonsGroupAnchor}*/}
          {/*  onClose={handleCloseMenu}*/}
          {/*>*/}
          {/*  <MenuItem onClick={handleCloseMenu}>*/}
          {/*    Some another search filter #1*/}
          {/*  </MenuItem>{" "}*/}
          {/*  <MenuItem onClick={handleCloseMenu}>*/}
          {/*    Some another search filter #2*/}
          {/*  </MenuItem>*/}
          {/*</Menu>*/}
        </ButtonGroup>
        </Box>
        <Box>
          <br/>
          <label>
            <input
                type="checkbox"
                defaultChecked={false}
                // ref="complete"
                onChange={(e) => handleEditShowOwner(e.target.checked)}
            />
            Show My Projects
          </label>

        </Box>
        {/*<Box alignItems={"center"}>*/}
        {/*  <br/>*/}
        {/*  <Typography variant={"body1"}>Popular tags: </Typography>*/}
        {/*  {popularTags.map((tag) => (*/}
        {/*    <Chip*/}
        {/*      size={"small"}*/}
        {/*      label={tag.label}*/}
        {/*      key={tag.id}*/}
        {/*      color={"primary"}*/}
        {/*      onClick={() => handleTagClick(tag.id)}*/}
        {/*      className={classes.chipItem}*/}
        {/*      variant={checkSelectedTag(tag.id) ? "default" : "outlined"}*/}
        {/*    />*/}
        {/*  ))}*/}
        {/*  <IconButton>*/}
        {/*    <MoreHorizIcon />*/}
        {/*  </IconButton>*/}
        {/*</Box>*/}
        <Typography
          variant={"h3"}
          className={classes.projectsHeader}
          align={"center"}
        >
          {/*Search result*/}
        </Typography>
        {/*<Box className={classes.projectsList}>*/}
        {/*  <ProjectCard*/}
        {/*    title={"test"}*/}
        {/*    description={"test description"}*/}
        {/*    tags={"hello, world, test".split(",")}*/}
        {/*  />*/}
        {/*</Box>*/}


        {/*Projects*/}
        {Boolean(query.projects.length) & Boolean(selectedEntities.projects) ?
            ((showOwners) ? (query.projects.map((project) => (
            <Table className={classes.projectsList}>
              <TableRow hover role="checkbox" tabIndex={-1} >
                <ProjectCard
                    title={project.name}
                    tags={Array.isArray(project.tags)?project.tags.join().split(',')
                        :project.tags.split(',')}
                    key={project.id}
                    description={project.description}
                    onClick={() => handleOpenProject(project)}
                    f_owner={project.f_owner}
                    entity={'project'}
                    id={project.id}
                    checked_def={false}
                    language={project.language}
                    search={true}
                    owner={{username: project.username, owner_id: project.owner_id}}
                />

              </TableRow>
            </Table>)
        )):((query.projects.filter(project => project.f_owner !== true)).map((project) => (
                <Table>
                  <TableRow hover role="checkbox" tabIndex={-1} >
                    <ProjectCard
                        title={project.name}
                        tags={Array.isArray(project.tags)?project.tags.join().split(',')
                            :project.tags.split(',')}
                        key={project.id}
                        description={project.description}
                        onClick={() => handleOpenProject(project)}
                        f_owner={project.f_owner}
                        entity={'project'}
                        id={project.id}
                        checked_def={false}
                        language={project.language}
                        search={true}
                        owner={{username: project.username, owner_id: project.owner_id}}
                    />

                  </TableRow>
                </Table>)
            ))): (<></>)}

        {/*Blueprints*/}
        {Boolean(query.blueprints.length) & Boolean(selectedEntities.blueprints) ?
            ((showOwners) ? (query.blueprints.map((project) => (
                <Table>
                  <TableRow hover role="checkbox" tabIndex={-1} >
                    <ProjectCard
                        title={project.typeId}
                        tags={[]}
                        key={project.name}
                        description={project.description}
                        onClick={() => handleOpenBlueprint(project)}
                        f_owner={project.f_owner}
                        entity={project.type}
                        id={project.blueprint_id}
                        checked_def={false}
                        language={project.language}
                        search={true}
                        owner={{username: project.username, owner_id: project.owner_id}}
                    />

                  </TableRow>
                </Table>)
            )):((query.blueprints.filter(blueprint => blueprint.f_owner !== true)).map((project) => (
                <Table>
                  <TableRow hover role="checkbox" tabIndex={-1} >
                    <ProjectCard
                        title={project.typeId}
                        tags={[]}
                        key={project.name}
                        description={project.description}
                        onClick={() => handleOpenBlueprint(project)}
                        f_owner={project.f_owner}
                        entity={project.type}
                        id={project.blueprint_id}
                        checked_def={false}
                        language={project.language}
                        search={true}
                        owner={{username: project.username, owner_id: project.owner_id}}
                    />

                  </TableRow>
                </Table>)
            ))): (<></>)}

        {/*Adapters*/}
        <Box>
        {Boolean(query.adapters.length) & Boolean(selectedEntities.adapters) ?
            ((showOwners) ? (query.adapters.map((project) => (
                <Table>
                  <TableRow hover role="checkbox" tabIndex={-1} >
                    <ProjectCard
                        title={project.typeId}
                        tags={[]}
                        key={project.name}
                        description={project.description}
                        onClick={() => handleOpenBlueprint(project)}
                        f_owner={project.f_owner}
                        entity={project.type}
                        id={project.blueprint_id}
                        checked_def={false}
                        language={project.language}
                        search={true}
                        owner={{username: project.username, owner_id: project.owner_id}}
                    />

                  </TableRow>
                </Table>)
            )):((query.adapters.filter(adapter => adapter.f_owner !== true)).map((project) => (
                <Table>
                  <TableRow hover role="checkbox" tabIndex={-1} >
                    <ProjectCard
                        title={project.typeId}
                        tags={[]}
                        key={project.name}
                        description={project.description}
                        onClick={() => handleOpenBlueprint(project)}
                        f_owner={project.f_owner}
                        entity={project.type}
                        id={project.blueprint_id}
                        checked_def={false}
                        language={project.language}
                        search={true}
                        owner={{username: project.username, owner_id: project.owner_id}}
                    />

                  </TableRow>
                </Table>)
            ))): (<></>)}
        </Box>

        {/*Microservices*/}
        <Box>
        {Boolean(query.microservices.length) & Boolean(selectedEntities.microservices) ?
            ((showOwners) ? (query.microservices.map((mService) => (
                <Table>
                  <TableRow hover role="checkbox" tabIndex={-1} >
                    <ProjectCard
                        key={mService.id}
                        title={mService._data.meta.name}
                        onClick={() => history.push(`/microservices/${mService.id}`)}
                        description={mService._data.meta.description}
                        f_owner={mService.f_owner}
                        entity={'microservice'}
                        id={mService.id}
                        search={true}
                        checked_def={mService.f_fav}
                        cust_func={handleSetFav}
                        owner={{username: mService.username, owner_id: mService.owner_id}}
                    />

                  </TableRow>
                </Table>)
            )):((query.microservices.filter(microservice => microservice.f_owner !== true)).map((mService) => (
                <Table>
                  <TableRow hover role="checkbox" tabIndex={-1} >
                    <ProjectCard
                        key={mService.id}
                        title={mService._data.meta.name}
                        onClick={() => history.push(`/microservices/${mService.id}`)}
                        description={mService._data.meta.description}
                        f_owner={mService.f_owner}
                        entity={'microservice'}
                        id={mService.id}
                        search={true}
                        checked_def={mService.f_fav}
                        cust_func={handleSetFav}
                        owner={{username: mService.username, owner_id: mService.owner_id}}
                    />

                  </TableRow>
                </Table>)
            ))): (<></>)}
        </Box>
      </Grid>
      {/*<Grid item xs={12} sm={3} className={classes.popularWrapper}>*/}
      {/*  <Typography variant="h3">Popular projects: </Typography>*/}
      {/*  <List>*/}
      {/*    <ListItem button>*/}
      {/*      <ListItemAvatar>*/}
      {/*        <Avatar*/}
      {/*          src={"https://i.insider.com/60a92998e25d05001880d017?width=700"}*/}
      {/*        />*/}
      {/*      </ListItemAvatar>*/}
      {/*      <ListItemText>*/}
      {/*        <Typography variant="h4">Some popular project</Typography>*/}
      {/*        <Typography variant="caption" color="textSecondary">*/}
      {/*          Points: 157 352*/}
      {/*        </Typography>*/}
      {/*      </ListItemText>*/}
      {/*    </ListItem>*/}
      {/*    <ListItem button>*/}
      {/*      <ListItemAvatar>*/}
      {/*        <Avatar>*/}
      {/*          <PersonIcon />*/}
      {/*        </Avatar>*/}
      {/*      </ListItemAvatar>*/}
      {/*      <ListItemText>*/}
      {/*        <Typography variant="h4">Some popular project</Typography>*/}
      {/*        <Typography variant="caption" color="textSecondary">*/}
      {/*          Points: 1 325*/}
      {/*        </Typography>*/}
      {/*      </ListItemText>*/}
      {/*    </ListItem>*/}
      {/*  </List>*/}
      {/*  <Typography variant="h3">Recent views: </Typography>*/}

      {/*  <List>*/}
      {/*    <ListItem button>*/}
      {/*      <ListItemAvatar>*/}
      {/*        <Avatar*/}
      {/*          src={"https://i.insider.com/60a92998e25d05001880d017?width=700"}*/}
      {/*        />*/}
      {/*      </ListItemAvatar>*/}
      {/*      <ListItemText>*/}
      {/*        <Typography variant="h4">Recent views</Typography>*/}
      {/*        <Typography variant="caption" color="textSecondary">*/}
      {/*          Points: 157 352*/}
      {/*        </Typography>*/}
      {/*      </ListItemText>*/}
      {/*    </ListItem>*/}
      {/*    <ListItem button>*/}
      {/*      <ListItemAvatar>*/}
      {/*        <Avatar>*/}
      {/*          <PersonIcon />*/}
      {/*        </Avatar>*/}
      {/*      </ListItemAvatar>*/}
      {/*      <ListItemText>*/}
      {/*        <Typography variant="h4">Some popular project</Typography>*/}
      {/*        <Typography variant="caption" color="textSecondary">*/}
      {/*          Points: 1 325*/}
      {/*        </Typography>*/}
      {/*      </ListItemText>*/}
      {/*    </ListItem>*/}
      {/*  </List>*/}
      {/*</Grid>*/}
    </Grid>
  );
};

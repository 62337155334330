import React from "react";
import { fade, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: fade(theme.palette.primary.light, 0.25),
    border: `1px solid ${theme.palette.primary.main}`,
  },
}));
export const ZoneSelect = ({
  isShow,
  width,
  height,
  startX,
  startY,
  onFinish,
  onMouseMove,
}) => {
  const classes = useStyles();
  const zoneStyle = {
    display: isShow ? "block" : "none",
    position: "absolute",
    top: startY,
    left: startX,
    width: width,
    height: height,
    zIndex: 80,
  };
  return (
    <div
      style={zoneStyle}
      className={classes.root}
      onMouseUp={onFinish}
      onMouseMove={onMouseMove}
    />
  );
};

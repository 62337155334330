import React, { useEffect, useState } from "react";
import { Box, Button, makeStyles, TextField } from "@material-ui/core";
import { useDispatch } from "react-redux";

import { setAuthScreenSignIn } from "../../store/ui/auth";
import { resetPassword } from "../../api/auth";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    flexDirection: "column",
  },
}));
const formFields = {
  password: {
    label: "New password",
    type: "password",
    variant: "filled",
  },
  passwordConfirm: {
    label: "Confirm password",
    type: "password",
    variant: "filled",
  },
};
export const ResetPassword = ({ resetData }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    resetPassword(formData);
    // dispatch(userLogIn());
  };

  const handleClickBack = () => {
    history.push("/");
    dispatch(setAuthScreenSignIn());
  };

  useEffect(() => {
    setFormData((prev) => ({ ...prev, ...resetData }));
  }, [resetData]);

  return (
    <form onSubmit={handleSubmit} className={classes.form} noValidate>
      {Object.entries(formFields).map(([name, data]) => {
        return (
          <Box marginY={1} key={name}>
            <TextField
              onChange={handleChange}
              value={formData[name] || ""}
              variant={data.variant}
              label={data.label}
              name={name}
              id={name}
              error={Boolean(formErrors[name])}
              helperText={formErrors[name]}
              type={"password"}
            />
          </Box>
        );
      })}

      <Button type={"submit"}>Submit</Button>
      <Button variant={"text"} onClick={handleClickBack}>
        Back
      </Button>
    </form>
  );
};

import axios from "axios";
import Config from "../config";

const getAdapters = ({ mode, request = "" }) => {
  return axios({
    baseURL: Config.baseUrl,
    url: `/adapters?snapshots=contents&mode=${mode}&request=${request}`,
  });
};

const getDeletedList = () => {
  return axios({
    method: "get",
    baseURL: Config.baseUrl,
    url: `/adapters/deleted?snapshots=contents`,
  });
};

const getAdapterById = ({ adapterId }) => {
  return axios({
    method: "get",
    baseURL: Config.baseUrl,
    url: `/adapters/${adapterId}?snapshots=contents`,
  });
};

const getAdapterSnapshot = ({ adapterId, version }) => {
  return axios({
    method: "get",
    baseURL: Config.baseUrl,
    url: `/adapters/${adapterId}/snapshots/${version}`,
  });
};

const patchAdapterData = (formData) => {
  const { blueprint_id, is_public, inputs, outputs, src, description, language, type, f_binary, extension, f_public } = formData;
  const local_inputs = inputs || [];
  const local_outputs = outputs || [];
  // const local_inputs = inputs || [];
  // const local_outputs = outputs || [];
  console.log('patchAdapterData, adapter_id', blueprint_id)
  console.log('patchAdapterData, formData', formData)
  return axios({
    method: "patch",
    baseURL: Config.baseUrl,
    url: `/adapters/${blueprint_id}/snapshots/dev`,
    data: {
      type,
      is_public,
      f_binary: f_binary,
      inputs: local_inputs,
      outputs: local_outputs,
      language,
      source: src,
      notes: description,
      extension,
      f_public,
    },
  });
};

const patchMainAdapterData = (formData) => {
  const { blueprint_id, typeId, f_public, description } = formData;

  return axios({
    method: "patch",
    baseURL: Config.baseUrl,
    url: `/adapters/${blueprint_id}`,
    data: {
      f_public: f_public,
      name: typeId,
      description: description,
    },
  });
};

const patchRemove = (formData) => {
  const { blueprint_id, check } = formData;

  console.log('Projects patchRemove', blueprint_id, check)

  return axios({
    method: "delete",
    baseURL: Config.baseUrl,
    url: `/adapters/${blueprint_id}`,
    data: {
      f_deleted: true,
    },
  });
};

const patchRestore = (formData) => {
  const { blueprint_id, check } = formData;

  console.log('Projects patchRemove', blueprint_id, check)

  return axios({
    method: "patch",
    baseURL: Config.baseUrl,
    url: `/adapters/${blueprint_id}/restore`,
    data: {
      f_deleted: false,
    },
  });
};

const postNewAdapter = (formData) => {
  console.log('postNewAdapter:', formData)
  const {
    typeId: name,
    description: notes,
    currentVersion: version,
    is_public,
  } = formData;
  return axios({
    method: "post",
    baseURL: Config.baseUrl,
    url: `/adapters`,
    data: {
      name,
      notes,
      version,
      is_public,
    },
  });
};

const postNewAdapterSnapshot = ({blueprintId, name}) => {
  return axios({
    method: "post",
    baseURL: Config.baseUrl,
    url: `/adapters/${blueprintId}/snapshots`,
    data: {
      name: name
    },
  });
};

const postFork = ({ blueprint_id }) => {
  return axios({
    method: "post",
    baseURL: Config.baseUrl,
    url: `/adapters/${blueprint_id}/snapshots/dev/fork`,
  });
};

export const Adapters = {
  get: {
    list: getAdapters,
    delList: getDeletedList,
    byId: getAdapterById,
    snapshot: getAdapterSnapshot,
  },
  patch: {
    dev: patchAdapterData,
    main: patchMainAdapterData,
    removeFlg: patchRemove,
    restoreFlg: patchRestore,
  },
  post: {
    create: postNewAdapter,
    snapshot: postNewAdapterSnapshot,
    fork: postFork,
  },
};

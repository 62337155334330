import React, { useState } from "react";
import { Box, Button, makeStyles, TextField } from "@material-ui/core";
import { useDispatch } from "react-redux";

import { authScreenStates, setAuthScreenSignIn } from "../../store/ui/auth";
import { signUpEmailPassword } from "../../api/auth";

const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    flexDirection: "column",
  },
}));
const formFields = {
  email: {
    label: "E-mail",
    type: "email",
    variant: "filled",
  },
  password: {
    label: "Password",
    type: "password",
    variant: "filled",
  },
  passwordConfirm: {
    label: "Confirm password",
    type: "password",
    variant: "filled",
  },
};

const signUpErrors = {
  userExists: "auth/email-already-in-use",
  passwordsNotMatch: "auth/passwords-not-match",
  weakPassword: "auth/weak-password",
};

export const SignUp = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormErrors((prev) => {
      if (name === "password" || name === "passwordConfirm") {
        setFormErrors((prev) => {
          const errors = { ...prev };
          delete errors.password;
          delete errors.passwordConfirm;
          return errors;
        });
      }
    });

    setFormErrors((prev) => {
      const errors = { ...prev };
      delete errors[name];
      return errors;
    });
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const { email, password, passwordConfirm } = formData;
    if (password !== passwordConfirm) {
      return setFormErrors((prev) => ({
        ...prev,
        password: signUpErrors.passwordsNotMatch,
        passwordConfirm: signUpErrors.passwordsNotMatch,
      }));
    }
    const onSuccess = () => {
      dispatch(setAuthScreenSignIn(authScreenStates.signIn));
    };
    const onError = (err) => {
      if (err.code === signUpErrors.userExists) {
        console.log(err.message);
        return dispatch(setAuthScreenSignIn());
      }
      if (err.code === signUpErrors.weakPassword) {
        return setFormErrors((prev) => ({ ...prev, password: err.message }));
      }
    };
    signUpEmailPassword(email, password, { onSuccess, onError });
  };

  const handleClickBack = () => {
    dispatch(setAuthScreenSignIn());
  };

  return (
    <form onSubmit={handleSubmit} className={classes.form} noValidate>
      {Object.entries(formFields).map(([name, data]) => {
        return (
          <Box marginY={1} key={name}>
            <TextField
              onChange={handleChange}
              value={formData[name] || ""}
              variant={data.variant}
              label={data.label}
              name={name}
              id={name}
              error={Boolean(formErrors[name])}
              helperText={formErrors[name]}
              type={data.type}
            />
          </Box>
        );
      })}

      <Button type={"submit"}>Submit</Button>
      <Button variant={"text"} onClick={handleClickBack}>
        Back
      </Button>
    </form>
  );
};

import { createSlice } from "@reduxjs/toolkit";
import { userSignIn } from "../thunks/user";

export const userSlice = createSlice({
  name: "user",
  initialState: {
    isLoggedIn: false,
    userData: null,
  },
  reducers: {
    userSignOut: (state) => {
      state.isLoggedIn = false;
      state.userData = null;
    },
    setUpdatedUserToken: (state, action) => {
      state.userData.accessToken = action.payload;
    },
  },
  extraReducers: {
    [userSignIn.fulfilled]: (state, action) => {
      state.isLoggedIn = true;
      state.userData = action.payload;
    },
  },
});

export const { userSignOut, setUpdatedUserToken } = userSlice.actions;
export default userSlice.reducer;

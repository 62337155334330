import axios from "axios";
import Config from "../config";

const getState = () => {
  return axios({
    method: "get",
    baseURL: Config.baseUrl,
    url: `/state`,
  });
};

const resetState = () => {
  return axios({
    method: "post",
    baseURL: Config.baseUrl,
    url: `/reset`,
  });
};

export const Dev = {
  state: {
    get: getState,
    reset: resetState,
  },
};

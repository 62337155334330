import { createSlice } from "@reduxjs/toolkit";

export const tableSpaceSlice = createSlice({
  name: "tableSpace",
  initialState: {
    nodesList: {
      isOpen: false,
    },
    nodeProperties: {
      isOpen: false,
    },
  },
  reducers: {
    nodeListOpen: (state) => {
      state.nodesList.isOpen = true;
    },
    nodeListClose: (state) => {
      state.nodesList.isOpen = false;
    },
    nodePropertiesOpen: (state) => {
      state.nodeProperties.isOpen = true;
    },
    nodePropertiesClose: (state) => {
      state.nodeProperties.isOpen = false;
    },
  },
});

export const {
  nodeListOpen,
  nodeListClose,
  nodePropertiesOpen,
  nodePropertiesClose,
} = tableSpaceSlice.actions;
export default tableSpaceSlice.reducer;

import { createSlice } from "@reduxjs/toolkit";
import {
  createNewProject,
  // fetchAllVisibleProjects,
  fetchListOfProjects,
  getDeletedMicroservices,
  getMicroserviceInfo,
  getMicroserviceTaskResults,
  getMicroserviceTaskInfo,
  getMicroserviceLaunches,
  getMicroservicesList,
  fetchDeletedProjects,
} from "../thunks/projects";

export const projectsSlice = createSlice({
  name: "projects",
  initialState: {
    onEdit: {
      projectId: null,
      projectName: null,
    },
    user: [],
    projectBlueprints: [],
    common: [],
    deleted: [],
    microservices: null,
    microservices_del: [],
    microserviceInfo: null,
    microserviceTaskInfo: null,
    microserviceTaskResults: null,
    microserviceLaunches: null,
  },
  reducers: {
    setEditProjectMeta: (state, action) => {
      state.onEdit.projectId = action.payload.projectId;
      state.onEdit.projectName = action.payload.name;
    },
  },
  extraReducers: {
    [fetchListOfProjects.fulfilled]: (state, action) => {
      state.user = action.payload;
    },

    // [fetchAllVisibleProjects.fulfilled]: (state, action) => {
    //   state.common = action.payload;
    // },

    [fetchDeletedProjects.fulfilled]: (state, action) => {
      state.deleted = action.payload;
    },

    [createNewProject.fulfilled]: (state, action) => {
      state.user.push(action.payload);
    },
    [getMicroservicesList.fulfilled]: (state, action) => {
      state.microservices = action.payload;
    },
    [getDeletedMicroservices.fulfilled]: (state, action) => {
      state.microservices_del = action.payload;
    },
    [getMicroserviceInfo.fulfilled]: (state, action) => {
      state.microserviceInfo = action.payload;
    },
    [getMicroserviceTaskInfo.fulfilled]: (state, action) => {
      state.microserviceTaskInfo = action.payload;
    },
    [getMicroserviceTaskResults.fulfilled]: (state, action) => {
      state.microserviceTaskResults = action.payload;
    },
    [getMicroserviceLaunches.fulfilled]: (state, action) => {
      state.microserviceLaunches = action.payload;
    },
  },
});

export const { setEditProjectMeta } = projectsSlice.actions;
export default projectsSlice.reducer;

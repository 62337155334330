import React, { useState } from "react";
import { Box, Button, makeStyles, TextField } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { signInEmailPassword } from "../../api/auth";

const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    flexDirection: "column",
  },
}));
const formFields = {
  email: {
    label: "E-mail",
    type: "email",
  },
  password: {
    label: "Password",
    type: "password",
  },
};
export const SignIn = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    signInEmailPassword(formData);
    // dispatch(userLogIn());
  };

  return (
    <form onSubmit={handleSubmit} className={classes.form} noValidate>
      {Object.entries(formFields).map(([name, data]) => {
        return (
          <Box marginY={1} key={name}>
            <TextField
              onChange={handleChange}
              value={formData[name] || ""}
              variant={"filled"}
              label={data.label}
              name={name}
              id={name}
              error={Boolean(formErrors[name])}
              helperText={formErrors[name]}
              type={data.type}
              fullWidth
            />
          </Box>
        );
      })}

      <Button type={"submit"}>Submit</Button>
    </form>
  );
};

import { Box, makeStyles, Tooltip, Typography } from "@material-ui/core";
import React, { useEffect, useMemo, useState } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  portWrapper: {
    display: "flex",
    alignItems: "center",
    position: "absolute",
    opacity: 0,
    width: "50%",
  },

  expanded: {
    position: "relative",
    opacity: 1,
  },
}));

const Port = ({ port, expanded, portStyle, hideTitle, links }) => {
  const [connected, setConnected] = useState(false);
  const [connectionLinks, setConnectionLink] = useState([]);
  const connectedPortStyle = {
    ...portStyle,
    background: "grey",
  };
  const portName = port.props.id.split("|")[1];
  const classes = useStyles();
  console.log("Port, portName:", portName);
  const checkPortsConnection = useMemo(() => {
    if (links) {
      const portType = portName.split("-")[0];
      const portConnected = links.filter(
        (link) => link[portType] === port.props.id
      );
      console.log("Port,  links:",  links);
      if (portConnected.length) {
        let connectedNodePort = portType === "input" ? "output" : "input";
        const connectionLinks = portConnected.map(
          (link) => `${portName} - ${link[connectedNodePort]}`
        );

        return { connected: true, links: connectionLinks };
      }
      return false;
    }
  }, [links]);

  const getConnectedLinks = useMemo(() => {
    if (connectionLinks.length) {
      const links = connectionLinks.map((link) => (
        <div key={link}>
          {link}
          <br />
        </div>
      ));
      if (links.length) {
        return links;
      }
      return portName;
    }
    return portName;
  }, [connectionLinks]);

  useEffect(() => {
    const result = checkPortsConnection;
    if (result) {
      const { links, connected } = result;
      setConnectionLink(links);
      return setConnected(connected);
    }
    setConnected(result);
  }, [links]);

  return (
    <Tooltip title={getConnectedLinks}>
      <Box
        className={clsx(classes.portWrapper, {
          [classes.expanded]: expanded,
        })}
      >
        {React.cloneElement(port, {
          style: connected ? connectedPortStyle : portStyle,
        })}
        {expanded && !hideTitle && (
          <Box marginLeft={1}>
            <Typography variant={"caption"} display={"block"}>
              {portName}
            </Typography>
          </Box>
        )}
      </Box>
    </Tooltip>
  );
};

export default React.memo(Port);
Port.propTypes = {
  port: PropTypes.object.isRequired,
  expanded: PropTypes.bool.isRequired,
  portStyle: PropTypes.object.isRequired,
  hideTitle: PropTypes.bool,
};

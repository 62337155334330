import React, {useEffect, useState} from "react";
import { Box, Button, TextField, Typography } from "@material-ui/core";
import { Projects } from "../components/projects/Projects";
import { useDispatch, useSelector } from "react-redux";
import {createNewProject, fetchDeletedProjects, fetchListOfProjects} from "../store/thunks/projects";
import {fetchBlueprints} from "../store/thunks/tablespace";

export const Dashboard = () => {
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);
  console.log("rendering...");
  useEffect(() => {
    dispatch(fetchListOfProjects());
  }, []);

  let user = useSelector((state) => [
    ...state.entities.projects.user
  ]);

  const deleted = useSelector((state) => [
    ...state.entities.projects.deleted
  ]);
  console.log("Dasboard, user:", user)
  console.log("Dasboard, deleted:", deleted)

  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    name: "",
    tags: "",
    description: "",
    f_public: true,
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(createNewProject(formData));
    // dispatch(fetchListOfProjects());
    // forceUpdate();
  };

  const handleChange = (e) => {
    setFormData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleChangeFPublic = (e) => {
    console.log('handleChangeFPublic:', e.target.checked);
    setFormData((prev) => ({ ...prev, ['f_public']: e.target.checked }));
  };

  return (
    <Box
      display={"flex"}
      width={"100%"}
      height={"100vh"}
      justifyContent={"center"}
    >
      <form noValidate onSubmit={handleSubmit}>
        <Box display={"flex"} flexDirection={"column"} mb={2}>
          {Object.entries(formData).map(([name, value]) => {
            if (name !== 'f_public') {
              return (
                  <Box marginRight={1} key={name}>
                    <TextField
                        variant={"filled"}
                        title={name}
                        value={value}
                        onChange={handleChange}
                        name={name}
                        id={name}
                        label={name}
                    />
                  </Box>
              );
            };
          })}
        </Box>
        <Box>
          <label>
            <input
                type="checkbox"
                defaultChecked={formData.f_public}
                name={"f_public"}
                // ref="complete"
                onChange={handleChangeFPublic}
            />
            Is Public
          </label>
        </Box>
        <Button type={"submit"}>Create project</Button>
      </form>
      <Box display={"flex"} flexDirection={"column"} marginLeft={2}>
        <Typography variant={"h2"} align={"center"}>
          Projects
        </Typography>
        <Projects list={user} />
        {/*<Projects list={common} />*/}
        <details>
          <summary>Deleted</summary>
          <Projects list={deleted} checked_def={true} />
        </details>
      </Box>
    </Box>
  );
};

import { createSlice } from "@reduxjs/toolkit";
import { parseObjectToArray } from "../../helpers/parseObjectToArray";
import { fetchBlueprints } from "../thunks/tablespace";

export const blueprints = createSlice({
  name: "blueprints",
  initialState: {
    global: [],
    local: [],
    deleted: [],
  },
  reducers: {
    setUserBlueprints: (state, action) => {
      state.userBluePrints = parseObjectToArray(action.payload);
    },
  },
  extraReducers: {
    [fetchBlueprints.fulfilled]: (state, action) => {
      const { global, local, deleted } = action.payload;
      state.global = global;
      state.deleted = deleted;
      state.local = local;
    },
  },
});

export const { setUserBlueprints } = blueprints.actions;
export default blueprints.reducer;

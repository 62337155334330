import { combineReducers } from "redux";

import uiReducer from "./ui/ui";
import entitiesReducer from "./entities/entities";
import userReducer from "./user/user";
import runTaskReducer from "./run_task/run_task";

export default combineReducers({
  ui: uiReducer,
  entities: entitiesReducer,
  user: userReducer,
  run_task: runTaskReducer
});

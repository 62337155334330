import {
  Box,
  ClickAwayListener,
  makeStyles,
  useTheme,
} from "@material-ui/core";
import Port from "./Port";
import React, { useEffect, useState } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  portsWrapper: {
    position: "absolute",
    display: "flex",
    flexWrap: "wrap",
    top: "40%",
    height: "auto",
    left: "auto",
    right: "auto",
    width: theme.spacing(2),
  },
  staticPortsWrapper: {
    position: "absolute",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    height: "45%",
    alignItems: "center",
    width: "15px",
    top: "55%",
    transform: "translateY(-50%)",
  },

  staticInputs: {
    left: "-2%",
  },
  staticOutputs: {
    right: "-2.5%",
  },

  inputs: {
    left: 0,
  },

  outputs: {
    right: "-4%",
  },

  expanded: {
    top: 0,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    border: `1px solid ${theme.palette.grey[200]}`,
    justifyContent: "center",
    paddingLeft: theme.spacing(0.25),
    zIndex: 1,
  },
}));

const Ports = ({ ports, alignment, portStyle, links, nodeId }) => {
  console.log("Ports, links:", links)
  const [expanded, setExpanded] = useState(false);
  const [delayedWrapper, setDelayedWrapper] = useState(false);

  const classes = useStyles();
  const theme = useTheme();

  const portsExpand = () => {
    setExpanded(true);
  };

  const portsCollapse = () => {
    setExpanded(false);
  };

  const handleDelayedCollapse = () => {
    setDelayedWrapper(false);
  };

  const handleDelayedExpand = () => {
    setDelayedWrapper(true);
  };

  useEffect(() => {
    let timer = null;
    if (delayedWrapper) {
      timer = setTimeout(() => {
        portsExpand();
      }, 1000);
    }

    return () => {
      portsCollapse();
      clearTimeout(timer);
    };
  }, [delayedWrapper]);

  return (
    <>
      {ports.length < 4 ? (
        <Box
          className={clsx(classes.staticPortsWrapper, {
            [classes.staticInputs]: alignment === "left",
            [classes.staticOutputs]: alignment === "right",
          })}
        >
          {ports.map((port) => (
            <Port
              port={port}
              expanded={true}
              portStyle={portStyle}
              hideTitle
              key={port.props.id}
              links={links}
            />
          ))}
        </Box>
      ) : (
        <>
          <ClickAwayListener
            onClickAway={handleDelayedCollapse}
            disableReactTree
          >
            <Box
              className={clsx(classes.portsWrapper, {
                [classes.inputs]: alignment === "left",
                [classes.outputs]: alignment === "right",
                [classes.expanded]: expanded,
              })}
              onClick={portsExpand}
              onMouseOver={handleDelayedExpand}
              onMouseLeave={handleDelayedCollapse}
            >
              {!expanded && (
                <div
                  className={"bi-diagram-port"}
                  style={{
                    width: "10px",
                    height: "25px",
                    borderRadius:
                      alignment === "left"
                        ? theme.spacing(0, 0.25, 0.25, 0)
                        : theme.spacing(0.25, 0, 0, 0.25),
                    padding: 0,
                    margin: 0,
                    position: "absolute",
                    backgroundColor:
                      alignment === "left"
                        ? theme.palette.success.main
                        : theme.palette.error.light,
                  }}
                />
              )}
              {ports.map((port) => (
                <Port
                  port={port}
                  expanded={expanded}
                  portStyle={portStyle}
                  key={port.props.id}
                  links={links}
                />
              ))}
            </Box>
          </ClickAwayListener>
        </>
      )}
    </>
  );
};

export default React.memo(Ports);
Ports.propTypes = {
  ports: PropTypes.array.isRequired,
  alignment: PropTypes.string.isRequired,
  portStyle: PropTypes.object.isRequired,
};

import React from "react";
import { SvgIcon, IconButton } from "@material-ui/core";

import RestoreFromTrashIcon from "@material-ui/icons/RestoreFromTrash";
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import DeleteIcon from "@material-ui/icons/Delete";
import FavoriteIcon from "@material-ui/icons/Favorite";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import python_logo from './python-logo.svg';
import js_logo from './javascript.svg';
import go_logo from './Go-Logo.svg';
import octave_logo from './GNU1-Octave-logo.svg';
import spl_logo from './SPL-COLOUR.svg'

export const RestoreCheckBox = ({ checked, ...props }) => {
    const fontSize = "small";
    return (
        <IconButton size="small" {...props}>
            {checked ? (
                <RestoreFromTrashIcon fontSize={fontSize} titleAccess = {"Restore Element"} />
            ) : (
                <DeleteIcon fontSize={fontSize} titleAccess = {"Move to Deleted"}/>
            )}
        </IconButton>
    );
};

export const FavoritesCheckBox = ({ checked, ...props }) => {
    const fontSize = "small";
    return (
        <IconButton size="small" {...props}>
            {checked ? (
                <FavoriteIcon fontSize={fontSize} />
            ) : (
                <FavoriteBorderIcon fontSize={fontSize} />
            )}
        </IconButton>
    );
};

export const ForkCheckBox = ({ ...props }) => {
  const fontSize = "small";
  return (
      <IconButton size="small" {...props}>
        <PlaylistAddIcon fontSize={fontSize} titleAccess = {"Fork Element as Local Copy"} />
      </IconButton>
  );
};

export const LogoIcon = ({language, ...props}) => {
  const fontSize = "small";
  console.log('LogoIcon, language:', language)
  return (
      <IconButton disabled={true} size="small" {...props}>
        {
          {
            'python': <img src ={python_logo} height={25} width={25}/>,
            'javascript': <img src ={js_logo} height={25} width={25}/>,
            'go': <img src ={go_logo} height={25} width={25}/>,
              'octave': <img src ={octave_logo} height={25} width={25}/>,
              'spl': <img src ={spl_logo} height={25} width={45}/>,
          }[language]
        }
      </IconButton>
  );
}

export const parseUserData = (userData) => {
  const {
    apiKey,
    displayName,
    email,
    photoURL,
    uid,
    stsTokenManager: { accessToken },
  } = userData;
  return { apiKey, displayName, email, photoURL, uid, accessToken };
};

import { createSlice } from "@reduxjs/toolkit";

export const authScreenStates = {
  signIn: "signIn",
  signUp: "signUp",
  forgotPassword: "forgotPassword",
  resetPassword: "resetPassword",
};

const authSlice = createSlice({
  name: "auth",
  initialState: {
    authScreenState: authScreenStates.signIn,
  },
  reducers: {
    setAuthScreenSignIn: (state) => {
      state.authScreenState = authScreenStates.signIn;
    },
    setAuthScreenSignUp: (state) => {
      state.authScreenState = authScreenStates.signUp;
    },
    setAuthScreenForgotPassword: (state) => {
      state.authScreenState = authScreenStates.forgotPassword;
    },
    setAuthScreenResetPassword: (state) => {
      state.authScreenState = authScreenStates.resetPassword;
    },
  },
});

export const {
  setAuthScreenSignIn,
  setAuthScreenSignUp,
  setAuthScreenForgotPassword,
  setAuthScreenResetPassword,
} = authSlice.actions;

export default authSlice.reducer;

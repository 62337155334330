import React from "react";

import ReactDOM from "react-dom";
import "./index.css";
import "firebaseui/dist/firebaseui.css";
import "beautiful-react-diagrams/styles.css";
import App from "./App";
import store from "./store/configStore";
import { ThemeProvider } from "@material-ui/core/styles";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { CssBaseline } from "@material-ui/core";
import { theme } from "./mui";
import "./api/axiosConf";

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <App />
        </ThemeProvider>
      </Provider>
    </BrowserRouter>
  </React.StrictMode>,

  document.getElementById("root")
);

import {getMicroserviceLaunches} from "../../store/thunks/projects";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
    Table, TableRow, TableCell, TableHead, TableContainer, TablePagination, Paper, TableBody, Button
} from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    root: {
        width: '100%',
    },
    // container: {
    //     maxHeight: 440,
    // },
});

export const LaunchTable = () => {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const {microserviceLaunches} = useSelector((state) => state.entities.projects);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleGoResults = (e) => {
        console.log("Cell Click:", e)

        history.push(`/ms_task?ms_id=${e.ms_id}&task_id=${e.id}`)
    };

    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();
    const classes = useStyles();

    useEffect(() => {
        console.log("MS Launches Dispatch")
        dispatch(getMicroserviceLaunches({}));
    }, []);

    useEffect(() => {
        if (microserviceLaunches !== null) {
            console.log("Launches:", microserviceLaunches);
        }
    }, [microserviceLaunches]);

    const columns = [
        { id: 'name', label: 'Microservice Name', minWidth: 200 },
        {
            id: 'c_dt',
            label: 'Launch Date',
            minWidth: 200,
            format: (value) => value.toDate(),
        },
        { id: 'status', label: 'Launch Status', minWidth: 200 },
        // { id: 'result', label: 'See results', minWidth: 170, onClick: handleGoResults,}
    ];

    return (
        <Paper className={classes.root}>
            <TableContainer className={classes.container}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {Boolean(columns) ? columns.map((column) => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth }}
                                >
                                    {column.label}
                                </TableCell>
                            )):("Nothing to see here")}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {Boolean(microserviceLaunches) ? (microserviceLaunches.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                            return (
                                <TableRow hover role="checkbox" tabIndex={-1} key={row.id} onClick={(event) => handleGoResults(row)}>
                                    {columns.map((column) => {
                                        const value = row[column.id];
                                        return (
                                            <TableCell key={column.id} align={column.align}>
                                                {column.format && typeof value === 'number' ? column.format(value) : value}
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                            );
                        })):("Nothing to see here")}
                    </TableBody>
                </Table>
            </TableContainer>
            {Boolean(microserviceLaunches) ?(
                <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={microserviceLaunches.length || 0}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />):("Nothing")}
        </Paper>
    );
}

import { createAsyncThunk } from "@reduxjs/toolkit";
import { parseUserData } from "../../helpers/parseUserData";
import {
    // fetchAllVisibleProjects,
    fetchListOfProjects,
    fetchDeletedProjects,
    patchProjectData,
} from "./projects";
import { Api } from "../../api";
export const userSignIn = createAsyncThunk(
  "user/signIn",
  async (user, { dispatch }) => {
    const parsedUser = parseUserData(user.toJSON());
    sessionStorage.setItem("user_token", parsedUser.accessToken);
    // const userData = await getSelf();
    const userData = await Api.user.get.self();
    // const unsentProject = localStorage.getItem("unsent_project");
    // if (unsentProject) {
    //   const parsedProject = JSON.parse(unsentProject);
    //   console.log('patchProjectData, user')
    //   await dispatch(patchProjectData(parsedProject));
    // }
    await dispatch(fetchListOfProjects());
    // await dispatch(fetchAllVisibleProjects());
    await dispatch(fetchDeletedProjects());
    // await dispatch(fetchProjectBlueprints());
    return {
      ...parsedUser,
      bid: userData.data.id,
      displayName: parsedUser.displayName || userData.data.name,
    };
  }
);

import {generateNodeId} from "./generateNodeId"

export const convertLink = (link) => {
  const [inputNodeId, inputPort] = link.input.split("|");
  const [outputNodeId, outputPort] = link.output.split("|");
  const inputName = inputPort.split("-")[1];
  const outputName = outputPort.split("-")[1];
  return {
    id: `${outputNodeId},${outputName},${inputNodeId},${inputName}`,
    src: [outputNodeId, outputName],
    dst: [inputNodeId, inputName],
};
};

export const convertLinks = (links) => {
  console.log('ConvertLinks, links:', links)
  return links.map((link) => convertLink(link));
};

export const convertNode = (node, projectBlueprint = 0, node_id = '', f_expand = false, list_nodes = []) => {
  const blueprint_id = node.data.blueprintId;
  console.log('convertNode0, node', node)
  if (projectBlueprint !== 1) {
    let snapshot = node.currentVersion;
    if(Boolean(node.blueprint)){
      snapshot = node.blueprint[1];
    }
    const {coordinates, data, ...newNode} = node;
    const [x, y] = coordinates;
    newNode.geometry = { x, y };
    newNode.last_id = '';
    newNode.blueprint_id = blueprint_id;
    newNode.blueprint = [blueprint_id, snapshot];
    newNode.typeId = data.typeId;
    newNode.data = { ...data, value: 40 };
    newNode.type = 'blueprint';
    // newNode.id = generateNodeId(Object.values(list_nodes), newNode);
    // newNode.data = { value: 42 };
    return newNode;
  } else {
    let snapshot = 'dev';
    if(Boolean(node.blueprint)){
      snapshot = node.blueprint[1];
    }
    let {id, geometry, data, ...newNode} = node;
    newNode.geometry = geometry;
    newNode.blueprint_id = blueprint_id;
    newNode.blueprint = [blueprint_id, snapshot];
    newNode.typeId = f_expand ? data.typeId : node_id+'*'+data.typeId;
    console.log("convertNode, typeId:", newNode.typeId)
    newNode.last_id = node_id+'*'+id;
    newNode.data = { ...data, value: 40, typeId: f_expand ? data.typeId : node_id+'*'+data.typeId };
    newNode.type = f_expand ? 'blueprint' : 'projectBlueprintPart';
    newNode.id = f_expand ? generateNodeId(Object.values(list_nodes), {...newNode}) : node_id+'*'+id;
    // newNode.id = generateNodeId(Object.values(list_nodes), newNode);
    // console.log("convertNode, ID generated:", generateNodeId(Object.values(list_nodes), newNode))
    console.log("convertNode, ID:", newNode.id)
    // newNode.data = { value: 42 };
    return newNode;
  }
};

export const convertNodes = (nodes) => {
  let nodesData = {};
  let nodeProjectParts = [];
  console.log('convertNodes, nodes:', nodes)
  nodes.forEach((node) => {
    if (node.data.type !== 'projectBlueprint') {
      nodesData[node.id] = convertNode(node);
    } else {
      let projectNodes = {...node.data.snapshot.nodes};
      Object.keys(node.data.snapshot.nodes).forEach((node_key) => {
        console.log('convertNodes, nodeId:', node_key);
        let localNode = convertNode(projectNodes[node_key], 1, node.id, node.data.f_expand, {...nodesData});
        nodesData[localNode.id] = {...localNode};
        nodeProjectParts.push({...nodesData[localNode.id]});
      })
      console.log('convertNodes, nodesData:', nodesData);
    };
  });
  return {nodesData: nodesData, nodeProjectParts: nodeProjectParts};
};

const parseLink = (link) => {
  const [outputNodeId, outputName, inputNodeId, inputName] = link.id.split(",");
  console.log('parseLink, input:', `${inputNodeId}|input-${inputName}`)
  return {
    input: `${inputNodeId}|input-${inputName}`,
    output: `${outputNodeId}|output-${outputName}`,
    src: [outputNodeId, outputName],
    dst: [inputNodeId, inputName],
  };
};

export const parseLinks = (links) => {
  return links.map((link) => parseLink(link));
};

// const parseNode = (node) => {
//   try {
//     return Object.entries(node)[1];
//   } catch (err) {
//     console.log("Error when parse one node", err);
//   }
// };

export const parseNodes = (nodes) => {
  console.log('parseNodes, nodes:', nodes)
  return Object.entries(nodes).map((item) => {
    if(item[1].data.type !== 'projectBlueprint') {
      console.log('parseNodes, item:', item)
      const {geometry, ...nodeData} = item[1];
      nodeData.coordinates = [geometry.x, geometry.y] || [geometry[0], geometry[1]];
      // nodeData.blueprint_id = item[1].blueprint[0];
      console.log('parseNodes, nodeData:', nodeData)
      return nodeData;
    } else {
      console.log('parseNodes, item:', item)
      const {...nodeData} = item[1];
      // nodeData.blueprint_id = item[1].blueprint[0];
      console.log('parseNodes, nodeData:', nodeData)
      return nodeData;
    }
  });
};

export const divideProjectLinks = ({convertedNodes, convertedLinks, projectNodes}) => {
  projectNodes.map((node) =>{
    console.log('patchProjectData (thunks), originalNode:', node);
    // Organic links for conversion
    if ( node.data.f_expand ){
      const localLinksSrc = convertedLinks.map((link) => {
        if (link.src[0].split("*")[0] === node.id){
          return link
        }
      })
      localLinksSrc.map((linkSrc) =>{
        let newSrc;
        try {
          newSrc = Object.values(convertedNodes).filter((localNode) => {
            if (localNode.last_id === linkSrc.src[0]) {
              return localNode;
            }
          })[0].id;
          linkSrc.src[0] = newSrc;
          linkSrc.id = `${linkSrc.src[0]},${linkSrc.src[1]},${linkSrc.dst[0]},${linkSrc.dst[1]}`
        }catch (e) {
          console.log("Error with Links 1:", e);
        }
        //
        console.log("patchProjectData (thunks), Organic linkSrc", linkSrc)
        console.log("patchProjectData (thunks), Organic newSrc", newSrc)
      })
      const localLinksDst = convertedLinks.map((link) => {
        if (link.dst[0].split("*")[0] === node.id){
          return link
        }
      })
      localLinksDst.map((linkDst) =>{
        let newDst;
        try {
          newDst = Object.values(convertedNodes).filter((localNode) => {
            if (localNode.last_id === linkDst.dst[0]) {
              return localNode;
            }
          })[0].id;
          linkDst.dst[0] = newDst;
          linkDst.id = `${linkDst.src[0]},${linkDst.src[1]},${linkDst.dst[0]},${linkDst.dst[1]}`
        }catch (e) {
          console.log("Error with Links 2:", e);
        }
        //
        console.log("patchProjectData (thunks), Organic linkSrc", linkDst)
        console.log("patchProjectData (thunks), Organic newSrc", newDst)
      })
    }

    // Links from projectBlueprints
    node.data.snapshot.links.map((link) => {
      let dst = [];
      let localDst, localSrc;
      try {
        localDst = Object.values(convertedNodes).filter((localNode) => {
          console.log("Error reason:", localNode.last_id.split("*"))
          if (localNode.last_id.split("*")[localNode.last_id.split("*").length-2] === node.id && localNode.last_id.split("*")[localNode.last_id.split("*").length-1] === link.dst[0]) {
            return localNode;
          }
        })[0].id;
      }catch (e) {
        console.log("Error with Links 3:", e);
      }
      try{
        localSrc = Object.values(convertedNodes).filter((localNode) => {
          if (localNode.last_id.split("*")[localNode.last_id.split("*").length-2] === node.id && localNode.last_id.split("*")[localNode.last_id.split("*").length-1] === link.src[0]) {
            return localNode;
          }
        })[0].id;
      }catch (e) {
        console.log("Error with Links 4:", e);
      }
      console.log("patchProjectData (thunks), localDst&localSrc:", [localDst, localSrc])
      dst[0] = node.data.f_expand ? localDst : node.id + "*" + link.dst[0];
      dst[1] = link.dst[1];
      let src = [];
      src[0] = node.data.f_expand ? localSrc : node.id + "*" + link.src[0];
      src[1] = link.src[1];
      let id = `${src[0]},${src[1]},${dst[0]},${dst[1]}`
      let type = node.data.f_expand ? '' : 'projectBlueprintLink';
      // let type = 'projectBlueprintLink';
      convertedLinks.push({dst: dst, src: src, id: id, type: type});
    })
  })

  return convertedLinks;
}

export const divideProjectBlueprints = ({convertedNodes, nodeProjects, submitData}) => {
  console.log('divideProjectBlueprints 0, convertedNodes:', convertedNodes)
  console.log('divideProjectBlueprints 0, nodeProjects:', nodeProjects)
  console.log('divideProjectBlueprints 0, submitData:', submitData);
  console.log('patchProjectData (thunks) nodeProjects', nodeProjects);
  Object.entries(nodeProjects).map((node) => {
    console.log('patchProjectData (thunks) node', node);
    Object.keys(node[1].inputs).forEach((input_key) => {
      try{
        let input = {...node[1].inputs[input_key]};
        const [inputNodeId, inputPort] = input.id.split("|");
        console.log("patchProjectData, node", node[1])
        console.log("patchProjectData, inputs ports", [node[1].id, inputPort])
        console.log("patchProjectData, input", convertedNodes[node[1].id].inputs[inputPort.split('-')[1]])
        let localInput = {};
        localInput['adapter'] =  convertedNodes[node[1].id]['inputs'][inputPort.split('-')[1]]['adapter'];
        localInput['adapter_name'] =  convertedNodes[node[1].id]['inputs'][inputPort.split('-')[1]]['adapter_name'];
        localInput['alignment'] =  convertedNodes[node[1].id]['inputs'][inputPort.split('-')[1]]['alignment'];
        localInput['id'] =  node[1].id+"|"+inputPort;
        localInput['is_file'] =  convertedNodes[node[1].id]['inputs'][inputPort.split('-')[1]]['is_file'];
        localInput['name'] =  convertedNodes[node[1].id]['inputs'][inputPort.split('-')[1]]['name'];
        localInput['name_id'] =  convertedNodes[node[1].id]['inputs'][inputPort.split('-')[1]]['name_id'];
        localInput['node_name'] =  convertedNodes[node[1].id]['inputs'][inputPort.split('-')[1]]['node_name'];
        localInput[convertedNodes[node[1].id]['inputs'][inputPort.split('-')[1]]['name_id']] = convertedNodes[node[1].id]['inputs'][inputPort.split('-')[1]][convertedNodes[node[1].id]['inputs'][inputPort.split('-')[1]]['name_id']];
        try{
          let globalNode = submitData.filter((node1) => node1.id === node[1].id.split("*")[0]);
          console.log("patchProjectData, globalNode:", globalNode);
          let globalValue = globalNode[0].inputs.filter((input) => input.id === (node[1].id+"|"+inputPort));
          console.log("patchProjectData, inputValue:", globalValue);
          localInput['value'] = globalValue[0].value;
        }catch {
          localInput['value'] = input.value;
        }
        let localInputs = {...convertedNodes[node[1].id]['inputs']};
        delete convertedNodes[node[1].id]['inputs'];
        localInputs[inputPort.split('-')[1]] = localInput;
        convertedNodes[node[1].id]['inputs'] = localInputs;
      } catch (e) {
        console.log('Error:', e)
      }

    })
    Object.keys(node[1].outputs).forEach((output_key) => {
      let output = {...node[1].outputs[output_key]};
      const [inputNodeId, inputPort] = output.id.split("|");
      console.log("patchProjectData, outputs ports", [node[1].id, inputPort])
      console.log("patchProjectData, outputs", convertedNodes[node[1].id].outputs[inputPort.split('-')[1]])

      try{
        let localInput = {};
        localInput['adapter'] =  convertedNodes[node[1].id]['outputs'][inputPort.split('-')[1]]['adapter'];
        localInput['adapter_name'] =  convertedNodes[node[1].id]['outputs'][inputPort.split('-')[1]]['adapter_name'];
        localInput['alignment'] =  convertedNodes[node[1].id]['outputs'][inputPort.split('-')[1]]['alignment'];
        localInput['id'] =  node[1].id+"|"+inputPort;
        localInput['is_file'] =  convertedNodes[node[1].id]['outputs'][inputPort.split('-')[1]]['is_file'];
        localInput['name'] =  convertedNodes[node[1].id]['outputs'][inputPort.split('-')[1]]['name'];
        localInput['name_id'] =  convertedNodes[node[1].id]['outputs'][inputPort.split('-')[1]]['name_id'];
        localInput['node_name'] =  convertedNodes[node[1].id]['outputs'][inputPort.split('-')[1]]['node_name'];
        localInput[convertedNodes[node[1].id]['outputs'][inputPort.split('-')[1]]['name_id']] =  convertedNodes[node[1].id]['outputs'][inputPort.split('-')[1]][convertedNodes[node[1].id]['outputs'][inputPort.split('-')[1]]['name_id']];
        localInput['value'] = output.value;
        let localInputs = {...convertedNodes[node[1].id]['outputs']};
        delete convertedNodes[node[1].id]['outputs'];
        localInputs[inputPort.split('-')[1]] = localInput;
        convertedNodes[node[1].id]['outputs'] = localInputs;
      } catch (e) {
        console.log('Error:', e)
      }

    })
    console.log('patchProjectData (thunks) 1.2', convertedNodes);
  });

  return convertedNodes
}

import { Projects } from "./projects";
import { User } from "./user";
import { Tasks } from "./tasks";
import { Snapshots } from "./snapshots";
import { Nodes } from "./nodes";
import { Blueprints } from "./blueprints";
import { Adapters } from "./adapters";
import { Dev } from "./dev";
import { Links } from "./links";
import { Microservices } from "./microservices";
import { Search } from "./search";

export const Api = {
  user: User,
  projects: Projects,
  tasks: Tasks,
  snapshots: Snapshots,
  nodes: Nodes,
  blueprints: Blueprints,
  adapters: Adapters,
  links: Links,
  microservices: Microservices,
  search: Search,
};

if (process.env.REACT_APP_STAGE === "dev_remote" || "dev_local") {
  Api.dev = Dev;
  window.api = Api;
}

import Config from "../config";
import axios from "axios";

//get list of projects
const getUserProjects = ({ filters }) => {
    return axios({
        method: "get",
        baseURL: Config.baseUrl,
        url: `/projects?snapshots=${filters}`,
    });
};

const getDeletedList = () => {
    return axios({
        method: "get",
        baseURL: Config.baseUrl,
        url: `/projects/deleted?snapshots=contents`,
    });
};

//Get project info by id
const getProjectById = ({ projectId }) => {
    return axios({
        method: "get",
        baseURL: Config.baseUrl,
        url: `/projects/${projectId}`,
    });
};

//Get project snapshot by project & snapshot Id's
const getProjectSnapshotsById = ({ projectId }) => {
    return axios({
        method: "get",
        baseURL: Config.baseUrl,
        url: `/projects/${projectId}/snapshots/dev`,
    });
};

// Download File
const getProjectFile = ({ output_name, task_id, node_id }) => {
    return axios({
        method: "get",
        baseURL: Config.baseUrl,
        url: `/file/${task_id}/${node_id}/${output_name}`,
        responseType: 'arraybuffer',
    });
};

export const Search = {
    get: {
        list: getUserProjects,
        delList: getDeletedList,
        byId: getProjectById,
        snapshot: getProjectSnapshotsById,
        downfile: getProjectFile,
    },
};

import axios from "axios";
import Store from "../store/configStore";
import { setFetchingGlobal } from "../store/ui/common";
const { dispatch } = Store;
// Request interceptor, adds auth bearer header to every api call
axios.interceptors.request.use(
  async (config) => {
    dispatch(setFetchingGlobal(true));
    const token = sessionStorage.getItem("user_token");
    return {
      ...config,
      headers: {
        ...config.headers,
        ["Authorization"]: `Bearer ${token}`,
      },
    };
  },
  function (error) {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  async (response) => {
    dispatch(setFetchingGlobal(false));
    return response;
  },
  (err) => {
    return Promise.reject(err);
  }
);

export const getAreaCoords = (e, prev) => {
  const { pageX, pageY } = e;
  const startX = pageX < prev.init.x ? pageX : prev.init.x;
  const startY = pageY < prev.init.y ? pageY : prev.init.y;
  const endX = pageX > prev.init.x ? pageX : prev.init.x;
  const endY = pageY > prev.init.y ? pageY : prev.init.y;
  const width = pageX < prev.init.x ? prev.init.x - pageX : pageX - prev.init.x;
  const height =
    pageY < prev.init.y ? prev.init.y - pageY : pageY - prev.init.y;
  return {
    ...prev,
    start: {
      x: startX,
      y: startY,
    },

    size: {
      width,
      height,
    },
    end: {
      x: endX,
      y: endY,
    },
  };
};

export const resetSelectAreaCoords = () => {
  return {
    init: { x: 0, y: 0 },
    start: { x: 0, y: 0 },
    end: { x: 0, y: 0 },
    size: { width: 0, height: 0 },
  };
};

export const findNodesInArea = (coords, snapshotData) => {
  const { from, to } = coords;
  const { nodes } = snapshotData;
  if (!nodes.length) {
    return null;
  }
  const newNodes = nodes.filter((node) => {
    const [cordX, cordY] = node.coordinates;
    return cordX > from.x && cordY > from.y && cordX < to.x && cordY < to.y;
  });
  return newNodes;
};

import { createSelector, createSlice } from "@reduxjs/toolkit";
import { fetchProjectInfoById } from "../thunks/projects";

export const tableSpaceSlice = createSlice({
  name: "tableSpace",
  initialState: {
    workSpace: null,
    projectEditable: false,
    workProject: null,
    nodeList: {
      options: [],
      selectedNodes: [],

      errorNodes: {
        list: [],
      },
      expandedGroupData: {
        groupId: null,
        groupSchema: {},
      },
    },
    currentGraphData: {
      nodes: [],
      links: [],
      run_task_id: "", run_task_flg: ""

    },
  },
  reducers: {
    setGraphData: (state, action) => {
      state.currentGraphData = action.payload;
    },
    setWorkProject: (state, action) => {
      state.workProject = action.payload;
    },
    setNodeListOptions: (state, action) => {
      state.nodesList.options = action.payload;
    },
    setSelectNode: (state, action) => {
      state.nodeList.selectedNodes = [action.payload];
    },
    clearSelectedNodes: (state, action) => {
      state.nodeList.selectedNodes = [];
    },
    addNodeToSelected: (state, action) => {
      state.nodeList.selectedNodes.push(action.payload);
    },
    setExpandedGroupId: (state, action) => {
      state.nodeList.expandedGroupData.groupId = action.payload;
    },
    setExpandedGroupSchema: (state, action) => {
      state.nodeList.expandedGroupData.groupSchema = action.payload;
    },
    clearExpandedGroupData: (state, action) => {
      state.nodeList.expandedGroupData = {
        groupId: null,
        groupSchema: {},
      };
    },
    setErrorNodes: (state, action) => {
      state.nodeList.errorNodes.list = action.payload;
    },
    clearErrorNodes: (state, action) => {
      state.nodeList.errorNodes.list = [];
    },
    setProjectEditable: (state, action) => {
      state.projectEditable = action.payload;
    },
    clearCurrentGraphData: (state) => {
      state.currentGraphData = {
        nodes: [],
        links: [],
        run_task_id: "", run_task_flg: "",
      };
    },
    clearWorkSpace: (state) => {
      state.workSpace = null;
    },
  },
  extraReducers: {
    [fetchProjectInfoById.fulfilled]: (state, action) => {
      state.workSpace = action.payload.schema;
      state.workProject = action.payload.meta;
      // state.run_task_id = action.payload.run_task_id;
      // state.run_task_flg = action.payload.run_task_flg;
      state.projectEditable = true;
    },
  },
});

export const {
  setGraphData,
  setWorkProject,
  setNodeListOptions,
  clearSelectedNodes,
  addNodeToSelected,
  setSelectNode,
  setExpandedGroupId,
  setExpandedGroupSchema,
  clearExpandedGroupData,
  setErrorNodes,
  clearErrorNodes,
  setProjectEditable,
  clearCurrentGraphData,
  clearWorkSpace,
} = tableSpaceSlice.actions;
export default tableSpaceSlice.reducer;

export const testSelector = createSelector(
  (state) => state.entities.tableSpace.nodeList.selectedNodes,
  (state) => state.entities.tableSpace.nodeList.errorNodes,
  (state) => state.entities.tableSpace.currentGraphData,
  (state) => state.entities.tableSpace.isEditMode,
  (selectedNodes, errorNodes, currentGraphData, isEditMode) => ({
    selectedNodes,
    errorNodes,
    snapshotData: currentGraphData,
    isEditMode,
  })
);

import React, {useEffect, useState, useCallback} from "react";
import {
  Box,
  Button,
  Dialog,
  TextField,
  Typography,
  Table,
  TableRow,
  TableCell,
  TableHead,
  Checkbox
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import {Api} from "../../api";
import {Label} from "@material-ui/icons";


let start_flg = false;

export const flgSettingsPage = () =>{
  start_flg = true;
}

export const EditSettings = ({ isOpen, classes, onClose, fetchMSIO, projectMeta, setWorkProject }) => {
  let [data, setData] = useState({
    values: null,
  });


  let [projectId, setProjectId] = useState({});
  let [meta, setMeta] = useState({name:"", description:"", f_public:""});

  const fetchData = useCallback(async () => {
    const data = await fetchMSIO();
    setData(data);
  }, [])

  useEffect(() => {
    if (start_flg) {
      fetchData();
      console.log("Page EditMS, data:", data)
      start_flg = false;
    };
  }, [start_flg])


  useEffect(() => {
    if(data.values !== null) {
      setProjectId(data.projectId);
      let tmp_meta = {name: projectMeta.name, description: projectMeta.description, f_public: projectMeta.f_public};
      setMeta(tmp_meta);
    }
  }, [data])

  const handleSubmit = async () => {
    console.log('GraphCanvas, SettingsPage!', projectId)

    let status = await Api.projects.patch.info({projectId: projectId,data: {...meta}});

    if (status.status === 200){
      setWorkProject({name: meta.name, f_public: meta.f_public, description: meta.description});
    }

    onClose();
  };

  const handleEditMeta = async (e, key) => {
    // console.log('EditMS, handleEditName:', e.target.value)
    let tmp_meta = {...meta};
    tmp_meta[key] = e;
    setMeta(tmp_meta)
    console.log('EditMS, tmp_meta:', tmp_meta)
  };


  return (
    <Dialog
      open={isOpen}
      modal={true}
      classes={{ paper: classes.dialogPaperRoot }}
      onClose={onClose}
    >
      <Typography variant={"h2"}>Project Settings</Typography>
      <Box mb={3} width={"100%"}>
        <TextField multiline rows={1} label={"Project Name"} fullWidth
                   onChange={(e) => handleEditMeta(e.target.value, 'name')}
                   defaultValue={projectMeta.name}
        />
      </Box>
      <Box mb={3} width={"100%"}>
        <TextField multiline label={"Project Description"} fullWidth
                   onChange={(e) => handleEditMeta(e.target.value, 'description')}
                   defaultValue={projectMeta.description}
        />
        <Box>
          <br/>
          <label>
            <input
                type="checkbox"
                defaultChecked={projectMeta.f_public}
                // ref="complete"
                onChange={(e) => handleEditMeta(e.target.checked, 'f_public')}
            />
            Is Public
          </label>
        </Box>
      </Box>
      <Box
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        flexDirection={"column"}
        // height={"500px"}
      >

        <Box mt={2}>
          <Button
              onClick={handleSubmit}
          >Submit</Button>
        </Box>
      </Box>
    </Dialog>
  );
};

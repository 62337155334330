import React from "react";
import Node from "./Node";
import { v4 as uuid } from "uuid";
import { generateNodeId } from "../../helpers/generateNodeId";
export const selectNode = (nodeId) => {
  return [nodeId];
};

export const addNode = (schema, node, nodeMethods, coordinatesAlt) => {
  // const nodeId = `node-${uuid().split("-").join("")}`;

  const nodeId = generateNodeId(schema.nodes, node);
  const typeId = node.typeId || node.data.typeId;
  console.log('GraphCanvas, inputs(generate): ', node)
  let nodeOptions = {
    ...node,
    id: nodeId,
    typeId,
    inputs: (node.type !== "projectBlueprint" ? _generatePorts(nodeId, node.inputs, "inputs") : _generatePorts(nodeId, node.inputs, "inputs", 1)),
    outputs: (node.type !== "projectBlueprint" ? _generatePorts(nodeId, node.outputs, "outputs") : _generatePorts(nodeId, node.outputs, "outputs", 1)),
    data: { ...node.data },
    currentVersion: node.currentVersion,
    versions: {...node.versions},
    snapshot: (node.type !== "projectBlueprint" ? null : node.snapshot),
    blueprintId: node.blueprint_id,
    language: node.language,
  };

  nodeOptions.inputs.forEach(item=>{
    item[item.name_id]={adapter:item.adapter,value:item.value, is_file: item.is_file};
    // item.push(inputs_agg);
  })
  // nodeOptions.inputs.push(inputs_agg);
  nodeOptions.outputs.forEach(item=>{
    item[item.name_id]={adapter:item.adapter,value:item.value, is_file: item.is_file};
    // item.push(outputs_agg);
  })
  // nodeOptions.outputs
  const newNode = GraphNode(nodeOptions, nodeMethods, coordinatesAlt);
  console.log('GraphCanvas, old Schema: ', schema)
  return {
    addedNode: newNode,
    schema: {
      ...schema,
      nodes: [...schema.nodes, newNode],
    },
  };
};

export const deleteNode = (nodeId, schema, nodeMethods) => {
  if (typeof nodeId === "object") {
    const removed = {
      nodes: [],
      links: [],
      run_task_id: "", run_task_flg: "",
      removed_links: [],
    };

    const nodes = schema.nodes
      .map((node) => {
        const { id, coordinates, data, inputs, outputs, language, currentVersion, versions } = node;
        const nodeOptions = {
          id,
          currentVersion,
          versions,
          coordinates,
          data,
          inputs,
          outputs,
          blueprintId: data.blueprintId,
          language,
        };
        return GraphNode(nodeOptions, nodeMethods);
      })
      .filter((node) => {
        if (!nodeId.includes(node.id)) {
          return node;
        }
        removed.nodes.push(node);
      });
    console.log('schemaControl, nodes1: ', nodes);
    const links = schema.links.filter((link) => {
      const input = link.input.split("|")[0];
      const output = link.output.split("|")[0];
      console.log('schemaControl, link input: ', input)
      console.log('schemaControl, link output: ', output)
      console.log('schemaControl, link nodeId: ', nodeId)

      if ((!nodeId.includes(input) && !nodeId.includes(output)) &&
          (!nodeId.includes(input.split("*")[0]) && !nodeId.includes(output.split("*")[0]))) {
        return link;
      }
      else{
        console.log('schemaControl, link for delete: ', link)
        removed.removed_links.push(link)
      }
      removed.links.push(link);
    });
    console.log('schemaControl, removed:', removed)
    return { schema: { ...schema, nodes, links }, removed };
  }
  console.log('schemaControl, schema.nodes fin:', schema.nodes)
  return {
    ...schema,
    nodes: schema.nodes
      .map((node) => {
        const { id, coordinates, data, inputs, outputs, language, currentVersion, versions } = node;
        const nodeOptions = {
          id,
          coordinates,
          data,
          inputs,
          outputs,
          language
          , currentVersion, versions

        };
        return GraphNode(nodeOptions, nodeMethods);
      })
      .filter((node) => node.id !== nodeId),
    links: schema.links.filter((link) => {
      const input = link.input.split(".")[0];
      const output = link.output.split(".")[0];
      if (nodeId !== input && nodeId !== output) {
        return link;
      }
    }),
  };
};

export const checkLinksPort = (schema, links) => {
  if (links.length !== schema.links.length) {
    return links.some((link) => {
      const [inputNodeName, inputPort] = link.input.split("|");
      const [outputNodeName, outputPort] = link.output.split("|");
      const inputName = inputPort.split("-")[0];
      const outputName = outputPort.split("-")[0];
      return inputName === outputName || inputNodeName === outputNodeName;
    });
  }
};

export const createNodeGroup = (selectedNodes, schema, nodeMethods) => {
  const result = deleteNode(selectedNodes, schema, nodeMethods);
  const mainData = {
    ...result.removed,
    nodes: result.removed.nodes.map((node) => {
      const { id, coordinates, data, inputs, outputs, language, currentVersion, versions
      } = node;
      return { id, coordinates, data, inputs, outputs, language, currentVersion, versions
      };
    }),
  };
  const group = GroupNodes(mainData, nodeMethods);
  const newSchema = {
    ...result.schema,
    nodes: [...result.schema.nodes, group],
  };
  return newSchema;
};

export const showNestedNodes = (
  nodeId,
  groupData,
  snapshotData,
  nodeMethods
) => {
  //TODO: re-check newNodes logic
  const foundNode = snapshotData.nodes.find((node) => node.id === nodeId);
  //node coordinates + node height / 2
  const center = Math.floor(foundNode.coordinates[1] + 80 / 2);

  const newNodes = snapshotData.nodes.map((node) => {
    const [posX, posY] = node.coordinates;
    if (posY > center) {
      const newNodeCoords = [posX, posY + 100];
      return GraphNode({ ...node, coordinates: newNodeCoords }, nodeMethods);
    }
    const newNodeCoords = [posX, posY - 100];
    return GraphNode({ ...node, coordinates: newNodeCoords }, nodeMethods);
  });

  //remode current group node
  const resultRemoved = deleteNode(
    [nodeId],
    { ...snapshotData, nodes: newNodes },
    nodeMethods
  );

  const nodesWithMethods = [
    ...resultRemoved.schema.nodes,
    ...groupData.schema.nodes,
  ].map((node) => GraphNode(node, nodeMethods));

  const newSchema = {
    nodes: nodesWithMethods,
    links: [...resultRemoved.schema.links, ...groupData.schema.links],
  };

  return newSchema;
};

export const collapseGroupNodes = (groupData, schema, nodeMethods) => {
  const groupId = groupData.groupId;
  const nodeIds = [];
  groupData.groupSchema.nodes.forEach((node) => nodeIds.push(node.id));
  const result = deleteNode(nodeIds, schema, nodeMethods);

  const mainData = {
    ...result.removed,
    nodes: result.removed.nodes.map((node) => {
      const { id, coordinates, data, inputs, outputs, currentVersion, versions } = node;
      return { id, coordinates, data, inputs, outputs, currentVersion, versions };
    }),
  };
  const group = GroupNodes(mainData, nodeMethods, groupId);
  const newSchema = {
    ...result.schema,
    nodes: [...result.schema.nodes, group],
  };
  return newSchema;
};

export const checkInputs = (schema, links) => {
  if (schema.links.length < links.length) {
    let newLink = null;
    links.forEach((link) => {
      const res = schema.links.includes(link);
      if (!res) {
        newLink = link;
      }
    });
    const linkForChange = schema.links.findIndex(
      (link) => link.input === newLink.input
    );
    if (linkForChange >= 0) {
      const newLinks = [...schema.links];
      newLinks.splice(linkForChange, 1, newLink);
      return { result: true, data: newLinks };
    }
  }
  return { result: false };
};

//==================== NODE PORT
export const NodePort = (nodeId, portId, portType) => {
  console.log('NodePort, nodeId, portId, portType', nodeId, portId, portType)
  console.log('NodePort, portId', portId)

  return {
    id: `${nodeId}|${portType}-${portId.name}`,
    alignment: portType === "input" ? "left" : "right",
    dynamic: portId.dynamic === undefined ? true :  portId.dynamic,
    name_id: portId.name,
    adapter: portId.adapter,
    adapter_name: portId.adapter_name,
    is_file: "inline",
    value: portId.value||0
  };
};
//==================== NODE
export const GraphNode = (options, methods, coordinatesAlt) => {
  console.log('schemaControls, options:', options)
  const { innerWidth, innerHeight, scrollX, scrollY } = window;
  let coordinates = coordinatesAlt ? coordinatesAlt : options.coordinates || [
    innerWidth / 2 - 80 + scrollX,
    innerHeight / 2 - 80 + scrollY,
  ];
  let snapshot = {...options.snapshot};
  if(!options.snapshot){
    snapshot = {...options.data.snapshot};
  }
  console.log('schemaControls, snapshot', snapshot)
  return {
    id: options.id || uuid(),
    coordinates: options.coordinates || coordinates,
    data: {
      ...options.data,
      type: options.type || options.data?.type || "default",
      typeId: options.typeId || options.data?.typeId || "default",
      f_expand: options.f_expand || false,
      schema: options.schema || {},
      blueprintId: options.blueprintId,
      language: options.language,
      snapshot: {...snapshot},
    },
    currentVersion: options.currentVersion,
    versions: {...options.versions},
    inputs: options.inputs || [],
    outputs: options.outputs || [],
    language: options.language,
    ...methods,
    render: ({ inputs, outputs, ...rest }) => {
      return <Node inputs={inputs} outputs={outputs} {...rest} {...methods} />;
    },
  };
};

export const GroupNodes = (groupList, nodeMethods, id) => {
  const groupId = id || `group-${Date.now()}`;
  const options = {
    id: groupId,
    inputs: _generatePorts(id, 1, "inputs"),
    outputs: _generatePorts(id, 1, "outputs"),
    typeId: "node_group",
    schema: groupList,
  };
  return GraphNode(options, nodeMethods);
};

export const _generatePorts = (nodeId, ports, type, project = 0) => {
  if (ports) {
    console.log('_generatePorts, ports', ports)
    return ports.map((port) => {
      if (project === 0) {
        return NodePort(nodeId, port, type === "inputs" ? "input" : "output");
      } else {
        return NodePort(nodeId+'*'+port.node_name, port, type === "inputs" ? "input" : "output");
      };
    });
  }
  return [];
};

export const getCanvasSize = (nodes, canvasSize) => {
  // const { outerHeight: y, outerWidth: x } = window;
  const { availWidth: x, availHeight: y } = window.screen;
  const maxOutOnX = Math.max(...nodes.map((node) => node.coordinates[0]));
  const maxOutOnY = Math.max(...nodes.map((node) => node.coordinates[1]));

  let canvasWidth = canvasSize.x;
  let canvasHeight = canvasSize.y;

  if (maxOutOnX + 160 > canvasWidth) {
    canvasWidth = maxOutOnX + x * 0.3;
  }
  if (maxOutOnY + 60 > canvasHeight) {
    canvasHeight = maxOutOnY + y * 0.5;
  }
  if (maxOutOnX + 160 < x) {
    canvasWidth = x;
  }
  if (maxOutOnY + 60 < y) {
    canvasHeight = y;
  }
  return { canvasWidth, canvasHeight };
};

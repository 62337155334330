import React, {useEffect, useState, useCallback} from "react";
import {
  Box,
  Button,
  Dialog,
  TextField,
  Typography,
  Table,
  TableRow,
  TableCell,
  TableHead,
  Checkbox, TableContainer, TableBody, TablePagination, Tooltip
} from "@material-ui/core";
import AutorenewIcon from '@material-ui/icons/Autorenew';
import DeleteIcon from '@material-ui/icons/Delete';
import TrendingFlatIcon from '@material-ui/icons/TrendingFlat';
import CallSplitIcon from '@material-ui/icons/CallSplit';
import {Divider, DialogTitle, DialogContent, DialogContentText, DialogActions} from "@material-ui/core";
import {Api} from "../../api";
import {useHistory} from "react-router-dom";
import {useDispatch} from "react-redux";
import {createNewProject} from "../../store/thunks/projects";



let start_flg = false;

export const flgSnapshotsPage = () =>{
  start_flg = true;
}

export const SnapshotsList = ({ isOpen, classes, onClose, fetchMSIO, projectMeta, setWorkProject, projectId,
                                changeProjectSnapshot, submitSnapshot, patchProject }) => {
  let [data, setData] = useState({
    values: null,
  });
  const history = useHistory();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(8);

  let [snapshots, setSnapshots] = useState([]);
  let [removeSnapshots, setRemoveSnapshots] = useState({});
  let [descriptionSnapshots, setDescriptionSnapshots] = useState({});
  let [changeSnapshots, setChangeSnapshots] = useState({});
  let [sendSnapshots, setSendSnapshots] = useState({});
  let [metaSnap, setMetaSnap] = useState({name:"", description:""});
  let [f_fix, setF_fix] = useState(false);
  let [newProjectName, setNewProjectName] = useState("");
  const dispatch = useDispatch();

  const handleEditMeta = async (e, key) => {
    // console.log('EditMS, handleEditName:', e.target.value)
    let tmp_meta = {...metaSnap};
    tmp_meta[key] = e;
    setMetaSnap(tmp_meta)
    console.log('EditMS, tmp_meta:', tmp_meta)
  };

  useEffect(async () => {
    if (start_flg) {
      const localSnaps = await Api.projects.get.byId({ projectId });
      console.log("SnapshotsList, localSnaps:", localSnaps.data.snapshots);

      setSnapshots(localSnaps.data.snapshots.sort((a, b) => Date.parse(b.geometry.c_dt) - Date.parse(a.geometry.c_dt)));
      start_flg = false;
      let removeSnapshotsLocal = {};
      let changeSnapshotsLocal = {};
      let descriptionSnapshotsLocal = {};
      let sendSnapshotsLocal = {};
      localSnaps.data.snapshots.sort((a, b) => Date.parse(b.geometry.c_dt) - Date.parse(a.geometry.c_dt)).forEach((snap) => {
        removeSnapshotsLocal[snap.name] = false;
        changeSnapshotsLocal[snap.name] = false;
        sendSnapshotsLocal[snap.name] = false;
        descriptionSnapshotsLocal[snap.name] = "";
        try {
          if (Boolean(snap.notes.description)) {
            descriptionSnapshotsLocal[snap.name] = snap.notes.description;
          };
        }catch (e) {
        }
      })
      setRemoveSnapshots(removeSnapshotsLocal);
      setChangeSnapshots(changeSnapshotsLocal);
      setDescriptionSnapshots(descriptionSnapshotsLocal);
      setSendSnapshots(sendSnapshotsLocal);
      console.log("SnapshotsList, removeSnapshotsLocal:", descriptionSnapshotsLocal);
    };
  }, [start_flg])


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handlerGoToSnapshot = (e) => {
    console.log("SnapshotsList, goToSnapshot:", e)
    changeProjectSnapshot(e);
    history.push(`/tablespace/${projectId}/${e.toString()}`);
    onClose();
  };

  const handlerCreateSnapshot = (e) => {
    submitSnapshot({meta: metaSnap, entityName: projectMeta.name, f_BlueprintFix: f_fix});
    onClose();
  }

  const handlerDeleteSnapshot = async (e) => {
    let removeSnapshotsLocal = {...removeSnapshots};
    if(e !== 'dev') {
      removeSnapshotsLocal[e] = true;
      console.log("SnapshotsList, handlerDeleteSnapshot:", removeSnapshots);
      setRemoveSnapshots(removeSnapshotsLocal);
    }
  }

  const handlerChangeSnapshot = async (e) => {
    let changeSnapshotsLocal = {...changeSnapshots};
    changeSnapshotsLocal[e] = true;
    // console.log("SnapshotsList, handlerDeleteSnapshot:", removeSnapshots);
    setChangeSnapshots(changeSnapshotsLocal);
  }

  const handleRemoveClose = async (e, flag) => {
    let removeSnapshotsLocal = {...removeSnapshots};
    removeSnapshotsLocal[e] = false;
    setRemoveSnapshots(removeSnapshotsLocal);
    if(flag){
      Api.projects.delete.snapshot({projectId, snapshotName: e})
      onClose();
    };
  };

  const handleChangeClose = async (e, flag) => {
    let changeSnapshotsLocal = {...changeSnapshots};
    changeSnapshotsLocal[e] = false;
    setChangeSnapshots(changeSnapshotsLocal);
    if(flag){
      history.push(`/tablespace/${projectId}`);
      await changeProjectSnapshot(e);
      await dispatch(patchProject());
      onClose();
    };
    onClose();
  };

  const handlerSendSnapshot = async (e) => {
    let sendSnapshotsLocal = {...sendSnapshots};
    sendSnapshotsLocal[e] = true;
    // console.log("SnapshotsList, handlerDeleteSnapshot:", removeSnapshots);
    setSendSnapshots(sendSnapshotsLocal);
  }

  const handleSendClose = async (e, flag) => {
    let sendSnapshotsLocal = {...sendSnapshots};
    sendSnapshotsLocal[e] = false;
    setSendSnapshots(sendSnapshotsLocal);

    if(flag) {
      const formData = {
        name: newProjectName.toString(),
        tags: '',
        description: 'Replica_' + projectMeta.description,
        f_public: projectMeta.f_public,
      };
      console.log('SnapshotsList, handleSendClose1')
      const new_proj = await Api.projects.post.create(formData);
      console.log('SnapshotsList, handleSendClose2')
      history.push(`/tablespace/${new_proj.data.project_id}`);
      await changeProjectSnapshot(e);
      await dispatch(patchProject());
    }
    onClose();
  };

  const handleEditFix = async () => {
    console.log('SnapshotsList, f_fix', f_fix)
    setF_fix(!f_fix);
  }

  const columns = [
    { id: 'name', label: 'Snapshot Name', minWidth: 250 },
    {
      id: 'c_dt',
      label: 'Creation Date',
      minWidth: 200,
      format: (value) => value.toDate(),
    },
    { id: 'actions', label: 'Actions', minWidth: 200 },
    // { id: 'result', label: 'See results', minWidth: 170, onClick: handleGoResults,}
  ];

  const handleNewProjectNameChange = async (e) =>{
    console.log("e.target.value:", e.target.value)
    setNewProjectName(e.target.value)
  }

  return (
    <Dialog
      open={isOpen}
      modal={true}
      classes={{ paper: classes.dialogPaperRoot }}
      onClose={onClose}
      style={{"minHeight": "80vh"}}
    >
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table" >
          <TableHead>
            <TableRow>
              {Boolean(columns) ? columns.map((column) => (
                  <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
              )):("Nothing to see here")}
            </TableRow>
          </TableHead>
          <TableBody>
            {Boolean(snapshots) ? (snapshots.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
              return (
                  <TableRow role="checkbox" tabIndex={-1} key={row.id} style={{}}>
                    {columns.map((column) => {
                      const value = column.id === 'c_dt' ? row.geometry[column.id].slice(0,19).replace(/T/g,' ') : row[column.id];
                      return (
                          <TableCell key={column.id} align={column.align}>
                            <Tooltip title={descriptionSnapshots[row.name] !== "" ? descriptionSnapshots[row.name] : row.name}>
                              <Typography>
                            {column.format && typeof value === 'number' ? column.format(value) : value}
                              </Typography>
                            </Tooltip>
                            {column.id === 'actions' ?
                                <>
                                  <Tooltip title={"Go to this snapshot"}>
                                    <TrendingFlatIcon key={row.name.toString()} style={{"margin-right": 10}} onClick={() =>
                                        handlerGoToSnapshot(row.name.toString())
                                    }></TrendingFlatIcon>
                                  </Tooltip>

                                  {/*CHANGE SNAPSHOT*/}
                                  <Tooltip title={"Change DEV to this snapshot"}>
                                    <AutorenewIcon style={{"margin-right": 10}} onClick={() =>
                                        handlerChangeSnapshot(row.name.toString())
                                    }></AutorenewIcon>
                                  </Tooltip>
                                  <Dialog
                                      open={changeSnapshots[row.name]}
                                      onClose={() => handleChangeClose(row.name, false)}
                                      aria-labelledby={"change_dialog" + row.name.toString()}
                                      aria-describedby={"change_description" + row.name.toString()}
                                  >
                                    <DialogTitle id={"change_dialog" + row.name.toString()}>
                                      {'Replace DEV snapshot to '+ row.name+'?'}
                                    </DialogTitle>
                                    <DialogContent>
                                      <DialogContentText id={"change_description" + row.name.toString()}>
                                        Are you sure to replace current DEV-snapshot?
                                      </DialogContentText>
                                    </DialogContent>
                                    <DialogActions>
                                      <Button onClick={() => handleChangeClose(row.name, false)}>Cancel</Button>
                                      <Button onClick={() => handleChangeClose(row.name, true)} autoFocus>
                                        OK
                                      </Button>
                                    </DialogActions>
                                  </Dialog>

                                  {/*SEND TO PROJECT*/}
                                  <Tooltip title={"Create new Project with "+row.name+" snapshot"}>
                                    <CallSplitIcon style={{"margin-right": 10}} onClick={() =>
                                        handlerSendSnapshot(row.name.toString())
                                    }></CallSplitIcon>
                                  </Tooltip>
                                  <Dialog
                                      open={sendSnapshots[row.name]}
                                      onClose={() => handleSendClose(row.name, false)}
                                      aria-labelledby={"send_dialog" + row.name.toString()}
                                      aria-describedby={"send_description" + row.name.toString()}
                                  >
                                    <DialogTitle id={"send_dialog" + row.name.toString()}>
                                      {'Create new project based on '+ row.name+'?'}
                                    </DialogTitle>
                                    <DialogContent>
                                      <DialogContentText id={"change_description" + row.name.toString()}>
                                        Are you sure to create new project?
                                      </DialogContentText>
                                      <TextField
                                          autoFocus
                                          margin="dense"
                                          id={"name" + row.name.toString()}
                                          label="Name of New Project"
                                          type="text"
                                          onChange={handleNewProjectNameChange}
                                          fullWidth
                                      />
                                    </DialogContent>
                                    <DialogActions>
                                      <Button onClick={() => handleSendClose(row.name, false)}>Cancel</Button>
                                      <Button onClick={() => handleSendClose(row.name, true)} autoFocus>
                                        OK
                                      </Button>
                                    </DialogActions>
                                  </Dialog>

                                  {/*REMOVE SNAP*/}
                                  <Tooltip title={"Delete this snapshot"}>
                                    <DeleteIcon key={row.name.toString()} style={{"margin-right": 10}} onClick={() =>
                                        handlerDeleteSnapshot(row.name.toString())
                                    }></DeleteIcon>
                                  </Tooltip>
                                  <Dialog
                                      open={removeSnapshots[row.name]}
                                      onClose={() => handleRemoveClose(row.name, false)}
                                      aria-labelledby={"remove_dialog" + row.name.toString()}
                                      aria-describedby={"remove_description" + row.name.toString()}
                                  >
                                    <DialogTitle id={"remove_dialog" + row.name.toString()}>
                                      {'Remove snapshot '+ row.name+'?'}
                                    </DialogTitle>
                                    <DialogContent>
                                      <DialogContentText id={"remove_description" + row.name.toString()}>
                                        Are you sure to remove this snapshot forever?
                                      </DialogContentText>
                                    </DialogContent>
                                    <DialogActions>
                                      <Button onClick={() => handleRemoveClose(row.name, false)}>Cancel</Button>
                                      <Button onClick={() => handleRemoveClose(row.name, true)} autoFocus>
                                        OK
                                      </Button>
                                    </DialogActions>
                                  </Dialog>
                                </> :
                                <></>}
                          </TableCell>
                      );
                    })}
                  </TableRow>
              );
            })):("Nothing to see here")}
          </TableBody>
        </Table>
      </TableContainer>
      {Boolean(snapshots) ?(
          <TablePagination
              rowsPerPageOptions={[8, 20, 100]}
              component="div"
              count={snapshots.length || 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
          />):("Nothing")}
      <Divider />
      <Typography
          sx={{ mt: 0.5, ml: 2 }}
          color="text.secondary"
          display="block"
          variant="caption"
      >
        Create Snapshot
      </Typography>

      <Table>
        <TableRow>
          <TableCell>
          <Box mb={3} width={"100%"}>
            <TextField label={"Snapshot Name"}
                       onChange={(e) => handleEditMeta(e.target.value, 'name')}
                       defaultValue={metaSnap.name}
            />
          </Box>
          </TableCell>
          <TableCell>
            <Box mb={3} width={"100%"}>
              <TextField label={"Snapshot Description"}
                         onChange={(e) => handleEditMeta(e.target.value, 'description')}
                         defaultValue={metaSnap.description}
              />
            </Box>
          </TableCell>
          <TableCell>
            <label>
              <input
                  type="checkbox"
                  checked={f_fix}
                  // ref="complete"
                  onChange={handleEditFix}
              />
            Fix Blueprints
            </label>
          </TableCell>
        </TableRow>
      </Table>
      <Box zIndex={9}>
        <Button onClick={handlerCreateSnapshot}>Submit Snapshot</Button>
      </Box>
    </Dialog>
  );
};

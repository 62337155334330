import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  makeStyles, MenuItem,
  Paper,
  TextField, Tooltip,
  Typography,
} from "@material-ui/core";
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/InsertDriveFile';
import {useDispatch, useSelector} from "react-redux";
import { Api } from "../../api";
import axios from 'axios';
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import {useParams} from "react-router-dom";
import {fetchAdapters, fetchBlueprints} from "../../store/thunks/tablespace";
import {changeSnapshotBlueprint, parseBlueprints} from "../../helpers/blueprints";

const FileButton = () => (
    <IconButton
        variant="contained"
        component="label"
    >
    <InsertDriveFileIcon />
    </IconButton>
)

const useStyles = makeStyles((theme) => ({
  root: {
    position: "fixed",
    zIndex: 51,
    top: "70%",
    transform: "translateY(-55%)",
    right: "0",
    overflow: 'auto',
    transitionDuration: "200ms",
    padding: theme.spacing(2),
    maxWidth: theme.spacing(50),
    maxHeight: theme.spacing(70),
  },
  button: {
    color: theme.palette.error.main,
  },
}));
export const NodeProperties = ({
  sentValues,
  nodeId,
  closeProperties,
  deleteNode,
  nodeNameUpdate, addNode
}) => {
  const dispatch = useDispatch();
  console.log("NodeProperties Start")
  useEffect(() => {
    dispatch(fetchAdapters());
  }, []);
  const adaptersList = useSelector((state) => [
    ...state.entities.adapters.global,
    //     ...state.entities.adapters.local,
  ]);

  useEffect(() => {
    dispatch(fetchBlueprints());
  }, []);

  const nodesList = useSelector((state) => [
    ...state.entities.blueprints.global,
    ...state.entities.blueprints.local,
  ]);

  const [connections, setConnections] = useState({
    incoming: [],
    outgoing: [],
  });
  const { projectId } = useParams();
  let [inputs, setInputs] = useState({
    values: [],
  });
  let [outputs, setOutputs] = useState({
    outp_values: [],
  });
  const [nodeProperties, setNodeProperties] = useState(null);
  const [snapshotNames, setSnapshotNames] = useState([]);
  const [nodeEdit, setNodeEdit] = useState(false);
  const snapSchema = useSelector(
    (state) => state.entities.tableSpace.currentGraphData
  );

  const classes = useStyles();

  const handleNodeDelete = () => {
    deleteNode(nodeId, snapSchema);
  };
  const handlePropertiesClose = () => {
    closeProperties();
  };


  const getNodeData = async () => {
    const { links } = snapSchema;
    const incoming = [];
    const outgoing = [];
    const values = [];
    const outp_values = [];

    const nodeLinks = links.filter((link) => {
      const [inputNode] = link.input.split("|");
      const [outputNode] = link.output.split("|");
      if (inputNode === nodeId || outputNode === nodeId) {
        return true;
      }
    });

    nodeLinks.forEach((link) => {
      const [inputNode] = link.input.split("|");
      inputNode === nodeId
        ? incoming.push(`${link.output} > ${link.input}`)
        : outgoing.push(`${link.output} > ${link.input}`);
    });

    console.log("NodeProperties, snapSchema:", snapSchema)
    const node = snapSchema.nodes.find((node) => node.id === nodeId);
    try {
      node.inputs.forEach((inp) => {
        values.push({...inp});
      });


      if ((node.data.typeId === "dummy") && (node.data.name.slice(0, 6) === 'output')) {
        Object.keys(node.data.result_data).map((out) => {
          outp_values.push({...node.data.result_data[out]});
        })
      }else{
        node.outputs.forEach((out) => {
          outp_values.push({...out});
        });
      };

      console.log('NodeProperties, values', values)
      console.log('NodeProperties, node', node)
      let localSnapshotNames = [];
      const id = node.data.blueprintId;
      if (id !== undefined) {
        try {
          console.log("NodeProperties, globalNde0:", node.data.blueprintId)
          const currentNode = await Api.blueprints.get.byId({blueprintId: id})
          const globalNode = await parseBlueprints([{...currentNode.data}]);
          if (globalNode) {
            Object.keys(globalNode[0].versions).forEach((nodeLoc) => {
              localSnapshotNames.push(globalNode[0].versions[nodeLoc].name);
            })
          }
        }catch (e) {
          console.log("Error in NodeProperties:", e)
        }
      }else{
        localSnapshotNames.push('dev')
      };
      console.log('NodeProperties, localSnapshotNames', localSnapshotNames)

      setSnapshotNames(localSnapshotNames);
      setNodeProperties(node);
      setConnections({ incoming, outgoing });
      setInputs({ values });
      setOutputs({ outp_values });
    }catch (e) {
      console.log("Error with NodeProperties:", e)
    }
  };

  const handleNodeUpdate = (e) => {
    const { value } = e.target;
    let name = value;
    if (value.length === 0) {
      name = "Click to edit node name...";
    }
    setNodeEdit(false);
    nodeNameUpdate({ nodeId, name });
  };

  const handleEditPortValue = (e, name) => {
    console.log('NodeProperties, handleEditPortValue, e', e.target.value)
    console.log('NodeProperties, handleEditPortValue, name', name)
    inputs.values.forEach(inp=>{
      if (inp.name_id === name) {
        inp.value = ['inline', e.target.value];
      }
    });
    console.log('NodeProperties, handleEditPortValue, inputs', inputs)
  };

  const handleEditPortAdapter = (port_name, adapter_id, type) => {
    console.log('NodeProperties, handleEditPortAdapter, e', port_name)
    console.log('NodeProperties, handleEditPortAdapter, key', adapter_id)
    if(type=='input'){
      inputs.values.forEach(inp=>{
        if (inp.name_id === port_name) {
          inp.adapter_name = adaptersList.filter(adapter => adapter.blueprint_id === adapter_id)[0]['typeId'];
          inp.adapter = [adapter_id, 'dev']
        }
      });
    }else{
      outputs.outp_values.forEach(out=>{
        if (out.name_id === port_name) {
          out.adapter_name = adaptersList.filter(adapter => adapter.blueprint_id === adapter_id)[0]['typeId'];
          out.adapter = [adapter_id, 'dev']
        }
      });
    }

    // console.log('NodeProperties, handleEditPortValue, inputs', inputs)
  };

  const handleClickEditPortValueFile = async (e, name) => {
    console.log("Upload file to", name)
    const input = document.getElementById("file-upload"+name.toString());
    console.log("Upload file to", input)
    input.click()
  };

  const handleEditPortValueFile = async (e, name) => {
    console.log('NodeProperties, handleEditPortValueFile, event.target.files[0]:', e.target.files[0])
    console.log('NodeProperties, handleEditPortValueFile, name:', name)

    let f = e.target.files[0];
    let formData = new FormData();
    formData.append('file', f)

    console.log('Upload, ProjectId:', projectId);

    const a = await Api.projects.post.upfile({
      formData,
      projectId
    });
    console.log("File upload output:", a);

    inputs.values.forEach(inp=>{
      if (inp.name_id === name) {
        inp.value = ['userfile', [f.name, a.data.key]];
        inp.f_name = a.data.key;
        inp.value[1][0] = f.name;
        inp.f_key = a.data.key;
      }
    });
  };

  const handleClickDownloadPortValueFile = async (e, name) => {
    let taskId;
    let nameId;
    let nodeId;
    let extension = "";

    console.log("Adapters in NodeProperties:", adaptersList)
    console.log("Adapters in name:", name)

    outputs.outp_values.forEach(out=>{
      if (out.name_id === name) {
        taskId = out.task_id;
        nameId = out.name_id;
        console.log("Adapters, out:", out)
        nodeId = out['id'].split("|")[0];

        adaptersList.forEach(adapter =>{
          if (adapter.blueprint_id === out.adapter[0]){
            extension = adapter.extension;
            console.log("Found ext:", extension);
          }
        });
      }
    });



    var file = await Api.projects.get.downfile({
      task_id: taskId,
      output_name: nameId,
      node_id: nodeId
    });

    var blob=new Blob([file.data], {type:(file.headers['content-type']+";charset=utf-8")});
    var link=document.createElement('a');
    link.type = file.headers['content-type']+";charset=utf-8";
    link.href=window.URL.createObjectURL(blob);
    name = (nameId + extension).toString();
    link.download=name;
    link.click();
  };

  const handleSavePortValues = (e) => {
    console.log('NodeProperties, handleSavePortValues, inputs:', inputs)

    sentValues({ nodeId, ...inputs, ...outputs })

    // nodes.forEach(node=>{
    //   if (node.id === nodeId) {
    //     node.inputs = inputs.values;
    //   }
    // });
    handlePropertiesClose();
  };

  const handleChangeSnapshot = async (snapName) => {
    console.log("NodeProperties, handleChangeSnapshot1:", nodesList)
    console.log("NodeProperties, handleChangeSnapshot2:", nodeProperties.data.blueprintId)
    const newNode = parseBlueprints({data: nodesList.filter(node => node.blueprint_id === nodeProperties.data.blueprintId)}, snapName)
    console.log("GraphControl, add node:", newNode[0])
    // changeBlueprintSnapshot({nodeId, newVersion: newNode});
    const coords = [...nodeProperties.coordinates];

    await deleteNode(nodeId, snapSchema);
    await addNode({...newNode[0]}, coords);
  }

  useEffect(() => {
    getNodeData();
  }, [snapSchema]);
  console.log('Inputs:', inputs);
  console.log('Outputs:', outputs);
  console.log('Connections:', connections)
  console.log('NodeProperties:', nodeProperties)
  console.log('SnapshotNames:', snapshotNames)
  return (

    <Paper classes={{ root: classes.root }} variant={"outlined"}>
      <Box marginBottom={2}>
        <Typography variant={"h2"} align={"left"}>
          {nodeId}
        </Typography>
        {/*<Typography variant={"h3"} align={"left"}>*/}
        {/*  */}
        {/*</Typography>*/}
        {(nodeProperties?.data?.typeId !== "dummy") ?
            < TextField
          select
          label="Snapshot"
          variant={"filled"}
          defaultValue={nodeProperties?.currentVersion}
          key={nodeProperties?.currentVersion}
          >
        {snapshotNames.map((snapName) => (
          <MenuItem key='blueprint_snapshots' value={snapName}
          onClick={(e) => handleChangeSnapshot(snapName)}
          >
        {snapName}
          </MenuItem>
          ))}
          </TextField> : <></>}
      </Box>
      <Box marginBottom={2}>
        {nodeProperties?.data?.name && (
          <>
            {nodeEdit ? (
              <TextField
                defaultValue={nodeProperties.data.name}
                onBlur={handleNodeUpdate}
                autoFocus
                KeyboardEvent={(e) => e.key === "Enter" && handleNodeUpdate(e)}
              />
            ) : (
              <Typography
                variant={"h4"}
                align={"left"}
                onClick={() => setNodeEdit(true)}
              >
                {nodeProperties.data.name}
              </Typography>
            )}
          </>
        )}
      </Box>
      Incoming:
      {Boolean(connections.incoming.length) ? connections.incoming.map((con) => (
          <Tooltip title={con}>
            <div>{con.length > 45 ? con.toString().slice(0, 45)+"..." : con}</div>
          </Tooltip>
      )): <> No data</>}
      <br />
      Outgoing:
      {Boolean(connections.outgoing.length) ? connections.outgoing.map((con) => (
          <Tooltip title={con}>
            <div>{con.length > 45 ? con.toString().slice(0, 45)+"..." : con}</div>
          </Tooltip>
      )): <> No data</>}

      <p></p>
      Inputs:
      {(nodeProperties?.data?.typeId !== "dummy") &&
          inputs.values.map((inp) => (
          (inp.value[0] === 'inline')
            ?<div>
                <TextField
                  variant={"filled"}
                  key={inp.name_id}
                  label={inp.name_id}
                  defaultValue={inp.value[1]}
                  onChange={(e) => handleEditPortValue(e, inp.name_id)}
                  style={{marginRight: '10px'}}
                />

                <TextField
                    select
                    label="Adapter"
                    disabled = {nodeProperties.language === 'spl'}
                    variant={"filled"}
                    defaultValue={inp.adapter_name}
                    key={inp.adapter[0]}
                >
                  {Boolean(adaptersList.length) ? adaptersList.filter(adapter => adapter.language === nodeProperties.language).map((option) => (
                      <MenuItem key={option.blueprint_id} value={option.typeId}
                        onClick={(e) => handleEditPortAdapter(inp.name_id, option.blueprint_id, 'input')}>
                        {option.typeId}
                      </MenuItem>
                  )):<></>}
                </TextField>
             </div>:
              <div>

                <TextField
                    // variant={"filled"}
                    // type="file"
                    // multiline
                    disabled={true}
                    key={inp.name_id}
                    label={inp.name_id}
                    defaultValue={(inp.value[1][0] === 'n')? 'file' : inp.value[1][0]}
                    onClick={(e) => handleClickEditPortValueFile(e, inp.name_id)}
                    InputProps={{endAdornment: <FileButton />}}
                    />
                <input
                    id={"file-upload"+inp.name_id.toString()}
                    type="file"
                    hidden
                    onChange={(e) => handleEditPortValueFile(e, inp.name_id)}
                />
                <TextField
                    select
                    label="Adapter"
                    disabled = {nodeProperties.language === 'spl'}
                    variant={"filled"}
                    defaultValue={inp.adapter_name}
                    key={inp.adapter[0]}
                >
                  {Boolean(adaptersList.length) ? adaptersList.filter(adapter => adapter.language === nodeProperties.language).map((option) => (
                      <MenuItem key={option.blueprint_id} value={option.typeId}
                                onClick={(e) => handleEditPortAdapter(inp.name_id, option.blueprint_id, 'input')}>
                        {option.typeId}
                      </MenuItem>
                  )):<></>}
                </TextField>
              </div>
        ))}

      <p></p>
      Outputs:
      {(nodeProperties?.data?.typeId === "dummy") && (nodeProperties?.data?.name.slice(0, 6) === 'output')?
          (outputs.outp_values.map((out) =>(

              (out.type === 'inline')
              ? <TextField
                  fullWidth
                  variant={"filled"}
                  type="file"
                  multiline
                  maxRows={3}
                  disabled={true}
                  key={out.name_id}
                  label={out.name_id}
                  defaultValue={out.data}

                  // onKeyDown={(e) => e.key === "Enter" && handleBlur1(e)}
                  // onBlur={handleBlur1}
              />:
                  <form>
                  <TextField
                      fullWidth
                      variant={"filled"}
                      type="file"
                      multiline
                      maxRows={3}
                      disabled={true}
                      key={out.name_id}
                      label={out.name_id}
                      defaultValue={out.name_id}
                      onClick={(e) => handleClickDownloadPortValueFile(e, out.name_id)}
                      InputProps={{endAdornment: <FileButton />}}
                  />
                  </form>
          ))):
          ((nodeProperties?.data?.typeId !== "dummy") ? outputs.outp_values.map((out) => (
                <div>
                  <TextField
                      fullWidth
                      disabled = {nodeProperties.language === 'spl'}
                      select
                      label={out.name_id+"-Adapter"}
                      variant={"filled"}
                      defaultValue={out.adapter_name}
                      key={out.adapter[0]}
                  >
                    {console.log('Inp.adapter_name:', out.adapter_name)}
                    {Boolean(adaptersList.length) ? adaptersList.filter(adapter => adapter.language === nodeProperties.language).map((option) => (
                        <MenuItem key={option.blueprint_id} value={option.typeId}
                                  onClick={(e) => handleEditPortAdapter(out.name_id,
                                                                                                  option.blueprint_id,
                                                                                                  'output')}>
                          {option.typeId}
                        </MenuItem>
                    )):<></>}
                  </TextField>
                </div>
          )) : <></>)}

      <Box
        display={"flex"}
        justifyContent={"space-between"}
        padding={1}
        marginTop={1}
      >
        <Button
          variant={"text"}
          classes={{ root: classes.button }}
          disableFocusRipple
          onClick={handleNodeDelete}
        >
          Delete node
        </Button>
        <Box display={"flex"} justifyContent={"flex-end"}>
          <Button variant={"text"} onClick={handlePropertiesClose}>
            Cancel
          </Button>
          <Button variant={"contained"}
                  onClick={handleSavePortValues}>
            Send
          </Button>
        </Box>
      </Box>
    </Paper>
  );
};

import React from "react";
import { MenuBar } from "./MenuBar";
import { SidebarDrawer } from "./SidebarDrawer";
import { Box, CircularProgress, makeStyles } from "@material-ui/core";
import { useSelector } from "react-redux";
import { AuthScreenContainer } from "../authScreens/AuthScreenContainer";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
  },

  hide: {
    display: "none",
  },
}));

export const MainLayout = ({ children }) => {
  const classes = useStyles();
  const { isLoggedIn } = useSelector((state) => state.user);
  const { isFetchingGlobal } = useSelector((state) => state.ui.common);

  return (
    <>
      {isFetchingGlobal && (
        <Box position={"fixed"} bottom={20} right={20}>
          <CircularProgress/>
        </Box>
      )}
      {isLoggedIn ? (
        <Box className={classes.root}>
          <MenuBar />
          <SidebarDrawer />
          <Box marginTop={8} width={"100%"}>
            {children}
          </Box>
        </Box>
      ) : (
        <AuthScreenContainer />
      )}
    </>
  );
};

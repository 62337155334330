import React, {useEffect, useState} from "react";
import {Box, Button, makeStyles, TextField, Typography} from "@material-ui/core";
import { BlueprintsTag } from "../components/blueprints/Blueprints";
import { useDispatch, useSelector } from "react-redux";
import {fetchBlueprints, fetchAdapters} from "../store/thunks/tablespace";
import {Projects} from "../components/projects/Projects";

const useStyles = makeStyles((theme) => ({
    searchInput: {
        marginBottom: theme.spacing(2),
    },
}));

export const Blueprints = () => {
    const classes = useStyles();
    useEffect(() => {
        dispatch(fetchBlueprints());
    }, []);
    useEffect(() => {
        dispatch(fetchAdapters());
    }, []);
    const nodesList = useSelector((state) => [
        ...state.entities.blueprints.global,
        ...state.entities.blueprints.local,
    ]);

    const deletedNodes = useSelector((state) => [
        ...state.entities.blueprints.deleted,
    ]);
    console.log('pages/Blueprints, nodesList:', nodesList)

    const adaptersList = useSelector((state) => [
        ...state.entities.adapters.global,
        //     ...state.entities.adapters.local,
    ]);
    const deletedAdapters = useSelector((state) => [
        ...state.entities.adapters.deleted,
        //     ...state.entities.adapters.local,
    ]);
    // const local = useSelector((state) => state.entities.adapters);
    console.log('pages/Blueprints, adaptersList:', adaptersList)


    const dispatch = useDispatch();

    const [filter, setFilter] = useState('');

    const handleEditFilter = async (e) => {
        setFilter(e.target.value)
    };

    return (
        <Box
            display={"flex"}
            flexDirection={"column"}
            ml={"auto"}
            mr={15}
            alignItems={"right"}
            width={"70%"}
        >
            <TextField
                label={"Enter filter"}
                variant={"filled"}
                defaultValue={''}
                fullWidth
                className={classes.searchInput}
                onChange={(e) => handleEditFilter(e)}
            />

                <Typography variant={"h2"} align={"left"}>
                    Blueprints
                </Typography>

                <BlueprintsTag list={nodesList} filter={filter} />

                <Typography variant={"h2"} align={"left"}>
                    Adapters
                </Typography>

                <BlueprintsTag list={adaptersList} filter={filter} />

                <details>
                    <summary>Deleted</summary>
                    <Typography variant={"h2"} align={"left"}>
                        Deleted Blueprints
                    </Typography>

                    <BlueprintsTag list={deletedNodes} checked_def={true} />

                    <Typography variant={"h2"} align={"left"}>
                        Deleted Adapters
                    </Typography>

                    <BlueprintsTag list={deletedAdapters} checked_def={true} />
                </details>
        </Box>
    );
};

import React from "react";
import {
  Box,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Collapse
} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import StarIcon from "@material-ui/icons/Star";
import FlareIcon from "@material-ui/icons/Flare";
import { useHistory } from "react-router";

import SettingsIcon from "@material-ui/icons/Settings";
import {ExpandLess} from "@material-ui/icons";
import {ExpandMore} from "@material-ui/icons";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import SubscriptionsIcon from "@material-ui/icons/Subscriptions";
import TableChartIcon from "@material-ui/icons/TableChart";
import SearchIcon from "@material-ui/icons/Search";
import { sidebarClose, sidebarOpen } from "../../store/ui/common";

const useStyles = makeStyles((theme) => ({
  drawerHeader: {
    ...theme.mixins.toolbar,
  },
  nested: {
    paddingLeft: theme.spacing(4)
  }
}));


export const SidebarDrawer = () => {
  const [openCollapse, setOpenCollapse] = React.useState(false);
  const dispatch = useDispatch();

  function handleOpenSettings(){
    setOpenCollapse(!openCollapse);
  }

  const isOpen = useSelector((state) => state.ui.common.sidebar.isOpen);
  const sidebarWidth = useSelector((state) => state.ui.common.sidebar.width);
  const classes = useStyles();
  const history = useHistory();
  const goTo = (address) => {
    history.push(address);
    dispatch(sidebarClose());
  };
  const pageLinks = [
    { name: "My Projects", route: "/", icon: <StarIcon fontSize={"small"} /> },
    {
      name: "Blueprints",
      route: "/blueprints",
      icon: <FlareIcon fontSize={"small"} />
    },
    // {
    //   name: "Table Space",
    //   route: "/tablespace",
    //   icon: <TableChartIcon fontSize={"small"} />,
    // },
    {
      name: "New Blueprint",
      route: "/nodes/create_node",
      icon: <InsertDriveFileIcon fontSize={"small"} />,
    },
    {
      name: "Search",
      route: "/search",
      icon: <SearchIcon fontSize={"small"} />,
    },
  ];

  return (
    <Drawer
      open={isOpen}
      anchor={"left"}
      variant={"persistent"}
      PaperProps={{
        style: {
          width: sidebarWidth,
        },
      }}
    >
      <div className={classes.drawerHeader} />
      <Divider />

      {/*Microservices*/}
      <ListItem button onClick={handleOpenSettings}>
        <ListItemIcon>
          <InsertDriveFileIcon fontSize={"small"} />
        </ListItemIcon>
        <ListItemText primary="Microservices" />
        {openCollapse ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={openCollapse} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {/*Microservice List*/}
          <ListItem button className={classes.nested}>
            <ListItemIcon onClick={() => goTo("/microservices")}>
              <InsertDriveFileIcon fontSize={"small"} />
              <ListItemText inset primary="Microservice List" onClick={() => goTo("/microservices")} />
            </ListItemIcon>
          </ListItem>
          {/*Launch List*/}
          <ListItem button className={classes.nested}>
            <ListItemIcon onClick={() => goTo("/launch_table")}>
              <InsertDriveFileIcon fontSize={"small"} />
              <ListItemText inset primary="Launch List" onClick={() => goTo("/launch_table")} />
            </ListItemIcon>
          </ListItem>

        </List>
      </Collapse>
      <Divider />
      <List>


        {pageLinks.map((link, index) => {
          return (
            <ListItem button key={index} onClick={() => goTo(link.route)}>
              <ListItemIcon>{link.icon}</ListItemIcon>
              <ListItemText>{link.name}</ListItemText>
            </ListItem>
          );
        })}

        <Divider />
      </List>
      <Box marginTop={"auto"}>
        <Divider />
        <List>
          <ListItem button onClick={() => goTo("/subscriptions")}>
            <ListItemIcon>
              <SubscriptionsIcon />
            </ListItemIcon>
            <ListItemText>Subscription</ListItemText>
          </ListItem>
          <ListItem button onClick={() => goTo("/settings")}>
            <ListItemIcon>
              <SettingsIcon />
            </ListItemIcon>
            <ListItemText>Settings</ListItemText>
          </ListItem>
        </List>
      </Box>
    </Drawer>
  );
};

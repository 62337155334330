import React, { useEffect, useMemo, useRef, useState } from "react";
import MenuIcon from "@material-ui/icons/Menu";
import Ports from "./Ports";
import { useSelector } from "react-redux";
import {
  Box,
  Card,
  CardContent,
  IconButton,
  makeStyles,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
  useTheme,
} from "@material-ui/core";
import PropTypes from "prop-types";
import clsx from "clsx";
import { testSelector } from "../../store/entities/tableSpace";
import { useHistory } from "react-router-dom";
import { Api } from "../../api";
import {ProjectCard} from "../../common/ProjectCard/ProjectCard";
import {LogoIcon} from "../../common/ProjectCard/FavoritesCheckBox"

const useStyles = makeStyles((theme) => ({
  nodeWrapper: {
    padding: theme.spacing(0.6),
    width: "180px",
    height: "80px",
    cursor: "pointer",
    backgroundColor: theme.palette.background.node,
    borderRadius: theme.spacing(0.55),
  },
  dummyNodeWrapper: {
    padding: theme.spacing(0.6),
    minWidth: "180px",
    minHeight: "80px",
    height: "max-content",
    width: "max-content",
    maxWidth: "437px",
    maxHeight: "620px",
    border: "1px dashed red",
    overflowY: "scroll",
    overflowX: "scroll",
    borderRadius: theme.spacing(0.55),
  },
  dummyContent: {
    width: "100%",
    height: "min-content",
  },
  nodeEditMode: {
    border: `1px solid ${theme.palette.error.light}`,
  },
  nodeError: {
    backgroundColor: theme.palette.warning.light,
  },
}));

const Node = ({ inputs, outputs, ...props }) => {
  const { deleteNode, selectNode, addToSelected, showGroup, unselectAll, expandNode } = props;
  console.log("Node, props:", props)
  console.log("Node, Inputs:", inputs)
  const { selectedNodes, errorNodes, snapshotData } = useSelector(testSelector);
  console.log('Node, snapshotData:', snapshotData)
  const [selected, setSelected] = useState(false);
  //states
  const [menuAnchor, setMenuAnchor] = useState(null);
  const [stamp, setStamp] = useState([]);
  //utils
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const menuRef = useRef();
  const getSnapshotLinks = useMemo(() => {
    return snapshotData.links;
  }, [snapshotData.links]);
  const getCommonStyles = useMemo(
    () => ({
      width: "10px",
      height: "10px",
      borderRadius: theme.spacing(0.25),
    }),
    []
  );

  const getOutputStyles = useMemo(
    () => ({
      ...getCommonStyles,
      background: theme.palette.error.light,
    }),
    []
  );

  const getInputStyles = useMemo(
    () => ({
      ...getCommonStyles,
      background: theme.palette.success.main,
    }),
    []
  );
  const getInputs = useMemo(() => inputs, [snapshotData]);
  console.log('Node, getInputs:', getInputs.filter((item) => item.props.dynamic === true))
  console.log('Node, snapshotData:', snapshotData)
  const getOutputs = useMemo(() => outputs, [snapshotData]);
  let language = props.data.language;
  //functions
  const handleMenuOpen = (e) => {
    unselectAll();
    setMenuAnchor(e.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchor(null);
  };

  const handleMenuSelect = () => {
    addToSelected(props.id);
    handleMenuClose();
  };

  const handleMenuDelete = () => {
    deleteNode(props.id, snapshotData, selectedNodes);
    handleMenuClose();
  };

  const handleNodeSelect = () => {
    selectNode(props.id, snapshotData);
  };

  const handleShowGroup = () => {
    showGroup(props.id, props.data, snapshotData);
    handleMenuClose();
  };
  const handleAddFile = (e) => {
    console.log('handleAddFile, e:', e)
    handleMenuClose();
  };
  //Check event type
  const handleNodeMouseDown = (e) => {
    if (e.ctrlKey) {
      return addToSelected(props.id);
    }

    const isPort = e.target.classList.contains("bi-diagram-port");
    //prevent action if clicked on port
    if (isPort) {
      return;
    }
    setStamp([Date.now()]);
  };

  const handleNodeMouseUp = (e) => {
    if (stamp.length) {
      const prev = stamp[0];
      const current = Date.now();
      const result = current - prev;
      if (result < 200) handleNodeSelect();
    }
  };

  const handleMenuEdit = () => {
    console.log('Node, props.data:', props);
    unselectAll();
    handleMenuClose();
    history.push(
        `/nodes/create_node?edit=true&node_id=${props.data.blueprintId}&type_id=${"blueprint"}`
    );
    console.log('Node, Unselected!');
  };

  const handleMenuExpand = () => {
    console.log('Node, props.data:', props);
    unselectAll();
    handleMenuClose();
    expandNode(props.id, snapshotData, selectedNodes);
    // history.push(
    //     `/nodes/create_node?edit=true&node_id=${props.data.blueprintId}&type_id=${"blueprint"}`
    // );
    console.log('Node, Unselected!');
  };

  //check is node selected
  useEffect(() => {
    selectedNodes.includes(props.id) ? setSelected(true) : setSelected(false);
  }, [selectedNodes]);

  const getErrorMessage = () => {
    if (errorNodes.list.length) {
      const node = errorNodes.list.find((node) => node.id === props.id);
      if (node) {
        return node.message;
      }
      return "";
    }
    return "";
  };

  const ColoredLine = ({ color }) => (
      <hr
          style={{
            color: color,
            backgroundColor: color,
            height: 1
          }}
      />
  );

  return (
    <>
      <Box position={"absolute"} top={1} right={1}>
        <IconButton size={"small"} onClick={handleMenuOpen} ref={menuRef}>
          <MenuIcon />
        </IconButton>
      </Box>
      <Box position={"absolute"} bottom={0} right={0}>
        <LogoIcon language={language}></LogoIcon>
      </Box>
      <Menu
        open={Boolean(menuAnchor)}
        anchorEl={menuAnchor}
        onClose={handleMenuClose}
        keepMounted
      >
        <MenuItem onClick={handleMenuDelete}>Delete node</MenuItem>
        <MenuItem onClick={handleMenuSelect}>Select node</MenuItem>
        {props.data?.type !== "projectBlueprint" && props.data?.typeId !== "dummy" && (
            <MenuItem onClick={handleMenuEdit}>Edit node</MenuItem>
        )}
        {/*{props.data?.type === "projectBlueprint" && (*/}
        {/*    <MenuItem onClick={handleMenuExpand}>Expand Project</MenuItem>*/}
        {/*)}*/}
        {props.data.typeId === "dummy" && (
          <MenuItem>
            {/*<form action={}*/}
            <label htmlFor={`file-${props.id}`} style={{ cursor: "pointer" }}>
              Upload file
            </label>
            <input
              type={"file"}
              id={`file-${props.id}`}
              style={{ display: "none" }}
              onChange={handleAddFile}
            />
          </MenuItem>
        )}

        {/*{props.data?.typeId === "node_group" && (*/}
        {/*  <MenuItem onClick={handleShowGroup}>Show grouped nodes</MenuItem>*/}
        {/*)}*/}
      </Menu>
      <Tooltip title={getErrorMessage()} open>
        <Card
          className={clsx(classes.nodeWrapper, {
            [classes.nodeError]: errorNodes.list.some(
              (node) => node.id === props.id
            ),
            [classes.dummyNodeWrapper]: props.data.typeId === "dummy",
          })}
          elevation={4}
          style={{ backgroundColor: selected && "rgba(0,0,0, 0.25)" }}
          onMouseDown={handleNodeMouseDown}
          onMouseUp={handleNodeMouseUp}
          // ref={rootRef}
        >
          <Box display={"flex"} justifyContent={"space-between"}>
            {(() => {
              let id, version, number;
              let [proj, node] = props.id.split("*")
              if (!node) {
                [id, version, number] = props.id.split("-");
              }else{
                console.log("Node, misc0:", [proj, node])
                let [id1, version1, number1] = node.split("-");
                id = proj + "*" +id1;
                version = version1;
                number = number1;
              }
              console.log("Node, misc1:", props.id)
              console.log("Node, misc2:", [id, version, number])
              // number = number.split("*")[1];
              return (
                <Box display={"flex"} flexDirection={"column"} ml={0.5}>
                  <Typography variant={"caption"}>
                    {props.data?.name?.length
                      ? props.data.name
                      : `ID: ${props.id}`}
                    {props.data.typeId === "dummy"}
                  </Typography>
                  {props.data.typeId !== "dummy" && (
                    <>
                      <Typography variant={"caption"}>Type: {id}</Typography>
                      <Typography variant={"caption"}>
                        Version: {version}
                      </Typography>
                      <Typography variant={"caption"}>
                        Number: {number}
                      </Typography>
                    </>
                  )}
                </Box>
              );
            })()}
          </Box>

          <CardContent
          // onMouseDown={handleNodeMouseDown}
          // onMouseUp={handleNodeMouseUp}
          >
            {props.data.result && (props.data.result.slice(0, 5) === 'ERROR') && (
              <Typography style={{color:"#ff0000"}} variant={"caption"}>
                {props.data.result}
              </Typography>
            )}
            {props.data.result && (props.data.result.slice(0, 5) !== 'ERROR') && (
              <Typography variant={"caption"}>
                {props.data.result}
              </Typography>
            )}
            {props.data.typeId === "dummy" && (
              <Box
                width={"100%"}
                height={"150px"}
                className={classes.dummyContent}
              >
                <Typography variant={"caption"}>
                  {props.data.content?.text}
                </Typography>
                {props.data.content?.image && (
                  <img
                    src={props.data.content.image}
                    alt={"image"}
                    style={{ position: "relative", width: "100%" }}
                  />
                )}
              </Box>
            )}
            <Ports
              ports={getInputs.filter((item) => (item.props.dynamic === true || item.props.dynamic === undefined))}
              alignment={"left"}
              portStyle={getInputStyles}
              links={getSnapshotLinks}
              nodeId={props.id}
            />
            <Ports
              ports={getOutputs}
              alignment={"right"}
              portStyle={getOutputStyles}
              links={getSnapshotLinks}
              nodeId={props.id}
            />
          </CardContent>
        </Card>
      </Tooltip>
    </>
  );
};

export default React.memo(Node);

Node.propTypes = {
  inputs: PropTypes.array.isRequired,
  outputs: PropTypes.array.isRequired,
  selectNode: PropTypes.func.isRequired,
  unselectAll: PropTypes.func.isRequired,
  addToSelected: PropTypes.func.isRequired,
  deleteNode: PropTypes.func.isRequired,
};

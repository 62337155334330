import { createSlice } from "@reduxjs/toolkit";
import { userSignIn } from "../thunks/user";

export const runTaskSlice = createSlice({
  name: "run_task",
  initialState: {
    run_task_id: null,
    run_task_flg: null,
  },
  reducers: {
    changeRunTaskId: (state, action) => {
      state.run_task_id = action.payload;
    },
    changeRunTaskFlg: (state, action) => {
        state.run_task_flg = action.payload;
      }
  }
});

export const { changeRunTaskId, changeRunTaskFlg } = runTaskSlice.actions;
export default runTaskSlice.reducer;
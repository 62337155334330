import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Typography,
  TextField,
  Box,
  MenuItem,
  Button,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { getMicroserviceInfo, getMicroserviceTaskInfo, getMicroserviceTaskResults } from "../../store/thunks/projects";
import { Api } from "../../api";
import IconButton from "@material-ui/core/IconButton";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import {fetchAdapters} from "../../store/thunks/tablespace";
import { useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";

const FileButton = () => (
    <IconButton
        variant="contained"
        component="label"
    >
      <InsertDriveFileIcon />
    </IconButton>
)

export const MicroserviceFormTask = () => {
  const history = useHistory();
  const location = useLocation();
  let values = [];
  let out_values = [];
  let [inputs, setInputs] = useState({
    values: {},
  });
  let [outputs, setOutputs] = useState({
    out_values: [],
  });

  const result = queryString.parse(location.search);
  const id = result.ms_id;
  const task_id = result.task_id;
  const dispatch = useDispatch();
  let results;

  useEffect(() => {
    console.log("MS Task1")
    console.log("MS Task1, location:", id, task_id)

    if ((id) && (task_id)){
      dispatch(getMicroserviceTaskInfo({ microserviceId: id, taskId: task_id }));
      dispatch(getMicroserviceInfo({ microserviceId: id }));
    }
  }, []);

  const {microserviceTaskInfo, microserviceInfo} = useSelector((state) => state.entities.projects);

  useEffect(() => {
    console.log("MS Task, dispatch results")
    console.log("MS Task1, location:", id, task_id)

    if ((microserviceInfo) && (task_id)){
      console.log("MS Task, dispatch results1")
      dispatch(getMicroserviceTaskResults({taskId: task_id, schema: microserviceInfo._data}))
    }
  }, [microserviceInfo]);

  const {microserviceTaskResults} = useSelector((state) => state.entities.projects);

  console.log('MS2, microserviceInfo:', microserviceInfo)
  console.log('MS3, microserviceTaskInfo:', microserviceTaskInfo)

  useEffect(() => {
    if ((microserviceInfo != undefined) && (microserviceTaskInfo != undefined)) {
      if (microserviceTaskInfo.id === parseInt(task_id)) {
        console.log("MS Inputs")

        microserviceInfo.inputs.forEach((input) => {
          console.log('Microservice input:', input)
          console.log('0Microservice microserviceTaskInfo:', microserviceTaskInfo)
          console.log('0Microservice microserviceInfo:', microserviceInfo)
          microserviceInfo._data.nodes.forEach((node) => {
            if ((input.slice(0, input.indexOf(":", 0)) === node.id) ||
                (input.split("*")[0] === node.id)) {
              node.inputs.forEach((inp) => {
                console.log('0Microservice inp:', inp)
                if (input.slice(input.indexOf(":", 0) + 1) === inp['name_id']) {
                  values.push({...inp});
                }
              });
            }
            ;
          });
        })

        values = {...microserviceTaskInfo.inputs}
        setInputs({values});
        console.log("MS Task Inputs:", inputs)
        console.log("MS Task Outputs:", outputs)

      }
    }

  }, [microserviceInfo, microserviceTaskInfo]);

  useEffect(() => {
    dispatch(fetchAdapters());
  }, [microserviceInfo, microserviceTaskInfo, microserviceTaskResults]);
  const adaptersList = useSelector((state) => [
    ...state.entities.adapters.global,
    //     ...state.entities.adapters.local,
  ]);

  useEffect(() => {
    console.log("MS Task, prepare output0.1", microserviceTaskResults)
    console.log("MS Task, prepare output0.2", microserviceInfo)
    console.log("MS Task, prepare output0.3", microserviceTaskInfo)
    if(microserviceTaskResults && (microserviceInfo != undefined)){
      if (parseInt(microserviceTaskResults.task_id) === parseInt(task_id)) {
        console.log("MS Task Results:", microserviceTaskResults)

        microserviceInfo._data.nodes.forEach((node) => {
          try {
            console.log("MS Task, prepare output1")
            node.outputs.forEach((out) => {
              let local_output = {};
              console.log("MS Task, prepare output2, out:", out)
              console.log("MS Task, prepare output2, node.data:", microserviceTaskResults[node.id.split("*")[0]].outputs[out.name_id].data)
              local_output = {...microserviceTaskResults[node.id.split("*")[0]].outputs[out.name_id]}
              console.log("MS Task, prepare output3.0, local_output", local_output)
              if (out.available) {
                out_values.push({...local_output});
              }
              console.log("MS Task, prepare output3")
            });
            console.log("MS Task, prepare output4")
          } catch {
            console.log("MS Task node not found:", node.id)
          }
        });
        setOutputs({out_values});
        console.log("MS Task, out_values:", out_values)
      }
    }
  }, [microserviceTaskResults, microserviceInfo])





  //
  const handleClickDownloadPortValueFile = async (e, name) => {
    let taskId;
    let nameId;
    let nodeId;
    let extension = "";

    console.log("Adapters in NodeProperties:", adaptersList)
    console.log("outputs in NodeProperties:", outputs)

    outputs.out_values.forEach(out=>{
      if (out.name_id === name) {
        taskId = out.task_id;
        nameId = out.name_id;
        nodeId = out.id.split("|")[0];

        adaptersList.forEach(adapter =>{
          if (adapter.blueprint_id === out.adapter[0]){
            extension = adapter.extension;
            console.log("Found ext:", extension);
          }
        });
      }
    });



    var file = await Api.projects.get.downfile({
      task_id: taskId,
      output_name: nameId,
      node_id: nodeId
    });

    var blob=new Blob([file.data], {type:(file.headers['content-type']+";charset=utf-8")});
    var link=document.createElement('a');
    link.type = file.headers['content-type']+";charset=utf-8";
    link.href=window.URL.createObjectURL(blob);
    var name = (nameId + extension).toString();
    link.download=name;
    link.click();
  };


  const handleBack = async () => {
    const {id} =
        microserviceInfo;

    history.push(`/microservices/${id}`)
  };

  console.log('MS Inputs:', inputs)
  console.log('MS Outputs:', outputs)

  return (
      <Box
          display={"flex"}
          flexDirection={"column"}
          alignItems={"center"}
          justifyContent={"center"}
          height={"90vh"}
      >


        {Boolean(Object.keys(inputs.values).length>0) ? (
            <>
              <Typography variant={"h3"}>Inputs:</Typography>

              {console.log("Inputs Length:", Object.keys(inputs.values).length)}
              {Object.keys(inputs.values).map((inp) => (
                  (Object.keys(inputs.values).length > 0)?
                    (inputs.values[inp][0] === 'inline')
                        ?<TextField
                            style = {{width: 300}}
                            variant={"filled"}
                            multiline
                            disabled={true}
                            // maxRows={3}
                            key={inp}
                            label={inp.length > 25 ? inp.toString().slice(0, 25)+"..." : inp}
                            defaultValue={inputs.values[inp][1]}
                        />:
                        <form>

                          <TextField
                              style = {{width: 300}}
                              fullWidth
                              variant={"filled"}
                              type="file"
                              multiline
                              disabled={true}
                              // maxRows={3}
                              key={inp}
                              label={inp.length > 25 ? inp.toString().slice(0, 25)+"..." : inp}
                              defaultValue={inputs.values[inp][1][0]}
                          />
                          <input
                              id="file-upload"
                              type="file"
                              hidden
                          />
                        </form>
                      : <></>
              ))}

            </>
        ) : (
            "No info or incorrect data"
        )}

        {Boolean(Object.keys(outputs).length) ? (
            <>
              <Typography variant={"h3"}>Results:</Typography>

              {outputs.out_values.map((out) => (
                  (out.type === 'inline')
                      ?<TextField
                          style = {{width: 300}}
                          variant={"filled"}
                          type="file"
                          multiline
                          // maxRows={3}
                          disabled={true}
                          key={out.id}
                          label={out.name_id.length > 25 ? out.name_id.toString().slice(0, 25)+"..." : out.name_id}
                          defaultValue={out.data}

                          // onKeyDown={(e) => e.key === "Enter" && handleBlur1(e)}
                          // onBlur={handleBlur1}
                      />:
                      <form>
                        <TextField
                            variant={"filled"}
                            type="file"
                            multiline
                            // maxRows={3}
                            disabled={true}
                            key={out.id.split("|")[0]}
                            label={out.name_id.length > 25 ? out.name_id.toString().slice(0, 25)+"..." : out.name_id}
                            defaultValue={out.name_id}
                            onClick={(e) => handleClickDownloadPortValueFile(e, out.name_id)}
                            InputProps={{endAdornment: <FileButton />}}
                        />
                      </form>
              ))}
              <Box mt={2}>
                <Button onClick={handleBack}>Back</Button>
              </Box>
            </>
        ) : (
            "No info or incorrect data"
        )}


      </Box>
  );
};
